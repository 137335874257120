import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Pricing = forwardRef(({pricingPlans}, ref) => {
    const navigate = useNavigate();

    const handleGetStartedClick = (buttonDestination) => {
        navigate(buttonDestination);
    };

    return (
        <section ref={ref} className="lg:mt-8 mt-2 bg-primary p-6 px-12">
            <h1 className="text-4xl font-bold text-center mb-8 text-primary">Pricing</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {pricingPlans.map((plan, index) => (
                    <div key={index} className="feature-card p-6 bg-accent rounded shadow text-primary flex flex-col">
                        <div>
                            <h2 className="text-xl font-bold mb-4 text-primary underline">{plan.title}</h2>
                            <p className="text-ml font-bold mb-4 text-primary">{plan.price}</p>
                            <ul className="list-none mb-4 text-sub">
                                {plan.features.map((feature, i) => (
                                    <li key={i} className="mb-2">
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {plan.buttonDestination && plan.buttonText ? (
                            <button
                            className="bg-white text-black py-2 px-4 rounded mb-4 mt-auto"
                            onClick={() => handleGetStartedClick(plan.buttonDestination)}
                        >
                            {plan.buttonText}
                        </button>
                        ) : ""}
                    </div>
                ))}
            </div>
        </section>
    );
});

export default Pricing;
