import { isElectron, ElectronToken } from './ElectronUtils';

const customFetch = async (url, options = {}) => {
    if (isElectron) {
        const token = await ElectronToken();
        if (token) {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${token}`,
            };
            options.credentials = 'omit';
        }
    }else{
        options.headers = {
            ...options.headers,
        };
        options.credentials = 'include';
    }

    return fetch(url, options);
};

export default customFetch;