import React, { useState, useEffect } from 'react';
import { CURRENT_VERSION } from '../version';

import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ListItemIcon from '@mui/material/ListItemIcon';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import BuildIcon from '@mui/icons-material/BuildOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircularProgress from '@mui/material/CircularProgress';

const getStatusChipStyles = (status) => {
    if (status === 'Released') {
        return {
            borderColor: 'darkgreen',
            backgroundColor: 'lightgreen',
            borderWidth: 2,
        };
    } else if (status === 'Unreleased') {
        return {
            borderColor: 'darkred',
            backgroundColor: 'lightcoral',
            borderWidth: 2,
        };
    } else {
        return {
            borderColor: 'goldenrod',
            backgroundColor: '#ffffb3',
            borderWidth: 2,
        };
    }
};

export default function Changelog({ open, handleClose }) {
    const [changelog, setChangelog] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchChangelog() {
            const response = await fetch(`/changelog.json`);
            const data = await response.json();
            const validChangelog = data.filter((release) => isValidVersion(release.version));
            setChangelog(validChangelog.reverse());
            setLoading(false);
        }

        fetchChangelog();
    }, []);

    useEffect(() => {
        if (open) {
            setCurrentIndex(changelog.length - 1);
        }
    }, [open, changelog]);

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const getIconForCategory = (category) => {
        switch (category) {
            case 'added':
                return <AddIcon fontSize="inherit" />;
            case 'fixed':
                return <BuildIcon fontSize="inherit" />;
            case 'changed':
                return <SwapVertIcon fontSize="inherit" />;
            case 'removed':
                return <RemoveIcon fontSize="inherit" />;
            default:
                return null;
        }
    };

    const isValidVersion = (version) => {
        const regex = /^\d+\.\d+\.\d+$/;
        return regex.test(version) && (version <= CURRENT_VERSION);
    };

    const handleNavigationClick = (direction) => {
        setCurrentIndex((prevIndex) => prevIndex + direction);
    };

    const release = changelog[currentIndex];

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            fullWidth={true}
            maxWidth="md"
            aria-labelledby="changelog-title"
            aria-describedby="changelog-description"
        >
            <DialogTitle id="changelog-title" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button onClick={() => handleNavigationClick(-1)} disabled={currentIndex === 0 || loading}> {/* Disable if loading */}
                    <ArrowBackIcon />
                </Button>
                <Typography variant="h6" component="h2">
                    Changelog
                </Typography>
                <Button onClick={() => handleNavigationClick(1)} disabled={currentIndex === changelog.length - 1 || loading}> {/* Disable if loading */}
                    <ArrowForwardIcon />
                </Button>
            </DialogTitle>
            <DialogContent dividers={true}>
                <div
                    id="changelog-description"
                    ref={descriptionElementRef}
                    style={{ margin: 0, fontSize: '1rem', fontWeight: 400, lineHeight: 1.5 }}
                >
                    {loading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <div key={release.version} className="release" style={{ marginBottom: '30px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h6" component="h3" gutterBottom>
                                    <u>{`Version ${release.version}`}</u>
                                    <Chip label={release.date} size="small" style={{ marginLeft: '10px' }} />
                                </Typography>
                                <div>
                                    {release.beta && (
                                        <Chip
                                            label="Beta"
                                            size="small"
                                            sx={{
                                                borderColor: (theme) => theme.palette.accent.main,
                                                backgroundColor: (theme) => theme.palette.primary.main,
                                                color: 'white',
                                                borderWidth: getStatusChipStyles(release.status).borderWidth,
                                                borderStyle: 'solid',
                                                marginRight: '5px',
                                            }}
                                        />
                                    )}
                                    <Chip
                                        label={release.status}
                                        size="small"
                                        sx={{
                                            borderColor: getStatusChipStyles(release.status).borderColor,
                                            backgroundColor: getStatusChipStyles(release.status).backgroundColor,
                                            color: 'black',
                                            borderWidth: getStatusChipStyles(release.status).borderWidth,
                                            borderStyle: 'solid',
                                        }}
                                    />
                                </div>
                            </div>
                            {release.description && release.description !== '' && (
                                <Typography variant="body2" gutterBottom>
                                    {release.description}
                                </Typography>
                            )}
                            {Object.keys(release.changes).map((category) => (
                                release.changes[category].length > 0 && (
                                    <div key={category}>
                                        <List>
                                            {release.changes[category].map((change, index) => (
                                                <ListItem
                                                    key={index}
                                                    className={`change ${category}`}
                                                    sx={{
                                                        '&:not(:last-child)': {
                                                            marginBottom: '0.5rem',
                                                        },
                                                    }}
                                                >
                                                    <ListItemIcon sx={{ minWidth: 'auto', marginRight: '0.5rem' }}>
                                                        {getIconForCategory(category)}
                                                    </ListItemIcon>
                                                    <Typography variant="body2">{change}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                )
                            ))}
                        </div>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
