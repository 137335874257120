import React, { useState, useEffect } from 'react';
//import { logEvent } from '../../utilities/analytics';
import { isElectron } from '../../utilities/ElectronUtils';
import {
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { FaWindows, FaLinux, FaAndroid } from 'react-icons/fa';

function Downloads() {
  const [downloadURL, setDownloadURL] = useState('');

  useEffect(() => {
    const fetchDownloadURL = async () => {
      try {
        const response = await fetch('https://downloads.makermate.app/releases/latest.yml');
        const text = await response.text();
        const lines = text.split('\n');
        const urlLine = lines.find((line) => line.startsWith('- url: '));

        if (urlLine) {
          const url = urlLine.split('- url: ')[1];
          setDownloadURL(url);
        }
      } catch (error) {
        console.error('Error fetching download URL:', error);
      }
    };

    if (!isElectron) {
      fetchDownloadURL();
    }
  }, []);

  return (
    <div>
      <Typography variant="body1" className="mb-4 pb-4">
        Download the latest version of the app for your platform below.
      </Typography>
      <Grid container spacing={2} justify="center" p={6}>
        <Grid item xs={6} style={{ position: 'relative' }}>
          <div style={{ position: 'relative', paddingBottom: '100%', width: '100%' }}>
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
                borderRadius: '8px',
                border: '1px solid',
                borderColor: 'primary.main',
                overflow: 'hidden',
                top: 0,
                left: 0,
                right: 0,
                bottom: '20px',
              }}
              fullWidth
              onClick={() => {
                if (downloadURL) {
                  window.open(downloadURL, '_blank');
                }
              }}
            >
              <FaWindows
                size={96}
                sx={{ marginBottom: '10px' }}
              />
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  whiteSpace: 'nowrap',
                  fontSize: 'clamp(0.6rem, 1.4vw, 1.2rem)',
                }}
              >
                Download for Windows
              </Typography>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '25px',
                  left: '-68px',
                  backgroundColor: 'error.main',
                  width: '200px',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '0.7rem',
                  transform: 'rotate(-45deg)',
                  whiteSpace: 'nowrap',
                  zIndex: 2,
                }}
              >
                Beta
              </Typography>
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} style={{ position: 'relative' }}>
          <div style={{ position: 'relative', paddingBottom: '100%', width: '100%' }}>
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
                borderRadius: '8px',
                border: '1px solid',
                borderColor: 'primary.main',
                overflow: 'hidden',
                top: 0,
                left: 0,
                right: 0,
                bottom: '20px',
              }}
              fullWidth
              disabled
            >
              <FaLinux
                size={96}
                sx={{ marginBottom: '10px' }}
              />
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  whiteSpace: 'nowrap',
                  fontSize: 'clamp(0.6rem, 1.4vw, 1.2rem)',
                }}
              >Download for Linux</Typography>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '25px',
                  left: '-68px',
                  backgroundColor: 'error.main',
                  width: '200px',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '0.7rem',
                  transform: 'rotate(-45deg)',
                  whiteSpace: 'nowrap',
                  zIndex: 2,
                }}
              >
                Coming soon
              </Typography>
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} style={{ position: 'relative' }}>
          <div style={{ position: 'relative', paddingBottom: '100%', width: '100%' }}>
            <Button
              variant="contained"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
                borderRadius: '8px',
                border: '1px solid',
                borderColor: 'primary.main',
                overflow: 'hidden',
                top: 0,
                left: 0,
                right: 0,
                bottom: '20px',
              }}
              fullWidth
              disabled
            >
              <FaAndroid
                size={96}
                sx={{ marginBottom: '10px' }}
              />
              <Typography
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  whiteSpace: 'nowrap',
                  fontSize: 'clamp(0.6rem, 1.4vw, 1.2rem)',
                }}
              >Download for Android</Typography>
              <Typography
                sx={{
                  position: 'absolute',
                  top: '25px',
                  left: '-68px',
                  backgroundColor: 'error.main',
                  width: '200px',
                  color: 'white',
                  fontWeight: 'bold',
                  fontSize: '0.7rem',
                  transform: 'rotate(-45deg)',
                  whiteSpace: 'nowrap',
                  zIndex: 2,
                }}
              >
                Coming soon
              </Typography>
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Downloads;