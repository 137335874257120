import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { logEvent } from './../utilities/analytics';
import { UserContext } from './../context/UserContext';
import { fetchMaterials, materialAdjustment } from './../api/materialAPI';
import {
    Typography,
    Container,
    Grid,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Tooltip,
    IconButton,
    Box,
    Skeleton,
    useTheme,
    useMediaQuery
} from '@mui/material';
import {
    Add,
    Remove
} from '@mui/icons-material';

function Materials() {
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const loader = useRef(null);

    const { userData } = useContext(UserContext);

    const [materials, setMaterials] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(12);


    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    const [adjustmentAmount, setAdjustmentAmount] = useState(1);

    const calculateNumSkeletons = () => {
        const numItemsPerRow = isMdUp ? 6 : isSmUp ? 4 : 2;
        const numLoadedItems = materials.length % numItemsPerRow;
        const numEmptySpots = numItemsPerRow - numLoadedItems;

        return numEmptySpots + numItemsPerRow;
    };

    useEffect(() => {
        logEvent('navigate_materials', {
            page_name: 'Materials',
            description: 'User navigated to the materials page',
        });
    }, []);

    const loadMaterials = useCallback(async (page, pageSize) => {
        setIsLoading(true);
        try {
            //TODO: Add settings to hide materials with 0 remaining
            //const filter = [{ field: 'total_remaining', operator: '>', value: 0 }];
            const filter = [];
            const data = await fetchMaterials(page, pageSize, filter);

            if (data.materials.length === 0) {
                setHasMore(false);
            } else {
                setMaterials((prevMaterials) => {
                    const updatedMaterials = [...prevMaterials];
                    data.materials.forEach((newMaterial) => {
                        const index = updatedMaterials.findIndex((material) => material.material_id === newMaterial.material_id);
                        if (index !== -1) {
                            // Update the existing material
                            updatedMaterials[index] = newMaterial;
                        } else {
                            // Add the new material to the list
                            updatedMaterials.push(newMaterial);
                        }
                    });
                    return updatedMaterials;
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleAdjustment = (itemId, adjustment) => {
        materialAdjustment(itemId, adjustment);
        setMaterials((prevMaterials) => {
            return prevMaterials.map((material) => {
                if (material.material_id === itemId) {
                    const newTotalRemaining = Math.max(material.total_remaining + adjustment, 0);
                    return { ...material, total_remaining: newTotalRemaining };
                }
                return material;
            });
        });
    };

    const handleKeyDown = (event) => {
        if (event.shiftKey) {
            setAdjustmentAmount(10);
        } else if (event.ctrlKey) {
            setAdjustmentAmount(100);
        } else {
            setAdjustmentAmount(1);
        }
    };

    const handleKeyUp = () => {
        setAdjustmentAmount(1);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    useEffect(() => {
        if (isLoading) return;
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setCurrentPage((prevPage) => prevPage + 1);
                }
            },
            {
                threshold: 1,
            }
        );

        const currentLoader = loader.current;

        if (currentLoader) {
            observer.observe(currentLoader);
        }

        return () => {
            if (currentLoader) {
                observer.unobserve(currentLoader);
            }
        };
    }, [loader, hasMore, isLoading]);

    useEffect(() => {
        loadMaterials(currentPage, pageSize);
    }, [loadMaterials, currentPage, pageSize]);

    function addCurrencySymbol(costField) {
        const roundedCost = parseFloat(costField).toFixed(2);
        return `${userData?.settings?.currencySymbol}${roundedCost}`;
    }

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" className="text-white mb-4" align="left">
                        Materials
                    </Typography>
                </Grid>
                <Grid item xs={12} pb={5}>
                    <Grid container spacing={3}>
                        {Array.isArray(materials) &&
                            materials.map((item, index) => (
                                <Grid item xs={12} sm={6} md={4} key={item.material_id}>
                                    <Card>
                                        <CardContent sx={{ padding: '0 16px 0 16px' }}>
                                            <Box display="flex" flexDirection="column">
                                                <CardHeader
                                                    titleTypographyProps={{ variant: 'h6' }}
                                                    subheaderTypographyProps={{ variant: 'subtitle3' }}
                                                    title={item.name}
                                                    sx={{ textAlign: 'left', padding: '10px 0' }}
                                                />
                                                <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'left' }}>
                                                    Cost Per Unit: {addCurrencySymbol(item.cost_per_unit)}
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                        <CardActions disableSpacing>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                }}
                                            >
                                                <Box>
                                                    <Tooltip title={`Decrease by ${adjustmentAmount}`}>
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                color="primary"
                                                                aria-label="decrease"
                                                                disabled={item.total_remaining === 0}
                                                                onClick={() => handleAdjustment(item.material_id, -adjustmentAmount)}
                                                            >
                                                                <Remove />
                                                            </IconButton>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title={`Increase by ${adjustmentAmount}`}>
                                                        <IconButton
                                                            size="small"
                                                            color="primary"
                                                            aria-label="increase"
                                                            onClick={() => handleAdjustment(item.material_id, adjustmentAmount)}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                <Typography sx={{ paddingRight: '10px' }}>{`${item.total_remaining} ${item.unit}`}</Typography>
                                            </Box>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        {(isLoading) &&
                            Array.from({ length: calculateNumSkeletons() }).map((_, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Skeleton variant="rectangular" height={115} />
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
                <Grid item xs={12} pb={10}>
                    <Grid container spacing={3}>
                        <div ref={loader} style={{ height: '1px' }}></div>
                        {!hasMore && <Typography variant="body2" align="center" sx={{ width: '100%' }}>No more results</Typography>}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Materials;

