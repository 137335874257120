import { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, FormHelperText } from '@mui/material';
import { fetchMaterials } from '../api/materialAPI';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
        month = `0${month}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
};

const ExpenseForm = forwardRef((props, ref) => {
    const { onSubmit, selectedExpense } = props;

    const [suggestedMaterials, setSuggestedMaterials] = useState([]);

    const [expenseName, setExpenseName] = useState(selectedExpense ? selectedExpense.name : '');
    const [expenseDate, setExpenseDate] = useState(selectedExpense ? formatDate(selectedExpense.date) : formatDate(new Date()));
    const [expenseCost, setExpenseCost] = useState(selectedExpense ? selectedExpense.cost : '');
    const [expenseType, setExpenseType] = useState(selectedExpense ? selectedExpense.type : '');
    const [unit, setUnit] = useState(selectedExpense ? selectedExpense.unit : '');
    const [quantity, setQuantity] = useState(selectedExpense ? selectedExpense.quantity : '');
    const [selectedSuggestion, setSelectedSuggestion] = useState(selectedExpense);

    const fetchSuggestedMaterials = useCallback(async (filter) => {
        const fetchedData = await fetchMaterials(0, 25, [{ field: 'name', operator: 'contains', value: filter }]);

        // Process the fetched data as needed
        const data = fetchedData.materials.map((material) => ({
            material_id: material.material_id, // Replace with the correct property name from your API
            name: material.name,
            unit: material.unit,
        }));

        setSuggestedMaterials(data);

        if(data.length === 1){
            setSelectedSuggestion(data[0]);
        }
    }, []);

    const handleInputChange = (event, value) => {
        fetchSuggestedMaterials(value);
    };

    useEffect(() => {
        fetchSuggestedMaterials(expenseName);
    }, [fetchSuggestedMaterials, expenseName]);


    useImperativeHandle(ref, () => ({
        handleSubmit: handleSubmit,
    }));

    const resetForm = () => {
        setExpenseName('');
        setExpenseDate(formatDate(new Date()));
        setExpenseCost('');
        setExpenseType('');
        setUnit('');
        setQuantity('');
    };

    useEffect(() => {
        if (!selectedExpense) {
            resetForm();
        } else {
            setExpenseName(selectedExpense.name);
            setExpenseDate(formatDate(selectedExpense.date));
            setExpenseCost(selectedExpense.cost);
            setExpenseType(selectedExpense.type);
            setUnit(selectedExpense.unit);
            setQuantity(selectedExpense.quantity);
        }
    }, [selectedExpense]);

    // useEffect(() => {
    //     if (selectedSuggestion) {
    //         setUnit(selectedSuggestion.unit);
    //         setSelectedSuggestion(selectedExpense)
    //     }else{
    //         setSelectedSuggestion(null);
    //     }
    // }, [selectedSuggestion]);

    const handleSubmit = () => {
        const submittedData = {
            name: expenseName,
            date: expenseDate,
            cost: expenseCost,
            type: expenseType,
            unit: unit,
            quantity: quantity,
        };
    
        if (selectedSuggestion && selectedSuggestion.material_id) {
            submittedData.material_id = selectedSuggestion.material_id;
        }

        //console.log(submittedData);
    
        onSubmit(submittedData);
        resetForm();
    };

    return (
        <div className="flyout-content">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <FormControl fullWidth required style={{ marginBottom: "10px", marginTop: '6px' }}>
                    <InputLabel style={{ paddingLeft: "4px", paddingRight: "4px" }}>Type</InputLabel>
                    <Select
                        name="expenseType"
                        value={expenseType}
                        onChange={(e) => {
                            setExpenseType(e.target.value);
                            if (e.target.value !== "Materials") {
                                //console.log("ResetSuggestion:");
                                setSelectedSuggestion(null);
                            } else {
                                const foundSuggestion = suggestedMaterials.find(
                                    (suggestion) => suggestion.name === expenseName
                                );
                                //console.log("foundSuggestion:",foundSuggestion);
                                setSelectedSuggestion(foundSuggestion || null);
                            }
                        }}
                        label="Type"
                    >
                        <MenuItem value="Materials">Materials</MenuItem>
                        <MenuItem value="Equipment">Equipment</MenuItem>
                        <MenuItem value="Supplies">Supplies</MenuItem>
                        <MenuItem value="Vehicle">Vehicle</MenuItem>
                        <MenuItem value="Fees">Fees</MenuItem>
                    </Select>
                </FormControl>
                {expenseType !== 'Materials' && (
                    <TextField
                        label="Expense Name"
                        name="expenseName"
                        value={expenseName}
                        onChange={(e) => setExpenseName(e.target.value)}
                        required
                        fullWidth
                        style={{ marginBottom: '10px' }}
                    />
                )}
                {expenseType === 'Materials' && (
                    <Autocomplete
                        freeSolo
                        options={suggestedMaterials}
                        inputValue={expenseName}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            //console.log("AutoComplete Changed",newValue);
                            if (typeof newValue === "object") {
                                //console.log("AutoComplete Changed (object)",newValue);
                                setSelectedSuggestion(newValue);
                                setExpenseName(newValue ? newValue.name : "");
                            } else {
                                //console.log("AutoComplete Changed",newValue);
                                setSelectedSuggestion(null);
                                setExpenseName(newValue);
                            }
                        }}
                        onInputChange={(event, value, reason) => {
                            //console.log("AutoComplete Input Changed",event, value, reason);
                            if (reason === "input") {
                                handleInputChange(event, value);
                                setSelectedSuggestion(null);
                                setExpenseName(value);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Expense Name"
                                name="expenseName"
                                required
                                fullWidth
                                helperText={
                                    selectedSuggestion
                                        ? `Adding onto existing material: ${selectedSuggestion.name}`
                                        : ''
                                }
                                FormHelperTextProps={{
                                    className: selectedSuggestion ? 'helper-text' : '',
                                }}
                                style={{ marginBottom: "10px" }}
                            />
                        )}
                    />
                )}
                <TextField
                    label="Date"
                    type="date"
                    name="expenseDate"
                    value={expenseDate}
                    onChange={(e) => {
                        setExpenseDate(e.target.value)
                    }}
                    required
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        inputProps: {
                            "data-date-format": "yyyy-MM-dd",
                            placeholder: "",
                        },
                    }}
                    style={{ marginBottom: "10px" }}
                />
                <TextField
                    label="Cost"
                    type="number"
                    step="0.01"
                    name="expenseCost"
                    value={expenseCost}
                    onChange={(e) => {
                        setExpenseCost(e.target.value)
                    }}
                    required
                    fullWidth
                    style={{ marginBottom: "10px" }}
                />
                <FormControl fullWidth required style={{ marginBottom: "10px" }}>
                    <InputLabel style={{ paddingLeft: "4px", paddingRight: "4px" }}>Unit</InputLabel>
                    <Select
                        name="unit"
                        value={unit}
                        onChange={(e) => {
                            setUnit(e.target.value)
                        }}
                        label="Unit"
                        disabled={selectedSuggestion !== null}
                    >
                        <MenuItem value="Quantity">Quantity</MenuItem>
                        <MenuItem value="Grams">Grams</MenuItem>
                        <MenuItem value="Kilograms">Kilograms</MenuItem>
                        <MenuItem value="Milliliters">Milliliters</MenuItem>
                        <MenuItem value="Liters">Liters</MenuItem>
                    </Select>
                    {selectedSuggestion !== null && (
                        <FormHelperText>
                            Unit is locked to {unit} based on the selected material.
                        </FormHelperText>
                    )}
                </FormControl>
                <TextField
                    label="Quantity"
                    type="number"
                    step="1"
                    name="quantity"
                    value={quantity}
                    onChange={(e) => {
                        setQuantity(e.target.value)
                    }}
                    required
                    fullWidth
                    style={{ marginBottom: "10px" }}
                />
            </form>
        </div>
    );
});

export default ExpenseForm;