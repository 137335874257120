import React, { useState, useEffect, useContext, useCallback } from 'react';
import { logEvent } from './utilities/analytics';
import { isElectron } from './utilities/ElectronUtils';
import { UserContext } from './context/UserContext';
import useScreenWidth from './hooks/useScreenWidth';
import Tutorial from './tutorial/Tutorial';
import Changelog from './components/Changelog';
import DashboardContent from './pages/DashboardContent';
import ExpensesPurchases from './pages/ExpensesPurchases';
import HardwareMaintenance from './pages/HardwareMaintenance';
import Materials from './pages/Materials';
import Products from './pages/Products';
import Orders from './pages/Orders';
import { fetchOrderCount } from './api/ordersAPI';
import Feedback from './pages/FeedbackHub';
import Account from './pages/Account';
import Settings from './pages/Settings';
import Market from './pages/Market';
import { ThemeProvider } from '@emotion/react'
import getTheme from './styles/MaterialUIStyles';
import {
    IconButton,
    List,
    ListItem,
    Tooltip,
    Drawer,
    AppBar,
    Toolbar,
    Hidden,
    Typography,
    Badge
} from '@mui/material';
import {
    DashboardOutlined as DashboardIcon,
    AccountBalanceWalletOutlined as ExpensesIcon,
    CategoryOutlined as MaterialIcon,
    //BuildOutlined as HardwareIcon,
    WarehouseOutlined as ProductsIcon,
    ShoppingCartOutlined as OrdersIcon,
    AccountCircleOutlined as AccountCircleIcon,
    SettingsOutlined as SettingsIcon,
    Menu as MenuIcon,
    Feedback as FeedbackIcon,
    //LocalMallOutlined as MarketIcon,
} from '@mui/icons-material';

function Dashboard() {
    const [changelogOpen, setChangelogOpen] = useState(false);
    const [selectedComponent, setSelectedComponent] = useState('');
    const [mobileOpen, setMobileOpen] = useState(false);
    const screenWidth = useScreenWidth();

    const { userData, userUserSettings } = useContext(UserContext);
    const [darkMode, setDarkMode] = useState(userData?.settings?.darkMode === '1' || false);

    const theme = getTheme(darkMode);

    const [showChangelog] = useState(userData?.settings?.showChangelog === '1' || false);
    const [showTutorial, setShowTutorial] = useState(false);

    const [orderCount, setOrderCount] = useState(0);

    useEffect(() => {
        const newDarkMode = userData?.settings?.darkMode === '1';
        setDarkMode(newDarkMode);
    }, [userData]);

    useEffect(() => {
        if (userData?.settings?.lastChangelog !== process.env.REACT_APP_VERSION) {
            if (showChangelog) {
                handleChangelogOpen();
            }
            userUserSettings({ lastChangelog: process.env.REACT_APP_VERSION });
        }
    }, [userData, showChangelog, userUserSettings]);

    const getOrderCount = useCallback(async () => {
        try {
          const count = await fetchOrderCount([{ field: 'status', operator: '!=', value: '2' }]);
          if (count) {
            setOrderCount(count.totalCount);
          }
        } catch (error) {
          console.error('Error fetching order count:', error);
        }
      }, []);

    useEffect(() => {
        getOrderCount();
    }, [selectedComponent, getOrderCount]);

    useEffect(() => {
        const interval = setInterval(() => {
            getOrderCount();
        }, 10 * 60 * 1000); // 10 minutes in milliseconds

        return () => clearInterval(interval);
    }, [getOrderCount]);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    function NavigationButton({ icon, isSelected, onClick, label, badgeContent }) {
        return (
            <ThemeProvider theme={theme}>
                <Tooltip title={label} placement="right">
                    <ListItem button onClick={onClick}>
                        <IconButton edge="start" color={isSelected ? "primary" : "secondary"}>
                            <Badge 
                                badgeContent={badgeContent > 0 ? badgeContent : null} 
                                color="error"
                                max={99}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    '& .MuiBadge-badge': { fontSize: '0.6rem' },
                                }}
                                >
                                {icon}
                            </Badge>
                        </IconButton>
                    </ListItem>
                </Tooltip>
            </ThemeProvider>
        );
    }

    const drawer = (
        <List className="bg-navigation w-14 flex flex-col items-center pt-4 overflow-y-auto scrollbar-hidden"
            style={{
                height: '100%',
                minHeight: screenWidth < 600 || isElectron ? 'calc(100vh - 56px)' : '100vh',
            }}
        >
            {[
                {
                    label: 'Dashboard',
                    icon: <DashboardIcon />,
                    isSelected: selectedComponent === '',
                    onClick: () => setSelectedComponent(''),
                },
                {
                    label: 'Expenses',
                    icon: <ExpensesIcon />,
                    isSelected: selectedComponent === 'expenses',
                    onClick: () => setSelectedComponent('expenses'),
                },
                {
                    label: 'Materials',
                    icon: <MaterialIcon />,
                    isSelected: selectedComponent === 'materials',
                    onClick: () => setSelectedComponent('materials'),
                },
                // {
                //     label: 'Hardware',
                //     icon: <HardwareIcon />,
                //     isSelected: selectedComponent === 'hardware',
                //     onClick: () => setSelectedComponent('hardware'),
                // },
                {
                    label: 'Products',
                    icon: <ProductsIcon />,
                    isSelected: selectedComponent === 'products',
                    onClick: () => setSelectedComponent('products'),
                },
                {
                    label: 'Orders',
                    icon: <OrdersIcon />,
                    isSelected: selectedComponent === 'orders',
                    onClick: () => setSelectedComponent('orders'),
                    badgeContent: orderCount,
                }
            ].map((buttonProps) => (
                <NavigationButton
                    key={buttonProps.label}
                    {...buttonProps}
                    onClick={() => {
                        buttonProps.onClick();
                        if (!isElectron && window.innerWidth < 600) {
                            handleDrawerToggle();
                        }
                    }}
                />
            ))}
            <div className="flex-grow" />

            {[
                {
                    label: 'Feedback',
                    icon: <FeedbackIcon />,
                    isSelected: selectedComponent === 'feedback',
                    onClick: () => setSelectedComponent('feedback'),
                },
                {
                    label: 'Account',
                    icon: <AccountCircleIcon />,
                    isSelected: selectedComponent === 'account',
                    onClick: () => setSelectedComponent('account'),
                },
                {
                    label: 'Settings',
                    icon: <SettingsIcon />,
                    isSelected: selectedComponent === 'settings',
                    onClick: () => setSelectedComponent('settings'),
                },
                // {
                //     label: 'Market',
                //     icon: <MarketIcon />,
                //     isSelected: selectedComponent === 'market',
                //     onClick: () => setSelectedComponent('market'),
                // },
            ].map((buttonProps) => (
                <NavigationButton
                    key={buttonProps.label}
                    {...buttonProps}
                    onClick={() => {
                        buttonProps.onClick();
                        if (!isElectron && window.innerWidth < 600) {
                            handleDrawerToggle();
                        }
                    }}
                />
            ))}
        </List>
    );

    const handleChangelogOpen = () => {
        logEvent('changelog_open', {
            changelog_action: 'open',
            description: 'User opened the changelog',
        });
        setChangelogOpen(true);
    };

    const handleChangelogClose = () => {
        logEvent('changelog_close', {
            changelog_action: 'close',
            description: 'User closed the changelog',
        });
        setChangelogOpen(false);
    };

    const renderPage = () => {
        switch (selectedComponent) {
            case 'expenses':
                return <ExpensesPurchases theme={theme} />;
            case 'hardware':
                return <HardwareMaintenance theme={theme} />;
            case 'materials':
                return <Materials theme={theme} />;
            case 'products':
                return <Products theme={theme} />;
            case 'orders':
                return <Orders theme={theme} getOrderCount={getOrderCount} />;
            case 'feedback':
                return <Feedback theme={theme} />;
            case 'account':
                return <Account theme={theme} />;
            case 'settings':
                return <Settings theme={theme} ChangeLogOpen={handleChangelogOpen} />;
            case 'market':
                return <Market theme={theme} />;
            default:
                return <DashboardContent theme={theme} />;
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="bg-primary h-screen p-0">
                <div className="h-full flex">
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor="left"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true,
                            }}
                            PaperProps={{
                                style: {
                                    top: screenWidth < 600 ? '56px' : '0px',
                                    height: screenWidth < 600 ? 'calc(100vh - 56px)' : '100vh',
                                },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        {drawer}
                    </Hidden>
                    <div className={`App-page ${isElectron ? 'App-titlebar' : ''}`}
                        style={screenWidth < 600 ? { paddingTop: '4rem' } : {}}
                    >
                        <Hidden smUp implementation="css">
                            <div style={{ zIndex: 1300, position: 'relative' }}>
                                <AppBar position="fixed" style={{ backgroundColor: 'rgb(51, 51, 51)' }}>
                                    <Toolbar>
                                        <IconButton
                                            color="inherit"
                                            aria-label="open drawer"
                                            edge="start"
                                            onClick={handleDrawerToggle}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                        <Typography variant="h6" noWrap className="App-logo-text">
                                            MakerMate
                                        </Typography>
                                    </Toolbar>
                                </AppBar>
                            </div>
                        </Hidden>
                        {renderPage()}
                        <Changelog open={changelogOpen} handleClose={handleChangelogClose} />
                    </div>
                </div>
            </div>
            {showTutorial && <Tutorial navigate={setSelectedComponent} handleClose={() => setShowTutorial(false)} />}
        </ThemeProvider>
    );
}

export default Dashboard;