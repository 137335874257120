import React, { useState, useCallback, useContext, useRef } from 'react';
import { submitProduct, deleteProduct } from './../../api/productAPI';
import { UserContext } from './../../context/UserContext';
import { useToast } from './../../components/Toast';
import ConfirmDialog from './../../components/ConfirmDialog';
import Components from './additionalDetails/Components';
import Integrations from './additionalDetails/Integrations';
import Varients from './additionalDetails/Varients';
import { TextField, Typography, Grid, Button, IconButton, Box, Tab, Tabs, FormControl, InputLabel, Select, MenuItem, Divider, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

const ProductForm = ({ item, onClose, isNew, setItems }) => {
    const { userData } = useContext(UserContext);
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ open: false, productId: null });

    const { showError, showSuccess } = useToast();
    const showErrorRef = useRef(showError);
    const showSuccessRef = useRef(showSuccess);

    const [tabValue, setTabValue] = useState(0);
    const [selectedComponents, setSelectedComponents] = useState(item?.components || []);

    const [itemName, setItemName] = useState(item?.name || '');
    const [itemStatus, setItemStatus] = useState(item?.status || '1');
    const [itemDescription, setItemDescription] = useState(item?.description || '');
    const [itemUnit, setItemUnit] = useState(item?.unit || 'Quantity');
    const [itemDetails, setItemDetails] = useState(item?.details || '');
    const [sellPrice] = useState(item?.sellPrice || 0.00); //setSellPrice
    const [itemSku, setItemSku] = useState(item?.sku || '');
    const [itemUpc, setItemUpc] = useState(item?.upc || '');
    const [itemHeight, setItemHeight] = useState(item?.height || '');
    const [itemWidth, setItemWidth] = useState(item?.width || '');
    const [itemLength, setItemLength] = useState(item?.length || '');
    const [itemWeight, setItemWeight] = useState(item?.weight || '');

    //const [itemImages, setItemImages] = useState([]);


    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleImageInputChange = (e) => {
        const file = e.target.files[0];
        console.log("Selected file:", file);
    };

    const findProductionQuantity = (components) => {
        let productionQuantity = null;
        components.forEach((component) => {
            let componentUseQuantity = Math.floor(component.total_remaining / component.quantity);
            if (productionQuantity === null || componentUseQuantity < productionQuantity) {
                productionQuantity = componentUseQuantity;
            }
        });
        if (productionQuantity === null) {
            productionQuantity = 0;
        }
        return productionQuantity;
    }

    const onSave = async () => {
        try {
            const newItem = {
                name: itemName,
                sku: itemSku,
                upc: itemUpc,
                status: itemStatus,
                description: itemDescription,
                details: itemDetails,
                unit: itemUnit,
                height: parseFloat(itemHeight),
                width: parseFloat(itemWidth),
                length: parseFloat(itemLength),
                weight: parseFloat(itemWeight),
                components: selectedComponents,
                sellPrice: parseFloat(sellPrice),
            };

            const data = await submitProduct(newItem, item?.id);

            //TODO: UPDATE the items in the list
            setItems((items) => {
                if (isNew) {
                    return [...items, data];
                } else {
                    const index = items.findIndex((item) => item.id === data.id);
                    const newItems = [...items];
                    newItems[index] = data;
                    return newItems;
                }
            });

            if (data !== false) {
                onClose();
            }
        } catch (error) {
            console.error("Error creating item:", error);
        }
    };

    const onDelete = useCallback(
        (productId, skipConfirmation = false) => {
            const removeProduct = () => {
                return deleteProduct(productId)
                    .then(() => {
                        //Remove the item from the items list using setItems
                        setItems((items) => items.filter((item) => item.id !== productId));

                        showSuccessRef.current('Expense deleted successfully');

                        onClose(true);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        showErrorRef.current('Failed to delete expense');
                    });
            };

            if (skipConfirmation || userData.settings.deleteWarnings === "0") {
                removeProduct();
            } else {
                setOpenDeleteDialog({ open: true, productId: productId, deleteFunction: removeProduct });
            }
        },
        [userData.settings.deleteWarnings, showErrorRef, showSuccessRef, onClose, setItems]
    );

    return (
        <>
            <Typography variant="h5" className="mb-4" align="left" style={{ position: 'sticky', top: 0, zIndex: 101, padding: '10px 0 0 10px' }}>
                {isNew ? 'Add New Product' : 'Edit Product'}
            </Typography>
            <IconButton
                onClick={onClose}
                style={{ position: 'absolute', top: 0, right: 0, zIndex: 101 }}
            >
                <CloseIcon />
            </IconButton>
            {!isNew && (
                <IconButton
                    onClick={() => onDelete(item?.id)}
                    style={{ position: 'absolute', top: 0, right: 40, zIndex: 101 }}
                >
                    <DeleteIcon />
                </IconButton>
            )}
            <IconButton
                onClick={onSave}
                style={{ position: 'absolute', top: 0, right: (!isNew ? 80 : 40 ), zIndex: 101 }}
            >
                <CheckIcon />
            </IconButton>
            <Divider />
            <Box sx={{ padding: '10px', overflow: 'auto', maxHeight: 'calc(100% - 46px)' }}>
                <Grid container spacing={3}>
                    {item?.pictures && !isNew && (
                        <Grid item xs={12} sm={6}>
                            {/* Display the first image as a placeholder */}
                            <img src={item?.pictures[0]} alt="Product" width="100%" />
                            {/* Image selector placeholder */}
                            <Typography variant="body1">Image selector goes here</Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Images</Typography>
                        <input
                            accept="image/*"
                            style={{ display: "none" }}
                            id="contained-button-file"
                            type="file"
                            onChange={handleImageInputChange}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" component="span">
                                Upload Image
                            </Button>
                        </label>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Details</Typography>
                        <TextField
                            label="Name"
                            defaultValue={itemName || ''}
                            onChange={(e) => setItemName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Status</InputLabel>
                            <Select
                                defaultValue={itemStatus || '1'}
                                onChange={(e) => setItemStatus(e.target.value)}
                            >
                                <MenuItem value="1">Active</MenuItem>
                                <MenuItem value="0">Inactive</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="SKU"
                            defaultValue={itemSku || ''}
                            onChange={(e) => setItemSku(e.target.value)}
                            fullWidth
                            margin="normal" />
                        <TextField
                            label="UPC"
                            defaultValue={itemUpc || ''}
                            onChange={(e) => setItemUpc(e.target.value)}
                            fullWidth
                            margin="normal" />
                        <TextField
                            label="Description"
                            defaultValue={itemDescription || ''}
                            onChange={(e) => setItemDescription(e.target.value)}
                            fullWidth
                            margin="normal" />
                        <TextField
                            label="Details"
                            defaultValue={itemDetails || ''}
                            onChange={(e) => setItemDetails(e.target.value)}
                            fullWidth
                            margin="normal" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Unit of Measure</Typography>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Unit</InputLabel>
                            <Select
                                defaultValue={itemUnit || '1'}
                                onChange={(e) => setItemUnit(e.target.value)}
                            >
                                <MenuItem value="Quantity">Quantity</MenuItem>
                                <MenuItem value="Grams">Grams</MenuItem>
                                <MenuItem value="Kilograms">Kilograms</MenuItem>
                                <MenuItem value="Milliliters">Milliliters</MenuItem>
                                <MenuItem value="Liters">Liters</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Unit Weight"
                            defaultValue={itemWeight || ''}
                            onChange={(e) => setItemWeight(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                            }}
                            fullWidth
                            margin="normal" />
                        <TextField
                            label="Unit Width"
                            defaultValue={itemWidth || ''}
                            onChange={(e) => setItemWidth(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">inches</InputAdornment>,
                            }}
                            fullWidth
                            margin="normal" />
                        <TextField
                            label="Unit Height"
                            defaultValue={itemHeight || ''}
                            onChange={(e) => setItemHeight(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">inches</InputAdornment>,
                            }}
                            fullWidth
                            margin="normal" />
                        <TextField
                            label="Unit Length"
                            defaultValue={itemLength || ''}
                            onChange={(e) => setItemLength(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">inches</InputAdornment>,
                            }}
                            fullWidth
                            margin="normal" />
                    </Grid>
                    {!isNew && (
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1">Inventory</Typography>
                            <Typography align="left">Production Quantity: {findProductionQuantity(item?.components)}</Typography>
                            <Typography align="left">Available Quantity: {item?.inventory || 0}</Typography>
                            {/* <Typography align="left">Open Order Quantity: {item?.openOrderQuantity || 0}</Typography> */}
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="Product tabs">
                                <Tab label="Components" />
                                <Tab label="Variants" disabled />
                                <Tab label="Integrations" disabled />
                            </Tabs>
                        </Box>
                        <TabPanel value={tabValue} index={0}>
                            <Components
                                item={item}
                                editMode={true}
                                selectedComponents={selectedComponents}
                                setSelectedComponents={setSelectedComponents} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Varients
                                item={item}
                                editMode={true} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <Integrations
                                item={item}
                                editMode={true} />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmDialog
                open={openDeleteDialog.open}
                title="Delete Product"
                content="Are you sure you want to delete this product? This action cannot be undone."
                onCancel={() => setOpenDeleteDialog({ open: false, expenseId: null })}
                onConfirm={() => {
                    openDeleteDialog.deleteFunction();
                    setOpenDeleteDialog({ open: false, expenseId: null });
                }}
            />
        </>
    );
};

export default ProductForm;