// ProductDetails.js
import React, { useState, useEffect } from 'react';
import { updateTabs } from './../../api/ordersAPI';
import {
    Grid,
    Paper,
    Tabs,
    Tab,
    Badge,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import DragHandleIcon from '@mui/icons-material/DragHandle';

function EditTab(props) {
    const { onClick } = props;
    return (
        <Tab
            onClick={onClick}
            label={
                <span>
                    <EditIcon />
                </span>
            }
            style={{ marginLeft: '8px' }}
        />
    );
}


const OrderTabs = ({ children, tabs, setTabs, activeTabIndex, setActiveTabIndex }) => {
    const [addTabDialogOpen, setAddTabDialogOpen] = useState(false);
    const [temporaryTabs, setTemporaryTabs] = useState(tabs);

    useEffect(() => {
        if (!addTabDialogOpen) {
            const timeout = setTimeout(() => {
                setTemporaryTabs(tabs);
            }, 200); // Adjust the delay to match the dialog transition duration

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [addTabDialogOpen, tabs]);

    const hasError = (index) => {
        const trimmedName = temporaryTabs[index].text.trim();
        if (trimmedName === '') return true;

        const duplicate = temporaryTabs.find(
            (tab, i) => i !== index && tab.text.trim() === trimmedName
        );
        return !!duplicate;
    };

    const handleTabChange = (event, newValue) => {
        setActiveTabIndex(newValue);
    };

    const capitalizeFirstLetters = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const addTab = (index) => {
        const text = capitalizeFirstLetters('');
        setTemporaryTabs((prevTabs) =>
            [
                ...prevTabs.slice(0, index),
                { id: null, text }, // Set the id property to null for new tabs
                ...prevTabs.slice(index),
            ].map((tab, i) => ({ ...tab, order: i }))
        );
    };

    const deleteTemporaryTab = (index) => {
        setTemporaryTabs((prevTabs) =>
            prevTabs.filter((_, i) => i !== index).map((tab, i) => ({ ...tab, order: i }))
        );
    };

    const handleSaveTabs = async () => {
        // Call the updateTabs function and pass the temporaryTabs
        const updatedTabs = await updateTabs(temporaryTabs);
        setTabs(updatedTabs);
        setAddTabDialogOpen(false);
        setActiveTabIndex(0);
    };

    const updateTemporaryTabName = (index, newName) => {
        setTemporaryTabs((prevTabs) =>
            prevTabs.map((tab, i) => (i === index ? { ...tab, text: capitalizeFirstLetters(newName) } : tab))
        );
    };

    const renderAddTabButton = (index) => (
        <ListItemButton onClick={() => addTab(index)}>
            <ListItemIcon>
                <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Add New Tab" />
        </ListItemButton>
    );

    return (
        <>
            <Grid item xs={12}>
                <Paper className="bg-white text-black p-1 rounded-lg shadow-md">
                    <Tabs
                        value={activeTabIndex}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {tabs.map((tab, index) => (
                            <Tab
                                key={index}
                                label={
                                    tab.text === 'Completed' ? (
                                        tab.text
                                    ) : (
                                        <>
                                            <Badge
                                                badgeContent={tab.totalOrders}
                                                max={999}
                                                color="primary"
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 11,
                                                    right: 13,
                                                    '& .MuiBadge-badge': { fontSize: '0.6rem' },
                                                }}
                                            />
                                            {tab.text}
                                        </>
                                    )
                                }
                            />
                        ))}
                        <EditTab onClick={() => setAddTabDialogOpen(true)} />
                    </Tabs>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {children}
                </Grid>
            </Grid>
            <Dialog
                open={addTabDialogOpen}
                onClose={() => setAddTabDialogOpen(false)}
                aria-labelledby="add-tab-dialog-title"
            >
                <DialogTitle id="add-tab-dialog-title">Manage Tabs</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add, edit, remove or rearrange tabs. You cannot remove the default Open and Completed tabs.
                    </DialogContentText>
                    <List>
                        {temporaryTabs.map((tab, index) => {
                            if (tab.text === 'Completed') {
                                return (
                                    <React.Fragment key={index}>
                                        {renderAddTabButton(index)}
                                        <ListItem>
                                            <ListItemIcon>
                                                <LockIcon />
                                            </ListItemIcon>
                                            <ListItemText>{tab.text}</ListItemText>
                                        </ListItem>
                                    </React.Fragment>
                                );
                            }

                            return (
                                <React.Fragment key={index}>
                                    <ListItem>
                                        {tab.locked ? (
                                            <ListItemIcon>
                                                <LockIcon />
                                            </ListItemIcon>
                                        ) : (
                                            <ListItemIcon>
                                                <DragHandleIcon />
                                            </ListItemIcon>
                                        )}
                                        <ListItemText>
                                            {tab.locked ? (
                                                tab.text
                                            ) : (
                                                <TextField
                                                    value={temporaryTabs[index].text}
                                                    onChange={(e) => updateTemporaryTabName(index, e.target.value)}
                                                    label="Tab Name"
                                                    fullWidth
                                                    error={hasError(index)}
                                                />
                                            )}
                                        </ListItemText>
                                        {!tab.locked && (
                                            <ListItemSecondaryAction>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => deleteTemporaryTab(index)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        )}
                                    </ListItem>
                                </React.Fragment>
                            );
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddTabDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveTabs} color="primary" disabled={temporaryTabs.some((_, i) => hasError(i))}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OrderTabs;
