// Log page views using GA4
export const logPageView = () => {
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
        window.gtag('event', 'page_view', {
            page_path: window.location.pathname,
            page_title: document.title,
        });
    }
};

// Log events using GA4
export const logEvent = (action, params) => {
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
        window.gtag('event', action, params);
    }
};