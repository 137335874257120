import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

export default function BasicSpeedDial({
    icon,
    actions = [],
    onSpeedDialClick,
}) {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        if (actions.length === 0) {
            onSpeedDialClick && onSpeedDialClick();
        } else {
            setOpen(!open);
        }
    };

    const handleActionClick = (action) => {
        setOpen(false);
        action.onClick && action.onClick();
    };

    return (
        <SpeedDial
            key={actions.length} // Add this line
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'absolute', bottom: 16, right: 24 }}
            icon={
                icon ? (
                    <span sx={{ display: 'inline-flex', alignItems: 'center' }}>{icon}</span>
                ) : (
                    <SpeedDialIcon sx={{ '& .MuiSpeedDialIcon-icon': { marginBottom: '5px' } }} />
                )
            }
            onClick={handleClick}
            open={open}
            onMouseEnter={(e) => e.stopPropagation()}
        >
            {actions.length > 0
                ? actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={() => handleActionClick(action)}
                        />
                    ))
                : null}
        </SpeedDial>
    );
}
