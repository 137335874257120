import React, {
  useState,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { logEvent } from './../utilities/analytics';
import { fetchOrders, fetchTabs } from './../api/ordersAPI';
import { isElectron } from './../utilities/ElectronUtils'
import useScreenWidth from './../hooks/useScreenWidth';
import OrderCard from './../components/orders/OrderCard';
import OrderDetails from './../components/orders/OrderDetails';
import OrderForm from './../components/orders/OrderForm';
import OrderTabs from './../components/orders/OrderTabs';
import SpeedDial from './../components/SpeedDial';
import {
  Typography,
  Container,
  Grid,
  Card,
  Skeleton
} from '@mui/material';
import {
    Add,
} from '@mui/icons-material';
//import { deleteOrder } from '../../../api/controllers/orderController';

function Orders({ getOrderCount }) {
  const [orders, setOrders] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null);
  const isMounted = useRef(true);

  const screenWidth = useScreenWidth();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabs, setTabs] = useState([
    { id: 1, text: 'Open', locked: true },
    { id: 2, text: 'Completed', locked: true },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(25);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderForm, setShowOrderForm] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateTabTotalOrders = useCallback((oldStatusId, newStatusId, totalCount = null) => {
    setTabs((prevTabs) => {
      const updatedTabs = prevTabs.map((tab) => {
        if (tab.id === oldStatusId) {
          return { ...tab, totalOrders: totalCount === null ? tab.totalOrders - 1 : totalCount };
        } else if (tab.id === newStatusId) {
          return { ...tab, totalOrders: totalCount === null ? tab.totalOrders + 1 : totalCount };
        }
        return tab;
      });
      return updatedTabs;
    });

    getOrderCount();
  }, [getOrderCount]);

  const fetchOrdersData = useCallback(async (filters = [], sort = [], currentPage, pageSize) => {
    setIsLoading(true);
    try {
      const fetchedOrders = await fetchOrders(currentPage, pageSize, sort, filters);

      setOrders((prevOrders) => {
        const updatedOrders = [...prevOrders];
        fetchedOrders.orders.forEach((newOrder) => {
          const index = updatedOrders.findIndex((order) => order.id === newOrder.id);
          if (index !== -1) {
            // Update the existing order
            updatedOrders[index] = newOrder;
          } else {
            // Add the new order to the list
            updatedOrders.push(newOrder);
          }
        });

        if (updatedOrders.length === fetchedOrders.totalCount) {
          setHasMore(false);
        }

        return updatedOrders;
      });

      return fetchedOrders;
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);

      getOrderCount();
    }
  }, [getOrderCount]);

  const fetchOrdersTabData = useCallback(async (tabIndex, clear = false) => {
    const filter = [{ field: 'status', operator: 'equals', value: tabs[tabIndex].id }];
    if (clear) {
      setCurrentPage(0);
      setOrders([]);
    }
    const fetchedOrders = await fetchOrdersData(filter, [], currentPage, pageSize);
    updateTabTotalOrders(tabs[tabIndex].id, tabs[tabIndex].id, fetchedOrders.totalCount);
  }, [currentPage, pageSize, tabs, updateTabTotalOrders, fetchOrdersData]);

  const fetchOrdersTabDataRef = useRef(fetchOrdersTabData);

  useEffect(() => {
    fetchOrdersTabDataRef.current = fetchOrdersTabData;
  }, [fetchOrdersTabData]);

  useEffect(() => {
    logEvent('navigate_orders', {
      page_name: 'Orders',
      description: 'User navigated to the orders page',
    });
  }, []);

  useEffect(() => {
    const fetchTabsData = async () => {
      try {
        const fetchedTabs = await fetchTabs();
        if (fetchedTabs) {
          setTabs(fetchedTabs);
        }
      } catch (error) {
        console.error('Error fetching tabs:', error);
      }
    };

    fetchTabsData();
  }, []);

  useEffect(() => {
    // Fetch orders when the component mounts
    fetchOrdersTabDataRef.current(activeTabIndex, true);
  }, [fetchOrdersTabDataRef, activeTabIndex]);

  useEffect(() => {
    if (isLoading || !hasMore) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      {
        threshold: 1,
      }
    );

    const currentLoader = loader.current;

    if (currentLoader) {
      observer.observe(currentLoader);
    }

    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [hasMore, isLoading]);

  return (
    <Container>
      <Grid container spacing={3} pb={2}>
        <Grid item md={12}>
          <Typography variant="h4" className="text-white mb-4" align="left">
            Orders
          </Typography>
        </Grid>
        <OrderTabs
          tabs={tabs}
          setTabs={setTabs}
          fetchOrdersTabData={fetchOrdersTabData}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
        >
          {orders.map((order) => (
            <Grid item key={order.id} xs={12} sm={6} md={4}>
              <Card
                className={`${(selectedOrder === order) ? 'card card-expanded' : 'card'}  ${isElectron ? 'electron-app' : ''} ${(screenWidth < 600) ? 'mobile' : ''} `}
              >
                {selectedOrder === order ? (
                  <>
                    <OrderDetails
                      order={order}
                      onClose={() => {
                        setSelectedOrder(null)
                        getOrderCount();
                      }} 
                      setOrders={setOrders}
                      status={tabs}
                    />
                  </>
                ) : (
                  <>
                    <div>
                      <OrderCard
                        order={order}
                        setOrders={setOrders}
                        tabs={tabs}
                        updateTabTotalOrders={updateTabTotalOrders}
                        setSelectedOrder={setSelectedOrder}
                        activeTabIndex={activeTabIndex} />
                    </div>
                  </>
                )}
              </Card>
            </Grid>
          ))}
          {isLoading &&
            Array.from({ length: 4 }).map((_, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Skeleton variant="rectangular" height={115} />
              </Grid>
            ))}
        </OrderTabs>
      </Grid>
      <div ref={loader} style={{ height: '1px' }}></div>
      {!hasMore && (
        <Typography variant="body2" align="center" sx={{ width: '100%' }}>
          No more results
        </Typography>
      )}
      {showOrderForm && (
        <Card
          className={`card card-expanded ${isElectron ? 'electron-app' : ''} ${(screenWidth < 600) ? 'mobile' : ''} `}
        >
          <OrderForm
            isNew={true}
            onClose={(deleted = false) => {
              setShowOrderForm(false);
              if(deleted){
                setSelectedOrder(null);
              }
              getOrderCount();
            }}
            setOrders={setOrders}
          />
        </Card>
      )}
      {!showOrderForm && selectedOrder === null && (
        <SpeedDial
            onSpeedDialClick={() => setShowOrderForm(true)}
            icon={<Add />}
        />
    )}
    </Container>
  );
}

export default Orders;
