import React from 'react';
import { useNavigate } from 'react-router-dom';
import LandingNavigation from './components/LandingPage/LandingNavigation';
import Banner from './components/LandingPage/Banner';
//import Pricing from './components/LandingPage/Pricing';
import Features from './components/LandingPage/Features';
//import Testimonials from './components/LandingPage/Testimonials';
//import FAQs from './components/LandingPage/FAQs';
import ContactUs from './components/LandingPage/ContactUs';
import Footer from './components/LandingPage/Footer';

function LandingPage({ sectionRefs }) {
  const navigate = useNavigate();

  const handleLinkClick = (e, path) => {
    e.preventDefault();
    navigate(path);
  };

  const BannerContent = {
    title: 'Take Control of Your E-Store',
    body: 'Do away with all the labor required to maintain your spreadsheets with our powerful management tool.',
    additional: (
      <div className="flex flex-row items-center space-x-6">
        <a href="/download" onClick={(e) => handleLinkClick(e, '/download')} className="btn btn-primary bg-secondary text-white px-6 py-2 rounded hover:bg-tertiary">Download for Windows</a>
        <a href="/login" onClick={(e) => handleLinkClick(e, '/login')} className="btn btn-primary bg-secondary text-white px-6 py-2 rounded hover:bg-tertiary">Open in your browser</a>
      </div>
    )
  };

  const featuresList = [
    {
      title: 'Customizable Dashboard',
      body:
        'Gain full visibility of your important business information in one centralized location. Customize your dashboard to display the metrics and data that matter most to you, ensuring that you can make informed decisions quickly and efficiently. With our intuitive drag-and-drop interface, you can easily organize and prioritize the data that is most relevant to your business goals and objectives. Tailor the dashboard to fit your unique needs and preferences.',
      screenshot: 'Dashboard.png',
      layout: 'left',
    },
    {
      title: 'Expense Management',
      body:
        'Simplify tracking and managing your business expenses with our easy-to-use expense management system. Categorize, tag, and monitor your expenditures to stay on top of your finances, helping you identify cost-saving opportunities and optimize your budget. Make data-driven decisions that improve your financial health by analyzing spending patterns and trends with our comprehensive reporting tools.',
      screenshot: 'Expenses.png',
      layout: 'right',
    },
    // {
    //   title: 'Hardware & Maintenace Management',
    //   body:
    //     'Effectively manage all your hardware and maintenance requirements in one place. Schedule regular maintenance, track warranty information, and monitor the performance of your equipment to ensure your business operates smoothly and minimizes downtime.',
    //   screenshot: 'Hardware.png',
    //   layout: 'left',
    // },
    {
      title: 'Production Inventory',
      body:
        'Effortlessly track your production material levels and optimize your stock management. With our intuitive production inventory management system, you can easily monitor material consumption to ensure that you always have the right materials on hand when you need them. Stay ahead of production demands and reduce stockouts or excess inventory.',
      screenshot: 'Materials.png',
      layout: 'left',
    },
    {
      title: 'Product Inventory',
      body:
        'Effortlessly track your products and optimize your stock management. Our powerful product inventory management system allows you to maintain accurate inventory counts. Ensure that you can meet customer demands without tying up resources in excess inventory.',
      screenshot: 'Products.png',
      layout: 'right',
    },
    {
      title: 'Order Management',
      body:
        'Efficiently manage all your orders from a single, unified platform. Track the status of your orders from receipt to fulfillment, and manage shipping and tracking information with ease. Improve order processing efficiency and enhance your customer experience. Stay on top of every order and ensure customer satisfaction by proactively addressing any issues or delays that may arise.',
      screenshot: 'Orders.png',
      layout: 'left',
    },
    // {
    //   title: 'Desktop & Mobile Apps',
    //   body:
    //     'Stay connected to your business from anywhere, at any time, with our comprehensive desktop and mobile apps. Access critical information and perform key tasks on the go, ensuring that you never miss an opportunity or a deadline.',
    //   screenshot: 'Apps.png',
    //   layout: 'below',
    // },
  ];

  // const pricingPlans = [
  //   {
  //     title: 'Core Features',
  //     price: 'Free',
  //     features: [
  //       'Customizable dashboard',
  //       'Comprehensive expense tracking',
  //       'Material control and tracking',
  //       'Hardware management and maintenance',
  //       'Real-time inventory tracking',
  //       'Order management and tracking',
  //       'Advanced estimator tool',
  //       '1 year of data retention',
  //     ],
  //     buttonText: 'Start Now',
  //     buttonDestination: '/signup',
  //   },
  //   {
  //     title: 'Integration Module',
  //     price: 'Starting at $9.99 / Month / Integration',
  //     features: [
  //       'Seamless integration with one online store',
  //       'Automatic order synchronization',
  //       'Real-time inventory level synchronization',
  //       'Flexible pricing tiers to suit your needs',
  //       'Extended data retention',
  //       'Priority support'
  //     ],
  //     buttonText: 'Learn More',
  //     buttonDestination: '/integration-module',
  //   },
  //   {
  //     title: 'Reporting Module',
  //     price: '$4.99 / Month',
  //     features: [
  //       'Export and download data in various report formats',
  //     ],
  //     buttonText: 'Learn More',
  //     buttonDestination: '/reporting-module',
  //   },
  //   {
  //     title: 'Tax Module',
  //     price: '$19.99 / Year',
  //     features: [
  //       'Automated tax report generation',
  //       'Simplified e-commerce store tax filing',
  //     ],
  //     buttonText: 'Learn More',
  //     buttonDestination: '/tax-module',
  //   },
  // ];

  // const testimonials = [
  //   {
  //     name: 'Michael Morris',
  //     role: 'Owner',
  //     businessName: 'Maker Mikes',
  //     businessLink: 'https://www.etsy.com/shop/makermikesshop',
  //     comment: 'As the owner of Maker Mikes, I wanted a simple solution for managing my online store. I discovered there are not many solutions that are both affordable and easy to use, so I decided to build my own. During the development process, I realized that this application could be useful to other small business owners, so I decided to make it available to the public. I hope you enjoy using it as much as I enjoyed building it!',
  //   },
  //   {
  //     name: 'Filler Name',
  //     businessName: 'Filler Company',
  //     comment: 'lipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  //   },
  // ];

  // const faqs = [
  //   {
  //     question: 'How do I get started with the application?',
  //     answer: 'Simply sign up for a free account and follow the onboarding steps.',
  //   },
  //   {
  //     question: 'Is there a trial period for the paid modules?',
  //     answer: 'Yes, we offer a 7-day free trial for all most of our paid modules, so you can test them out and decide if they meet your needs.',
  //   },
  //   {
  //     question: 'Can I cancel my subscription at any time?',
  //     answer: 'Yes, you can cancel your subscription at any time from the account settings page, and you will not be billed for the next billing cycle.',
  //   },
  //   {
  //     question: 'Do you offer any discounts for annual billing?',
  //     answer: 'Yes, we offer a discount when you choose an annual billing plan. You can save up to 20% on your subscription fees by opting for annual billing.',
  //   },
  //   {
  //     question: 'What payment methods do you accept?',
  //     answer: 'We accept major credit cards, including Visa, Mastercard, and American Express. We also accept PayPal for your convenience.',
  //   },
  //   {
  //     question: 'Can I change my subscription plan later?',
  //     answer: 'Yes, you can easily upgrade or downgrade your subscription plan from the account settings page at any time.',
  //   },
  //   {
  //     question: 'Is my data secure?',
  //     answer: 'We take data security very seriously. All data transmitted between your device and our servers is encrypted using industry-standard encryption methods. We also follow best practices for data storage and access control.',
  //   },
  // ];

  return (
    <div className="min-h-screen bg-primary text-primary flex flex-col">
      <LandingNavigation sectionRefs={sectionRefs} />

      <main className="flex-grow overflow-y-auto mt-20">
        <Banner Title={BannerContent.title} Body={BannerContent.body} AdditionalContent={BannerContent.additional} />

        <Features ref={sectionRefs.features} featuresList={featuresList} />

        {/* <Pricing ref={sectionRefs.pricing} pricingPlans={pricingPlans} />

        <Testimonials ref={sectionRefs.testimonials} testimonials={testimonials} />

        <FAQs ref={sectionRefs.faqs} faqs={faqs} /> */}

        <ContactUs ref={sectionRefs.contactUs} />

        <Footer />

      </main>
    </div>
  );
}

export default LandingPage;