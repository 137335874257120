import React, { useContext, useEffect } from 'react';
import { UserContext } from './../context/UserContext';
import customFetch from './../utilities/customFetch';
import { setElectronToken } from '../utilities/ElectronUtils';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, TextField, Button, Typography, Link } from '@mui/material';

export default function LoginPage() {
    const { isLoggedIn, checkIsLoggedIn } = useContext(UserContext);
    const navigate = useNavigate();

    async function handleLogin(e) {
        e.preventDefault();

        const email = e.target.email.value;
        const password = e.target.password.value;

        try {
            let headers = {
                'Content-Type': 'application/json',
            };
        
            const response = await customFetch(process.env.REACT_APP_API_URL + 'login', {
                method: 'POST',
                body: JSON.stringify({ email, password }),
                headers: headers,
            });

            const { userToken } = await response.json();

            if(response.ok) {
                setElectronToken(userToken);
            } else {
                throw new Error('Invalid email or password');
            }

            //Check the authentication status
            checkIsLoggedIn();

            // Redirect the user to the dashboard or any other desired page
            if(isLoggedIn) {
                navigate('/dashboard');
            }
        } catch (error) {
            alert(error.message);
        }
    }

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/dashboard');
        }
    }, [isLoggedIn, navigate]);

    return (
        <div className="login-page">
            <Card className="login-card">
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Login
                    </Typography>
                    <form onSubmit={handleLogin} >
                        <TextField name="email" label="Email" type="email" fullWidth margin="normal" />
                        <TextField name="password" label="Password" type="password" fullWidth margin="normal" />
                        <Link href="#" onClick={() => { }}>
                            Forgot your password?
                        </Link>
                        <Button variant="contained" style={{ backgroundColor: '#1484ac', color: '#fff' }} fullWidth type="submit">
                            Login
                        </Button>
                        <Typography variant="body2">
                            Need an account?{' '}
                            <Link href="#" onClick={() => navigate('/signup')}>
                                Register
                            </Link>
                        </Typography>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}
