import React from 'react';
import { isElectron, ipcRenderer } from '../utilities/ElectronUtils'
import './../styles/TitleBar.css';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/CropSquare';
import CloseIcon from '@mui/icons-material/Close';
import Logo from './../assets/logo-beta.svg';

function TitleBar() {
  if (!isElectron) {
    return null; // Do not render title bar if not running in Electron
  }

  return (
    <div className="title-bar bg-navigation">
      <img src={Logo} alt="MakerMate" className="logo" />
      <div className="title">MakerMate</div>
      <div className="window-controls">
        <button onClick={() => ipcRenderer.send('minimize-window')} className="window-control-button"><MinimizeIcon /></button>
        <button onClick={() => ipcRenderer.send('maximize-window')} className="window-control-button"><MaximizeIcon /></button>
        <button onClick={() => ipcRenderer.send('close-window')} className="window-control-button"><CloseIcon /></button>
      </div>
    </div>
  );
}

export default TitleBar;
