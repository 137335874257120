import React from 'react';
import {
  TextField,
} from '@mui/material';

const Text = ({ layoutItem, isEditMode, text, setText }) => {

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  return (
    <>
      {isEditMode ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <TextField label="Text" value={text} onChange={handleTextChange} multiline />
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: ((layoutItem.h*150) + ((layoutItem.h-1)*10))-50 }}>
          <p>{text}</p>
        </div>
      )}
    </>
  );
};

export default Text;