// ProductDetails.js
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { fetchMaterials } from './../../../api/materialAPI';
import { styled } from '@mui/material/styles';
import { UserContext } from './../../../context/UserContext';
import { Box, Autocomplete, TextField, Badge, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider, Grid, Typography, Collapse, IconButton, InputAdornment } from '@mui/material';
import { ExpandLess as ExpandLessIcon } from '@mui/icons-material';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 35,
        top: 0,
        fontSize: '1rem',
        padding: '10px',
        margin: '0',
    },
}));

const Components = ({
    item,
    editMode,
    selectedComponents = [],
    setSelectedComponents = () => { },
}) => {
    const { userData } = useContext(UserContext);
    const [availableComponents, setAvailableComponents] = useState([]);

    const [selectedComponent, setSelectedComponent] = useState(null);
    const [quantityDialogOpen, setQuantityDialogOpen] = useState(false);
    const [totalCostOfMaterials, setTotalCostOfMaterials] = useState(0);
    const [componentQuantity, setComponentQuantity] = useState(1);
    const [openCollapseIndex, setOpenCollapseIndex] = useState(-1);

    useEffect(() => {
        const newTotalCost = selectedComponents.reduce((acc, component) => {
            return acc + component.cost_per_unit * component.quantity;
        }, 0);

        setTotalCostOfMaterials(newTotalCost.toFixed(2));
    }, [selectedComponents]);

    function addCurrencySymbol(costField) {
        const roundedCost = parseFloat(costField).toFixed(2);
        return `${userData?.settings?.currencySymbol}${roundedCost}`
    };

    const getAvailableComponents = useCallback(async (page = 0, pageSize = 25, filter = []) => {
        try {
            const selectedComponentIds = selectedComponents.map((component) => component.material_id);
            if (selectedComponentIds.length > 0) {
                filter.push({ field: 'material_id', operator: 'isNotAnyOf', value: selectedComponentIds });
            }
            const data = await fetchMaterials(page, pageSize, filter);
    
            if (data !== false) {
                const mergedComponents = [...selectedComponents, ...data.materials];
    
                setAvailableComponents(mergedComponents);
            }
        } catch (error) {
            console.error("Error getting available components:", error);
        }
    }, [selectedComponents]);    

    return editMode ? (
        <>
            <Autocomplete
                multiple
                id="components-selector"
                options={availableComponents}
                getOptionLabel={(option) => option.name}
                value={selectedComponents}
                onChange={(event, newValue) => {
                    const lastValue = newValue[newValue.length - 1];
                    if (lastValue && !selectedComponents.includes(lastValue)) {
                        setSelectedComponent(lastValue);
                        setQuantityDialogOpen(true);
                    } else {
                        setSelectedComponents(newValue);
                        //console.log("SetSelectedComponents:", newValue);
                    }
                }}
                isOptionEqualToValue={(option, value) => option.material_id === value.material_id}
                sx={{ marginTop: '10px' }}
                onInputChange={(event, value) => {
                    const searchFilter = [{ field: 'name', operator: 'contains', value: value }];
                    getAvailableComponents(0, 25, searchFilter);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Components"
                        placeholder="Select components"
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Box
                            key={option.material_id}
                            position="relative"
                            display="inline-flex"
                            sx={{ marginLeft: '5px', marginTop: '5px' }}
                        >
                            <Badge
                                badgeContent={option.quantity}
                                max={999}
                                color="primary"
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    position: 'absolute',
                                    top: 4,
                                    left: 6,
                                    '& .MuiBadge-badge': { fontSize: '0.6rem' },
                                }}
                            />
                            <Chip
                                label={option.name}
                                {...getTagProps({ index })}
                            />
                        </Box>
                    ))
                }
            />
            {selectedComponents.length > 0 && (
                <TextField
                    margin="dense"
                    label="Total Cost of Materials"
                    fullWidth
                    value={totalCostOfMaterials}
                    sx={{ marginTop: '10px' }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{userData?.settings?.currencySymbol}</InputAdornment>,
                        readOnly: true,
                    }}
                    helperText="Automatically calculated based on selected components and their quantities"
                />
            )}
            <Dialog open={quantityDialogOpen} onClose={() => setQuantityDialogOpen(false)}>
                <DialogTitle>Enter Component Quantity</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Quantity"
                        fullWidth
                        value={componentQuantity}
                        onChange={(e) => setComponentQuantity(e.target.value)}
                        type="number"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setQuantityDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setSelectedComponents([
                                ...selectedComponents,
                                {
                                    ...selectedComponent,
                                    quantity: componentQuantity,
                                },
                            ]);

                            setQuantityDialogOpen(false);
                            setComponentQuantity(1);
                        }}
                        color="primary"
                    >
                        Add Component
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    ) : (
        <>
            {item.components &&
                item.components.map((component, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                                marginTop: '10px',
                                padding: '10px',
                                border: '1px solid',
                                borderColor: 'grey.800',
                                borderRadius: '4px',
                            }}
                        >
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={1}>
                                    <ExpandMore
                                        expand={openCollapseIndex}
                                        onClick={() => setOpenCollapseIndex(openCollapseIndex === index ? -1 : index)}
                                        aria-expanded={openCollapseIndex}
                                        aria-label="show more"
                                    >
                                        <ExpandLessIcon />
                                    </ExpandMore>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant="h6" component="div" align="left">
                                        {component.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <StyledBadge
                                            badgeContent={component.quantity}
                                            color="primary"
                                            max={999}
                                        ></StyledBadge>
                                    </Box>
                                </Grid>
                                <Box width={1} sx={{ paddingLeft: '15px' }}>
                                    <Collapse in={openCollapseIndex === index}>
                                        <Grid item xs={12}>
                                            <Divider sx={{ marginBottom: '5px' }} />
                                        </Grid>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={3}>
                                                <Typography variant="subtitle2">Unit:</Typography>
                                                <Typography variant="body2">{component.unit}</Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography variant="subtitle2">Total Stock:</Typography>
                                                <Typography variant="body2">
                                                    {component.total_remaining}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography variant="subtitle2">Cost:</Typography>
                                                <Typography variant="body2">
                                                    {addCurrencySymbol(
                                                        component.cost_per_unit * component.quantity
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <Typography variant="subtitle2">Usable for Production:</Typography>
                                                <Typography variant="body2">
                                                    {Math.floor(component.total_remaining / component.quantity)} times
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                </Box>
                            </Grid>
                        </Box>
                    );
                })}
        </>
    );
};

export default Components;
