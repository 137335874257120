import React, { useEffect } from 'react';
import { logEvent } from './../utilities/analytics';
import {
  Typography,
  Container,
  Grid,
  Paper
} from '@mui/material';

function HardwareMaintenance() {

  useEffect(() => {
    logEvent('navigate_hardware', {
      page_name: 'Hardware',
      description: 'User navigated to the hardware page',
    });
  }, []);
  
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Typography variant="h4" className="text-white mb-4" align="left">
            Hardware
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className="bg-white text-black p-6 rounded-lg shadow-md">
            <div style={{ width: '100%', height: 400 }}>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default HardwareMaintenance;
