import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { logEvent } from './../utilities/analytics';
import { UserContext } from './../context/UserContext';
import {
  Container,
  Grid,
  Drawer,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  Card,
  CardContent,
} from '@mui/material';

import GeneralSettings from '../components/settings/GeneralSettings';
import InventorySettings from '../components/settings/InventorySettings';
import PaidServices from '../components/settings/PaidServices';
import Downloads from '../components/settings/Downloads';
import About from '../components/settings/About';

function Settings({ theme, ChangeLogOpen }) {
  const { userData, userUserSettings } = useContext(UserContext);
  const saveSettingsRef = useRef(null);
  const isInitialRender = useRef(true);

  useEffect(() => {
    logEvent('navigate_settings', {
      page_name: 'Settings',
      description: 'User navigated to the settings page',
    });
  }, []);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [selectedSetting, setSelectedSetting] = useState('general');

  saveSettingsRef.current = useCallback((key, value) => {
    const stringValue = typeof value === 'boolean' ? (value ? '1' : '0') : value;
    userUserSettings({ ...userData.settings, [key]: stringValue });
  }, [userUserSettings, userData.settings]);


  useEffect(() => {
    isInitialRender.current = false;
  }, []);


  const drawerContent = (
    <List>
      <ListItemButton
        sx={{ bgcolor: selectedSetting === 'general' ? 'primary.main' : 'inherit' }}
        onClick={() => {
          setSelectedSetting('general');
          logEvent('navigate_general_settings', {
            page_name: 'General Settings',
            description: 'User navigated to the general settings page',
          });
          if (isMobile) setDrawerOpen(false);
        }}
      >
        <ListItemText primary="General" />
      </ListItemButton>
      {/* <ListItemButton
        sx={{ bgcolor: selectedSetting === 'inventory' ? 'primary.main' : 'inherit' }}
        onClick={() => {
          setSelectedSetting('inventory');
          logEvent('navigate_inventory_settings', {
            page_name: 'Inventory Settings',
            description: 'User navigated to the inventory settings page',
          });
          if (isMobile) setDrawerOpen(false);
        }}
      >
        <ListItemText primary="Inventory" />
      </ListItemButton> */}
      {/* <ListItemButton
        sx={{ bgcolor: selectedSetting === 'paid' ? 'primary.main' : 'inherit' }}
        onClick={() => {
          setSelectedSetting('paid');
          logEvent('navigate_paid_module_settings', {
            page_name: 'Paid Module Settings',
            description: 'User navigated to the paid module settings page',
          });
          if (isMobile) setDrawerOpen(false);
        }}
      >
        <ListItemText primary="Paid Modules" />
      </ListItemButton> */}
      <ListItemButton
        sx={{ bgcolor: selectedSetting === 'downloads' ? 'primary.main' : 'inherit' }}
        onClick={() => {
          setSelectedSetting('downloads');
          logEvent('navigate_downloads_settings', {
            page_name: 'Downloads Settings Settings',
            description: 'User navigated to the paid about downloads page',
          });
          if (isMobile) setDrawerOpen(false);
        }}
      >
        <ListItemText primary="Downloads" />
      </ListItemButton>
      <ListItemButton
        sx={{ bgcolor: selectedSetting === 'about' ? 'primary.main' : 'inherit' }}
        onClick={() => {
          setSelectedSetting('about');
          logEvent('navigate_about_settings', {
            page_name: 'About Settings Settings',
            description: 'User navigated to the paid about settings page',
          });
          if (isMobile) setDrawerOpen(false);
        }}
      >
        <ListItemText primary="About" />
      </ListItemButton>
    </List>
  );

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {isMobile ? (
            <Drawer
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              anchor="bottom"
            >
              {drawerContent}
            </Drawer>
          ) : (
            <div>
              <Typography variant="h4" className="text-white mb-4" align="left">
                Settings
              </Typography>
              <Card className="mt-6">
                <CardContent>{drawerContent}</CardContent>
              </Card>
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={8}>
          {isMobile && (
            <div className="flex justify-between items-center mb-4">
              <Typography variant="h4">Settings</Typography>
              <button
                className="bg-gray-200 text-gray-700 p-2 rounded-md"
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                Menu
              </button>
            </div>
          )}

          <div style={{ marginTop: theme.spacing(1.4) }}>
            {selectedSetting === 'general' && (
              <>
                <Typography variant="h6" className="mb-4">
                  General Settings
                </Typography>
                <Card className="mb-4 mt-6">
                  <CardContent>
                    <GeneralSettings
                      userData={userData}
                      isInitialRender={isInitialRender}
                      saveSettingsRef={saveSettingsRef}
                    />
                  </CardContent>
                </Card>
              </>
            )}
            {selectedSetting === 'inventory' && (
              <>
                <Typography variant="h6" className="mb-4">
                  Inventory Settings
                </Typography>
                <Card className="mb-4 mt-6">
                  <CardContent>
                    <InventorySettings>
                      <GeneralSettings
                        userData={userData}
                        isInitialRender={isInitialRender}
                        saveSettingsRef={saveSettingsRef}
                      />
                    </InventorySettings>
                  </CardContent>
                </Card>
              </>
            )}
            {selectedSetting === 'paid' && (
              <>
                <Typography variant="h6" className="mb-4">
                  Paid Modules
                </Typography>
                <Card className="mb-4 mt-6">
                  <CardContent>
                    <PaidServices
                      isMobile={isMobile}
                    />
                  </CardContent>
                </Card>
              </>
            )}
            {selectedSetting === 'downloads' && (
              <>
                <Typography variant="h6" className="mb-4">
                  Downloads
                </Typography>
                <Card className="mb-4 mt-6">
                  <CardContent><Downloads /></CardContent>
                </Card>
              </>
            )}
            {selectedSetting === 'about' && (
              <>
                <Typography variant="h6" className="mb-4">
                  About
                </Typography>
                <Card className="mb-4 mt-6">
                  <CardContent>
                    <About
                      ChangeLogOpen={ChangeLogOpen}
                    />
                  </CardContent>
                </Card>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </Container>
  );

}


export default Settings;