import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  //Box,
} from '@mui/material';

function SuggestionDialog({ open, onClose, suggestion }) {
  return open && (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{suggestion.title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{suggestion.description}</Typography>
        {/* <Box sx={{ mt: 2 }}>
          <Typography variant="h6" component="h3" mb={1}>Comments</Typography>
          <Typography variant="body2">No comments yet.</Typography>
        </Box> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default SuggestionDialog;
