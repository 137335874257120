import React, {
    useCallback,
    useContext,
    useState,
    useRef
} from 'react';
import { deleteOrder } from './../../api/ordersAPI';
import { UserContext } from './../../context/UserContext';
import { useToast } from './../../components/Toast';
import ConfirmDialog from './../../components/ConfirmDialog';
import Items from './additionalDetails/Items';
import Notes from './additionalDetails/Notes';
import Shipment from './additionalDetails/Shipment';
import { submitOrder } from './../../api/ordersAPI';
import {
    Typography,
    IconButton,
    Divider,
    TextField,
    Box,
    Tabs,
    Tab,
    Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
        month = `0${month}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
};

const OrderForm = ({ order, onClose, isNew, setOrders }) => {
    const { userData } = useContext(UserContext);
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ open: false, productId: null });

    const { showError, showSuccess } = useToast();
    const showErrorRef = useRef(showError);
    const showSuccessRef = useRef(showSuccess);

    const [tabValue, setTabValue] = useState(0);
    const [lineItems, setLineItems] = useState(order?.lineItems || []);

    const orderVia = order?.via || 'Manual';
    const [orderNo, setOrderNo] = useState(order?.orderNo || '');
    const [orderDate, setOrderDate] = useState(order ? formatDate(order?.orderDate) : formatDate(new Date()));
    const [customerName, setCustomerName] = useState(order?.customerName || '');
    const [billToName, setBillToName] = useState(order?.billToName || '');
    const [billToStreet, setBillToStreet] = useState(order?.billToStreet || '');
    const [billToStreet2, setBillToStreet2] = useState(order?.billToStreet2 || '');
    const [billToStreet3, setBillToStreet3] = useState(order?.billToStreet3 || '');
    const [billToCity, setBillToCity] = useState(order?.billToCity || '');
    const [billToState, setBillToState] = useState(order?.billToState || '');
    const [billToZip, setBillToZip] = useState(order?.billToZip || '');
    const [billToCountry, setBillToCountry] = useState(order?.billToCountry || '');
    const [notes, setNotes] = useState(order?.notes || '');
    const [tax, setTax] = useState(order?.totalTax || '');
    const [shipmentPrice, setShipmentPrice] = useState(order?.shipmentPrice || '');
    const [copyBillTo, setCopyBillTo] = useState(order?.copyBillTo || false);
    const [shipmentData, setShipmentData] = useState({
        shipToName: order?.shipToName || '',
        shipToStreet: order?.shipToStreet || '',
        shipToStreet2: order?.shipToStreet2 || '',
        shipToStreet3: order?.shipToStreet3 || '',
        shipToCity: order?.shipToCity || '',
        shipToState: order?.shipToState || '',
        shipToZip: order?.shipToZip || '',
        shipToCountry: order?.shipToCountry || '',
        weight: order?.weight || '',
        length: order?.length || '',
        width: order?.width || '',
        height: order?.height || '',
        shipmentCharge: order?.shippingCharge || '',
        trackingNo: order?.trackingNo || '',
    });

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const onDelete = useCallback(
        (orderId, skipConfirmation = false) => {
            const removeOrder = () => {
                return deleteOrder(orderId)
                    .then(() => {
                        //Remove the item from the items list using setItems
                        setOrders((orders) => orders.filter((order) => order.id !== orderId));

                        showSuccessRef.current('Expense deleted successfully');

                        onClose(true);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        showErrorRef.current('Failed to delete expense');
                    });
            };

            if (skipConfirmation || userData.settings.deleteWarnings === "0") {
                removeOrder();
            } else {
                setOpenDeleteDialog({ open: true, orderId: orderId, deleteFunction: removeOrder });
            }
        },
        [userData.settings.deleteWarnings, showErrorRef, showSuccessRef, onClose, setOrders]
    );

    const validateData = useCallback(() => {
        //TODO: Test order # uniqueuness

        //Test order date is valid
        if (orderDate === null || orderDate.includes('NaN') || orderDate === '') {
            showError('Order date is required');
            return false;
        }

        //Test that at least 1 line item exists:
        if (lineItems.length === 0) {
            setTabValue(0);
            showError('At least 1 line item is required');
            return false;
        }

        //Test that all line items have a name, quantity and price
        for (const lineItem of lineItems) {
            if (!lineItem.name) {
                showError('Line item name is required');
                return false;
            }
            if (!lineItem.quantity) {
                showError('Line item quantity is required');
                return false;
            }
            if (!lineItem.sellPrice) {
                showError('Line item price is required');
                return false;
            }
        }

        //test that tax is set and shipping is set
        if (!tax) {
            showError('Tax is required');
            return false;
        }
        if (!shipmentPrice) {
            showError('Shipping is required');
            return false;
        }

        //Test that shipTo data has been filled in
        if (!shipmentData.shipToName && (copyBillTo && !billToName)) {
            showError('Ship to name is required');
            return false;
        }
        if (!shipmentData.shipToStreet && (copyBillTo && !billToStreet)) {
            showError('Ship to street is required');
            return false;
        }
        if (!shipmentData.shipToCity && (copyBillTo && !billToCity)) {
            showError('Ship to city is required');
            return false;
        }
        if (!shipmentData.shipToState && (copyBillTo && !billToState)) {
            showError('Ship to state is required');
            return false;
        }
        if (!shipmentData.shipToZip && (copyBillTo && !billToZip)) {
            showError('Ship to zip is required');
            return false;
        }
        if (!shipmentData.shipToCountry && (copyBillTo && !billToCountry)) {
            showError('Ship to country is required');
            return false;
        }
        return true;
    }, [
        orderDate,
        lineItems,
        tax,
        shipmentPrice,
        shipmentData,
        copyBillTo,
        billToCity,
        billToCountry,
        billToName,
        billToState,
        billToStreet,
        billToZip,
        showError
    ]);

    const onSave = useCallback(async () => {
        try {
            if (!validateData()) return;
            const updatedOrder = {
                orderNo: orderNo || null,
                via: orderVia,
                orderDate: orderDate,
                status: 1,
                customerName: customerName || null,
                billToName: billToName || null,
                billToStreet: billToStreet || null,
                billToStreet2: billToStreet2 || null,
                billToStreet3: billToStreet3 || null,
                billToCity: billToCity || null,
                billToState: billToState || null,
                billToZip: billToZip || null,
                billToCountry: billToCountry || null,
                shipToName: (copyBillTo ? billToName : shipmentData.shipToName) || null,
                shipToStreet: (copyBillTo ? billToStreet : shipmentData.shipToStreet) || null,
                shipToStreet2: (copyBillTo ? billToStreet2 : shipmentData.shipToStreet2) || null,
                shipToStreet3: (copyBillTo ? billToStreet3 : shipmentData.shipToStreet3) || null,
                shipToCity: (copyBillTo ? billToCity : shipmentData.shipToCity) || null,
                shipToState: (copyBillTo ? billToState : shipmentData.shipToState) || null,
                shipToZip: (copyBillTo ? billToZip : shipmentData.shipToZip) || null,
                shipToCountry: (copyBillTo ? billToCountry : shipmentData.shipToCountry) || null,
                copyBillTo: copyBillTo || null,
                lineItems: lineItems,
                totalTax: tax || null,
                shipmentPrice: shipmentPrice || null,
                weight: shipmentData.weight || null,
                length: shipmentData.length || null,
                width: shipmentData.width || null,
                height: shipmentData.height || null,
                shippingCharge: shipmentData.shipmentCharge || null,
                trackingNo: shipmentData.trackingNo || null,
                totalCost: lineItems.reduce((total, item) => total + (item.sellPrice * item.quantity), 0) + parseFloat(tax) + parseFloat(shipmentPrice),
                notes: notes || null,
            };

            const data = await submitOrder(updatedOrder, order?.id);

            if (data !== false) {
                setOrders((orders) => {
                    if (isNew) {
                        return [...orders, data];
                    } else {
                        const index = orders.findIndex((order) => order.id === data.id);
                        const newOrders = [...orders];
                        newOrders[index] = data;
                        return newOrders;
                    }
                });

                onClose(true);

                showSuccess('Order saved successfully');

            } else {
                showError('Failed to save order');
            }
        } catch (error) {
            console.error('Error:', error);
            showError('Failed to save order');
        }
    }, [
        isNew,
        order,
        orderVia,
        orderNo,
        orderDate,
        customerName,
        billToName,
        billToStreet,
        billToStreet2,
        billToStreet3,
        billToCity,
        billToState,
        billToZip,
        billToCountry,
        notes,
        tax,
        shipmentPrice,
        shipmentData,
        lineItems,
        copyBillTo,
        onClose,
        setOrders,
        validateData,
        showError,
        showSuccess
    ]);

    return (
        <>
            <Typography variant="h5" className="mb-4" align="left" style={{ position: 'sticky', top: 0, zIndex: 101, padding: '10px 0 0 10px' }}>
                {isNew ? 'Add New Order' : 'Edit Order'}
            </Typography>
            <IconButton
                onClick={onClose}
                style={{ position: 'absolute', top: 0, right: 0, zIndex: 101 }}
            >
                <CloseIcon />
            </IconButton>
            {!isNew && order.status !== 2 && orderVia === 'Manual' && (
                <IconButton
                    onClick={() => onDelete(order?.id)}
                    style={{ position: 'absolute', top: 0, right: 40, zIndex: 101 }}
                >
                    <DeleteIcon />
                </IconButton>
            )}
            <IconButton
                onClick={onSave}
                style={{ position: 'absolute', top: 0, right: ((!isNew && order.status !== 2 && orderVia === 'Manual') ? 80 : 40), zIndex: 101 }}
            >
                <CheckIcon />
            </IconButton>
            <Divider />
            <Box sx={{ padding: '10px', overflow: 'auto', maxHeight: 'calc(100% - 46px)' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Order Details</Typography>
                        {/* {!isNew && <Typography>Order Source: {orderVia}</Typography>} */}
                        {(isNew || orderVia === 'Manual') && (
                            <>
                                <TextField
                                    label="Order #"
                                    value={orderNo}
                                    onChange={(e) => setOrderNo(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                />
                            </>
                        )}
                        <TextField
                            label="Order Date"
                            type="date"
                            value={orderDate}
                            onChange={(e) => setOrderDate(e.target.value)}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            error={orderDate === null || orderDate.includes('NaN') || orderDate === ''}
                        />
                        {orderVia === 'Manual' && (
                            <TextField
                                label="Customer Name"
                                type="customerName"
                                value={customerName}
                                onChange={(e) => {
                                    setCustomerName(e.target.value)
                                    setBillToName(e.target.value)
                                }}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Billing Address</Typography>
                        <TextField
                            label="Name"
                            value={billToName}
                            onChange={(e) => setBillToName(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={copyBillTo && (billToName === null || billToName === '')}
                        />
                        <TextField
                            label="Street"
                            value={billToStreet}
                            onChange={(e) => setBillToStreet(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={copyBillTo && (billToStreet === null || billToStreet === '')}
                        />
                        <TextField
                            label="Street2"
                            value={billToStreet2}
                            onChange={(e) => setBillToStreet2(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Street3"
                            value={billToStreet3}
                            onChange={(e) => setBillToStreet3(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="City"
                            value={billToCity}
                            onChange={(e) => setBillToCity(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={copyBillTo && (billToCity === null || billToCity === '')}
                        />
                        <TextField
                            label="State"
                            value={billToState}
                            onChange={(e) => setBillToState(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={copyBillTo && (billToState === null || billToState === '')}
                        />
                        <TextField
                            label="Zip"
                            value={billToZip}
                            onChange={(e) => setBillToZip(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={copyBillTo && (billToZip === null || billToZip === '')}
                        />
                        <TextField
                            label="Country"
                            value={billToCountry}
                            onChange={(e) => setBillToCountry(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={copyBillTo && (billToCountry === null || billToCountry === '')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Product tabs"
                            >
                                <Tab label="Items" />
                                <Tab label="Shipment" />
                                <Tab label="Notes" />
                            </Tabs>
                        </Box>
                        <TabPanel value={tabValue} index={0}>
                            <Items
                                order={order}
                                editMode={true}
                                lineItems={lineItems}
                                setLineItems={setLineItems}
                                tax={tax}
                                setTax={setTax}
                                shippingPrice={shipmentPrice}
                                setShippingPrice={setShipmentPrice}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Shipment
                                order={order}
                                editMode={true}
                                shipmentData={shipmentData}
                                copyBillTo={copyBillTo}
                                setCopyBillTo={setCopyBillTo}
                                setShipmentData={setShipmentData}
                                billingData={{
                                    billToName: billToName,
                                    billToStreet: billToStreet,
                                    billToStreet2: billToStreet2,
                                    billToStreet3: billToStreet3,
                                    billToCity: billToCity,
                                    billToState: billToState,
                                    billToZip: billToZip,
                                    billToCountry: billToCountry
                                }}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <Notes
                                order={order}
                                editMode={true}
                                notes={notes}
                                setNotes={setNotes} />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Box>
            <ConfirmDialog
                open={openDeleteDialog.open}
                title="Delete Order"
                content="Are you sure you want to delete this order? This action cannot be order."
                onCancel={() => setOpenDeleteDialog({ open: false, expenseId: null })}
                onConfirm={() => {
                    openDeleteDialog.deleteFunction();
                    setOpenDeleteDialog({ open: false, expenseId: null });
                }}
            />
        </>
    );
};

export default OrderForm;