import React, { useState } from 'react';
//import { logEvent } from '../../utilities/analytics';
import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Stack,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const exampleModules = [
    {
      title: 'Integration Module',
      isActive: true,
      autoRenew: true,
      date: '2023-05-05',
    },
    {
      title: 'Reporting Module',
      isActive: false,
      autoRenew: false,
      date: '2023-06-10',
    },
    {
      title: 'Tax Module',
      isActive: false,
      autoRenew: false,
      date: '2023-06-10',
    },
    // {
    //   title: 'AI Tokens',
    //   isActive: false,
    //   autoRenew: false,
    //   date: '2023-06-10',
    // },
  ];

function PaidServices({ isMobile }) {
    const [open, setOpen] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const handlePurchaseOpen = (serviceName) => {
        setSelectedService(serviceName);
        setOpen(true);
    };

    const handlePurchaseClose = () => {
        setOpen(false);
    };

    return (
        <Grid container direction="column" spacing={2}>
            {exampleModules.map((module, index) => (
                <Grid item key={index}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
                                <Typography align="left">{module.title}</Typography>
                                <Chip
                                    label={module.isActive ? 'Active' : 'Inactive'}
                                    color={module.isActive ? 'success' : 'error'}
                                    variant="outlined"
                                    size="small"
                                />
                                {module.isActive && (
                                    <Chip
                                        label={module.autoRenew ? `Auto renews on ${module.date}` : `Expires on ${module.date}`}
                                        color={module.autoRenew ? 'success' : 'error'}
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Placeholder content for {module.title} will be added here later.
                            </Typography>
                            {!module.isActive ? (
                                <Button onClick={() => handlePurchaseOpen(module.title)}>Purchase</Button>
                            ) : (
                                ''
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            ))}
            <Dialog open={open} onClose={handlePurchaseClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Purchase {selectedService}</DialogTitle>
                <DialogContent>
                    {/* {selectedService && (
            <StripeCheckout serviceName={selectedService} />
          )} */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePurchaseClose} color="primary">
                        Purchase
                    </Button>
                    <Button onClick={handlePurchaseClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default PaidServices;