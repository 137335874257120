import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent } from './../utilities/analytics';
import { handleLogout } from './../utilities/logoutUtils';
import { UserContext } from './../context/UserContext';
import { useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Container,
  Grid,
  Drawer,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Card,
  CardContent,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip
} from '@mui/material';
import useScreenWidth from './../hooks/useScreenWidth';

function Account() {
  const { userData, userUserSettings, checkIsLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();
  
  const [updatedEmail, setUpdatedEmail] = useState(userData.email);
  const [updatedPassword, setUpdatedPassword] = useState('');
  const [showPasswordHelper, setShowPasswordHelper] = useState(true);

  const theme = useTheme();
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth <= 768;

  const [drawerOpen, setDrawerOpen] = useState(!isMobile);
  const [selectedSetting, setSelectedSetting] = useState('account');

  useEffect(() => {
    logEvent('navigate_account', {
      page_name: 'Account',
      description: 'User navigated to the account page',
    });
  }, []);

  const handleUpdateProfile = () => {
    userUserSettings({ ...userData, email: updatedEmail, password: updatedPassword });
    
    logEvent('update_account', {
      page_name: 'Update Account',
      description: 'User updated their account settings',
    });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setUpdatedPassword(newPassword);

    if (newPassword.trim() !== '') {
      setShowPasswordHelper(false);
    } else {
      setShowPasswordHelper(true);
    }
  };

  const exampleModules = [
    {
      title: 'Integration Module',
      isActive: true,
      autoRenew: true,
      date: '2023-05-05',
    },
    {
      title: 'Reporting Module',
      isActive: false,
      autoRenew: false,
      date: '2023-06-10',
    },
    {
      title: 'Tax Module',
      isActive: false,
      autoRenew: false,
      date: '2023-06-10',
    },
    // {
    //   title: 'ListingHelper Tokens',
    //   isActive: false,
    //   autoRenew: false,
    //   date: '2023-06-10',
    // },
  ];

  const drawerContent = (
    <List>
    <ListItemButton
      sx={{ bgcolor: selectedSetting === 'account' ? 'primary.light' : 'inherit' }}
      onClick={() => {
        setSelectedSetting('account');
        logEvent('navigate_account_settings', {
          page_name: 'Account Settings',
          description: 'User navigated to the account settings page',
        });
        if (isMobile) setDrawerOpen(false);
      }}
    >
      <ListItemText primary="Account" />
    </ListItemButton>
      <ListItemButton
        sx={{ bgcolor: 'inherit' }}
        onClick={() => {
          handleLogout(checkIsLoggedIn, navigate);
        }}
      >
        <ListItemText primary="Logout" />
      </ListItemButton>
      {/* <ListItemButton
        sx={{ bgcolor: selectedSetting === 'modules' ? 'primary.light' : 'inherit' }}
        onClick={() => {
          logEvent('navigate_account_modules', {
            page_name: 'Account Modules',
            description: 'User navigated to the account modules page',
          });
          setSelectedSetting('modules');
          if (isMobile) setDrawerOpen(false);
        }}
      >
        <ListItemText primary="Paid Modules" />
      </ListItemButton>
      <ListItemButton
        sx={{ bgcolor: selectedSetting === 'purchases' ? 'primary.light' : 'inherit' }}
        onClick={() => {
          logEvent('navigate_account_purchases', {
            page_name: 'Account Purchases',
            description: 'User navigated to the account purchases page',
          });
          setSelectedSetting('purchases');
          if (isMobile) setDrawerOpen(false);
        }}
      >
        <ListItemText primary="Purchases" />
      </ListItemButton> */}
    </List>
  );

  const accountModules = (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          label="Email"
          value={updatedEmail}
          type="email"
          fullWidth
          onChange={(e) => setUpdatedEmail(e.target.value)}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Password"
          value={updatedPassword}
          type="password"
          fullWidth
          onChange={handlePasswordChange}
          helperText={
            showPasswordHelper
              ? "Your password has already been set."
              : "The typed password will replace the current one."
          }
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateProfile}
        >
          Update Profile
        </Button>
      </Grid>
    </Grid>
  );

  const modules = (
    <Grid container direction="column" spacing={2}>
      {exampleModules.map((module, index) => (
        <Grid item key={index}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
                <Typography align="left">{module.title}</Typography>
                <Chip
                  label={module.isActive ? 'Active' : 'Inactive'}
                  color={module.isActive ? 'success' : 'error'}
                  variant="outlined"
                  size="small"
                />
                {module.isActive && (
                  <Chip
                    label={module.autoRenew ? `Auto renews on ${module.date}` : `Expires on ${module.date}`}
                    color={module.autoRenew ? 'success' : 'error'}
                    variant="outlined"
                    size="small"
                  />
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Placeholder content for {module.title} will be added here later.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );

  const purchasesModule = <div>{/* Purchase History module settings will be added here later */}</div>;

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {isMobile ? (
            <Drawer
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
              anchor="bottom"
            >
              {drawerContent}
            </Drawer>
          ) : (
            <div>
              <Typography variant="h4" className="text-white mb-4" align="left">
                Account
              </Typography>
              <Card className="mt-6">
                <CardContent>{drawerContent}</CardContent>
              </Card>
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={8}>
          {isMobile && (
            <div className="flex justify-between items-center mb-4">
              <Typography variant="h4">Account</Typography>
              <button
                className="bg-gray-200 text-gray-700 p-2 rounded-md"
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                Menu
              </button>
            </div>
          )}

          <div style={{ marginTop: theme.spacing(1.4) }}>
            {selectedSetting === 'account' && (
              <>
                <Typography variant="h6" className="mb-4">
                  Account Settings
                </Typography>
                <Card className="mb-4 mt-6">
                  <CardContent>{accountModules}</CardContent>
                </Card>
              </>
            )}
            {selectedSetting === 'modules' && (
              <>
                <Typography variant="h6" className="mb-4">
                  Paid Modules
                </Typography>
                <Card className="mb-4 mt-6">
                  <CardContent>{modules}</CardContent>
                </Card>
              </>
            )}
            {selectedSetting === 'purchases' && (
              <>
                <Typography variant="h6" className="mb-4">
                  Purchases
                </Typography>
                <Card className="mb-4 mt-6">
                  <CardContent>{purchasesModule}</CardContent>
                </Card>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </Container>
  );

}

export default Account;