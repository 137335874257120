import customFetch from './../utilities/customFetch';

export const fetchSuggestions = async (page, pageSize, sort, filters) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}feedback/get`;

        const requestData = {
            page: page,
            pageSize: pageSize,
            sort: sort,
            filter: filters,
        };

        const response = await customFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error('Failed to fetch suggestions:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return false;
    }
};

export const addSuggestion = async (title, description, submissionType) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}feedback`;

        const requestData = {
            title: title,
            description: description,
            type: submissionType,
        };

        const response = await customFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error('Failed to add suggestion:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error adding suggestion:', error);
        return false;
    }
};

export const toggleSuggestionVote = async (suggestionId) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}feedback/vote`;

        const requestData = {
            suggestion_id: suggestionId,
        };

        const response = await customFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error('Failed to toggle suggestion vote:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error toggling suggestion vote:', error);
        return false;
    }
};