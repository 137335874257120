import React from 'react';

const Footer = () => {
    return (
        <footer className="mt-16 pb-10">
            <div className="flex justify-center items-center">
                <p className="text-sm">&copy; {new Date().getFullYear()} MakerMate. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
