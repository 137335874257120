import React, { forwardRef, useState } from 'react';

const ContactUs = forwardRef((props, ref) => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission, e.g., send email or store in a database
        //console.log('Form data submitted:', formData);
        setFormData({ name: '', email: '', message: '' });
    };

    return (
        <section ref={ref} className="lg:mt-8 mt-2 bg-primary p-6 px-12">
            <h2 className="text-4xl font-bold text-center mb-8 text-primary">Contact Us</h2>
            <p className="text-xl mb-4">We'd love to hear from you! Reach out to us:</p>
            <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto">
                <label htmlFor="name" className="block text-sub mb-2">
                    Name:
                </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full mb-4 p-2 text-black bg-white rounded"
                />
                <label htmlFor="email" className="block text-sub mb-2">
                    Email:
                </label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full mb-4 p-2 text-black bg-white rounded"
                />
                <label htmlFor="message" className="block text-sub mb-2">
                    Message:
                </label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full mb-4 p-2 text-black bg-white rounded"
                    rows="4"
                ></textarea>
                <button type="submit" className="bg-secondary text-white py-2 px-4 rounded">
                    Send Message
                </button>
            </form>
        </section>
    );
});

export default ContactUs;
