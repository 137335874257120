// ProductDetails.js
import React, { useState, useEffect } from 'react';
import { productAdjustment } from './../../api/productAPI';
import {
    Typography,
    IconButton,
    CardActionArea,
    CardContent,
    CardActions,
    Tooltip,
    Box,
    CardMedia,
} from '@mui/material';
import {
    Add,
    Remove,
} from '@mui/icons-material';

const ProductCard = ({ item, setItems, setExpandedCard }) => {
    const [adjustmentAmount, setAdjustmentAmount] = useState(1);

    const handleAdjustment = (itemId, variantId, adjustment) => {
        productAdjustment(itemId, adjustment);
        setItems((prevItem) => {
            return prevItem.map((item) => {
                if (item.id === itemId) {
                    const newQuantity = Math.max(item.inventory + adjustment, 0);
                    return { ...item, inventory: newQuantity };
                }
                return item;
            });
        });
    };

    const handleKeyDown = (event) => {
        if (event.shiftKey) {
            setAdjustmentAmount(10);
        } else if (event.ctrlKey) {
            setAdjustmentAmount(100);
        } else {
            setAdjustmentAmount(1);
        }
    };

    const handleKeyUp = () => {
        setAdjustmentAmount(1);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    return (
        <>
            <CardActionArea onClick={() => setExpandedCard(item.id)} >
                <CardMedia
                    component="img"
                    height="140"
                    image="https://via.placeholder.com/1920x1080"
                    alt="placeholder-1920x1080"
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        {item.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {item.details}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions disableSpacing>
                {(!item.variants || item.variants.length === 0) && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Box>
                            <Tooltip title={`Decrease by ${adjustmentAmount}`}>
                                <span>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        aria-label="decrease"
                                        disabled={item.inventory === 0}
                                        onClick={() => handleAdjustment(item.id, null, -adjustmentAmount)}
                                    >
                                        <Remove />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip title={`Increase by ${adjustmentAmount}`}>
                                <IconButton
                                    size="small"
                                    color="primary"
                                    aria-label="increase"
                                    onClick={() => handleAdjustment(item.id, null, adjustmentAmount)}
                                >
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Typography sx={{ paddingRight: '10px' }}>{item.inventory || 0}</Typography>
                    </Box>
                )}
                {item.variants &&
                    item.variants.length > 0 &&
                    item.variants.map((variant, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Box>
                                <Tooltip title={`Decrease by ${adjustmentAmount}`}>
                                    <span>
                                        <IconButton
                                            size="small"
                                            color="primary"
                                            aria-label="decrease"
                                            disabled={variant.inventory === 0}
                                            onClick={() => handleAdjustment(item.id, variant.id, -adjustmentAmount)}
                                        >
                                            <Remove />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title={`Increase by ${adjustmentAmount}`}>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        aria-label="increase"
                                        onClick={() => handleAdjustment(item.id, variant.id, adjustmentAmount)}
                                    >
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                                <Typography sx={{ paddingLeft: '10px' }}>{variant.name}</Typography>
                            </Box>
                            <Typography sx={{ paddingRight: '10px' }}>{variant.inventory}</Typography>
                        </Box>
                    ))}
            </CardActions>
        </>
    );
};

export default ProductCard;
