import React, { useState } from 'react';
import Chart from './ChartComponent';
import Counter from './CounterComponent';
import Header from './HeaderComponent';
import Text from './TextComponent';
import { IconButton, Card } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

const ComponentWrapper = ({ component, layoutItem, editMode, setEditMode }) => {
  const [thisComponent, setThisComponent] = useState(component);

  const preventPropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const toggleEditMode = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEditMode(!editMode);
  };

  const handleClickSave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    toggleEditMode(e);
  };

  const handleClickDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // Implement your delete logic here
  };

  const buttonWrapper = () => {
    return (
      <Card
        style={{
          position: 'relative',
          padding: '25px',
          height: '100%',
          width: '100%'
        }}
      >
        {editMode ? (
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <div style={{ pointerEvents: 'none' }} >
              <IconButton onMouseUp={handleClickSave} onMouseDown={preventPropagation} style={{ pointerEvents: 'auto' }}>
                <SaveIcon />
              </IconButton>
              <IconButton onMouseUp={handleClickDelete} onMouseDown={preventPropagation} style={{ pointerEvents: 'auto' }}>
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        ) : (
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <div style={{ pointerEvents: 'none' }} >
              <IconButton onMouseUp={toggleEditMode} onMouseDown={preventPropagation} style={{ pointerEvents: 'auto' }}>
                <EditIcon />
              </IconButton>
            </div>
          </div>
        )}
        {ComponentSwitch()}
      </Card>
    );
  };

  const ComponentSwitch = () => {
    switch (thisComponent.type) {
      case 'chart':
        return (
          <Chart
            layoutItem={layoutItem}
            isEditMode={editMode}
            // onChartTypeChange={(chartType) =>
            //   handleChartTypeChange(Number(component.i), chartType)
            // }
            text={thisComponent.text}
            setText={(text) => setThisComponent({ ...thisComponent, text: text })}
          //size={size}
          //setSize={setSize}
          />
        );
      case 'header':
        return (
          <Header
            layoutItem={layoutItem}
            isEditMode={editMode}
            // onTextChange={(text) =>
            //   handleHeaderChange(Number(component.i), text)
            // }
            text={thisComponent.text}
            setText={(text) => setThisComponent({ ...thisComponent, text: text })}
          //size={size}
          //setSize={setSize}
          />
        );
      case 'text':
        return (
          <Text
            layoutItem={layoutItem}
            isEditMode={editMode}
            // onTextChange={(text) =>
            //   handleTextChange(Number(component.i), text)
            // }
            text={thisComponent.text}
            setText={(text) => setThisComponent({ ...thisComponent, text: text })}
          //size={size}
          //setSize={setSize}
          />
        );
      case 'counter':
        return (
          <Counter
            layoutItem={layoutItem}
            isEditMode={editMode}
            // onTextChange={(text) =>
            //   handleTextChange(Number(component.i), text)
            // }
            text={thisComponent.text}
            setText={(text) => setThisComponent({ ...thisComponent, text: text })}
          //size={size}
          //setSize={setSize}
          />
        );
      default:
        return null;
    }
  };

  return buttonWrapper();
};

export default ComponentWrapper;