import { createTheme } from '@mui/material';
import { grey,green } from '@mui/material/colors';

const getTheme = (darkMode) => {
  return createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#1484ac',
      },
      secondary: {
        main: '#ffffff',
      },
      accent: {
        main: '#24888c',
      },
      download: {
        main: green[800],
        light: grey[400],
        dark: grey[800],
      },
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    typography: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 16,
      fontWeight: 'normal',
    },
  });
};

export default getTheme;
