import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TextField,
    MenuItem,
    Chip,
    IconButton,
    Box,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { toggleSuggestionVote } from './../../api/feedbackAPI';
import { ArrowUpward } from '@mui/icons-material';
import SuggestionDialog from './SuggestionDialog';

const statuses = ['New', 'Planned', 'In Progress', 'Completed'];

function Suggestions({ suggestions, onVote, lastSuggestionRef, hasMore }) {
    const [filter, setFilter] = useState({ status: '', searchText: '' });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);

    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleStatusFilterChange = (event) => {
        const value = event.target.value;
        setFilter({ ...filter, status: value === 'All' ? '' : value });
    };

    const handleSearchTextChange = (event) => {
        setFilter({ ...filter, searchText: event.target.value });
    };

    const filteredSuggestions = suggestions.filter((suggestion) => {
        const matchesStatus = !filter.status || suggestion.status === filter.status;
        const matchesSearchText =
            !filter.searchText ||
            suggestion.title.toLowerCase().includes(filter.searchText.toLowerCase()) ||
            suggestion.description.toLowerCase().includes(filter.searchText.toLowerCase());
        return matchesStatus && matchesSearchText;
    });

    const handleVoteClick = (event, suggestionId) => {
        event.preventDefault();
        toggleSuggestionVote(suggestionId);
        onVote(suggestionId);
    };

    const handleSuggestionClick = (suggestionId) => {
        setSelectedSuggestion(suggestions.find((suggestion) => suggestion.id === suggestionId));
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setSelectedSuggestion(null);
    };

    const handleChipColor = (status) => {
        switch (status) {
            case 'New':
            case 'Feature Request':
                return 'primary';
            case 'Planned':
                return 'warning';
            case 'Bug Report':
                return 'error';
            case 'In Progress':
                return 'info';
            case 'Completed':
                return 'success';
            default:
                return 'default';
        }
    };

    const formatDate = (date) => {
        const now = new Date();
        const diff = now - new Date(date);

        const minute = 60 * 1000;
        const hour = 60 * minute;
        const day = 24 * hour;
        const year = 365 * day;

        if (diff < minute) {
            return 'a few seconds ago';
        } else if (diff < hour) {
            const minutes = Math.floor(diff / minute);
            return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
        } else if (diff < day) {
            const hours = Math.floor(diff / hour);
            return `${hours} hour${hours === 1 ? '' : 's'} ago`;
        } else if (diff < year) {
            const days = Math.floor(diff / day);
            return `${days} day${days === 1 ? '' : 's'} ago`;
        } else {
            const years = Math.floor(diff / year);
            return `${years} year${years === 1 ? '' : 's'} ago`;
        }
    };

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 2 }}>
                <TextField
                    label="Status"
                    variant="outlined"
                    size="small"
                    select
                    value={filter.status || 'All'}  // add a default value of "All"
                    onChange={handleStatusFilterChange}
                >
                    {['All', ...statuses].map((status) => ( // add an empty string as the first option
                        <MenuItem key={status} value={status}>
                            {status || 'All'}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    value={filter.searchText}
                    onChange={handleSearchTextChange}
                />
            </Box>
            <TableContainer>
                <Table sx={{ minWidth: 450 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 150 }}>Votes</TableCell>
                            <TableCell style={{ width: 500 }}>Title</TableCell>
                            {!isMdScreen && (
                                <>
                                    <TableCell align="center" style={{ width: 300 }}>Submitted</TableCell>
                                    <TableCell align="center" style={{ width: 250 }}>Type</TableCell>
                                    <TableCell align="right" style={{ width: 500 }}>Status</TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredSuggestions.map((suggestion) => (
                            <TableRow key={suggestion.id} ref={lastSuggestionRef}>
                                <TableCell>
                                    <IconButton
                                        onClick={(event) => handleVoteClick(event, suggestion.id)}
                                        color={suggestion.isVoted ? 'primary' : 'default'}
                                        disabled={(suggestion.status === 'Completed')}
                                    >
                                        <ArrowUpward />
                                    </IconButton>
                                    <Typography variant="body2" component="span">
                                        {suggestion.votes}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <button onClick={() => handleSuggestionClick(suggestion.id)}>{suggestion.title}</button>
                                    {isMdScreen && (
                                        <>
                                            <br />
                                            <Chip sx={{ marginRight: '5px' }} label={suggestion.type} color={handleChipColor(suggestion.type)} />
                                            <Chip sx={{ marginRight: '5px' }} label={suggestion.status} color={handleChipColor(suggestion.status)} />
                                            <Chip label={`Version ${suggestion.version}`} />
                                        </>
                                    )}
                                </TableCell>
                                {!isMdScreen &&
                                    (
                                        <>
                                            <TableCell align="center">
                                                <Typography variant="body2" component="span">
                                                    {formatDate(suggestion.submitted)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Chip label={suggestion.type} color={handleChipColor(suggestion.type)} />
                                            </TableCell>
                                            <TableCell align="right">
                                                {suggestion.version && (
                                                    <Chip sx={{ marginRight: '5px' }} label={`Version ${suggestion.version}`} />
                                                )}
                                                <Chip label={suggestion.status} color={handleChipColor(suggestion.status)} />
                                            </TableCell>
                                        </>
                                    )
                                }
                            </TableRow>
                        ))}
                        {!hasMore && (
                            <TableRow>
                                <TableCell colSpan={isMdScreen ? 3 : 5}>
                                    <Typography variant="body2" component="span">
                                        No more results
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <SuggestionDialog open={isDialogOpen} onClose={handleCloseDialog} suggestion={selectedSuggestion} />
        </>
    );
}

export default Suggestions;
