import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { UserProvider } from './context/UserContext';
import { LandingNavigationProvider } from './components/LandingPage/LandingNavigationContext';
import { ToastProvider } from './components/Toast';
import App from './App';
import reportWebVitals from './utilities/reportWebVitals';

function AppWrapper() {
  const [isInLandingNavigation, setIsInLandingNavigation] = useState(false);

  return (
    <UserProvider>
      <ToastProvider isLandingNavigation={isInLandingNavigation}>
        <LandingNavigationProvider value={{ isInLandingNavigation, setIsInLandingNavigation }}>
          <App />
        </LandingNavigationProvider>
      </ToastProvider>
    </UserProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
