import React, { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const Counter = ({ layoutItem, isEditMode, text, setText }) => {
  const [count, setCount] = useState(0);
  const [counterType, setCounterType] = useState('Expenses');

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleCounterTypeChange = (event) => {
    setCounterType(event.target.value);
  };

  useEffect(() => {
    // Set the final count value
    const finalCount = 1000;
  
    // Simulate counting up to the final count value
    let startCount = 0;
    const minIntervalDuration = 1;
    const maxIntervalDuration = 100;
    const acceleration = 25; // Increase this value for a more abrupt speed up
    const totalTime = 100; // Total time for the animation in milliseconds
  
    const updateCounter = () => {
      if (startCount < finalCount) {
        setCount(++startCount);
        const progress = startCount / finalCount;
        const timeProgress = progress * totalTime;
        const intervalDuration = maxIntervalDuration * Math.exp(-acceleration * timeProgress / totalTime) + minIntervalDuration;
        setTimeout(updateCounter, intervalDuration);
      }
    };
  
    updateCounter();
  
    // Cleanup function
    return () => {};
  }, [setCount]);

  return (
    <>
      {isEditMode ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <TextField label="Title" value={text} onChange={handleTextChange} />
          <FormControl variant="outlined" style={{ marginTop: 10 }}>
            <InputLabel id="counter-type-label">Counter Type</InputLabel>
            <Select
              labelId="counter-type-label"
              value={counterType}
              onChange={handleCounterTypeChange}
              label="Counter Type"
            >
              <MenuItem value="Expenses">Expenses</MenuItem>
              <MenuItem value="Total Materials">Total Materials</MenuItem>
              <MenuItem value="Total Inventory">Total Inventory</MenuItem>
              <MenuItem value="Total Orders">Total Orders</MenuItem>
            </Select>
          </FormControl>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: ((layoutItem.h * 150) + ((layoutItem.h - 1) * 10)) - 50,
          }}
        >
          <h2>{text}</h2>
          <h1>{count}</h1>
        </div>
      )}
    </>
  );
};

export default Counter;
