import { createContext, useContext, useState } from 'react';

const LandingNavigationContext = createContext({});

export const useLandingNavigationContext = () => {
    return useContext(LandingNavigationContext);
};

export const LandingNavigationProvider = (props) => {
    const [isInLandingNavigation, setIsInLandingNavigation] = useState(false);

    return (
        <LandingNavigationContext.Provider
            value={{ isInLandingNavigation, setIsInLandingNavigation }}
            {...props}
        >
        </LandingNavigationContext.Provider>
    );
};

export default LandingNavigationContext;
