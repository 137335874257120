import React from 'react';
import { useNavigate } from 'react-router-dom';
import LandingNavigation from '../LandingNavigation';
import Banner from '../Banner';
import Features from '../Features';
import Footer from '../Footer';

const TaxModule = ({ sectionRefs }) => {
    const navigate = useNavigate();
    
    const handleLinkClick = (e, path) => {
      e.preventDefault();
      navigate(path);
    };
    const BannerContent = {
        title: 'Tax Module',
        body: 'Streamline your tax compliance and reporting with our TaxModule.',
        additional: (
          <div className="flex flex-row items-center space-x-6">
            <a href="/download" onClick={(e) => handleLinkClick(e, '/download')} className="btn btn-primary bg-secondary text-white px-6 py-2 rounded hover:bg-tertiary">Download for Windows</a>
            <a href="/login" onClick={(e) => handleLinkClick(e, '/login')} className="btn btn-primary bg-secondary text-white px-6 py-2 rounded hover:bg-tertiary">Open in your browser</a>
          </div>
        )
    };

    const featuresList = [
        {
            title: 'Automated Tax Calculations',
            body:
                'Save time and reduce errors with our automated tax calculation feature. The TaxModule automatically calculates sales tax, VAT, or other applicable taxes for your transactions, ensuring compliance and accuracy.',
            screenshot: 'TaxCalculations.png',
            layout: 'left',
        },
        {
            title: 'Tax Rate Management',
            body:
                'Effortlessly manage tax rates for different regions and tax jurisdictions. Easily add, edit, or delete tax rates, ensuring that your business stays up-to-date with changing tax regulations.',
            screenshot: 'TaxRateManagement.png',
            layout: 'right',
        },
        {
            title: 'Tax Exemption Support',
            body:
                'Handle tax-exempt transactions with ease. Our TaxModule supports tax exemptions for eligible customers, products, or services, making it simple to apply the correct tax treatment for every transaction.',
            screenshot: 'TaxExemption.png',
            layout: 'left',
        },
        {
            title: 'Tax Reports and Filings',
            body:
                'Generate accurate and compliant tax reports for your business with just a few clicks. Easily prepare and file your tax returns with the help of our streamlined reporting and filing tools.',
            screenshot: 'TaxReports.png',
            layout: 'right',
        },
        {
            title: 'Multi-Currency and International Tax Support',
            body:
                'Expand your business globally with our multi-currency and international tax support features. Manage transactions in multiple currencies and automatically calculate taxes for international sales.',
            screenshot: 'MultiCurrency.png',
            layout: 'left',
        },
    ];

    return (
        <div className="min-h-screen bg-primary text-primary flex flex-col">
            <LandingNavigation sectionRefs={sectionRefs} />

            <main className="flex-grow overflow-y-auto mt-20">
                <Banner Title={BannerContent.title} Body={BannerContent.body} />

                <Features featuresList={featuresList} />

                <Footer />

            </main>
        </div>
    );
};

export default TaxModule;

