import customFetch from './../utilities/customFetch';
//import { logEvent } from './../utilities/analytics';

export const fetchMaterials = async (page = 0, pageSize = 25, filter = []) => {
    try {
        const filters = {
            items: filter,
            logicOperator: 'AND',
        }

        const requestData = {
            page: page,
            pageSize: pageSize,
            filter: filters
        };

        const response = await customFetch(`${process.env.REACT_APP_API_URL}materials/get`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Error fetching materials:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error("Error fetching materials:", error);
        return false;
    }
};

export const materialAdjustment = async (materialId, adjustment) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}materials/${materialId}/adjustment`;

        const requestData = {
            adjustment: adjustment,
        };

        const response = await customFetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error('Failed to update material quantity:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error updating material quantity:', error);
        return false;
    }
};