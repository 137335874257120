import React, { useEffect, useState } from 'react';
import { logEvent } from './../utilities/analytics';
import {
    Typography,
    Container,
    Grid,
    //Paper,
    Button,
    Box,
    Fab,
    Badge,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    //Chip,
    Card,
    CardContent,
    Popover,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';
import Cart from './Cart';

function Market() {
    const [cartItems, setCartItems] = useState([]);
    const [cartOpen, setCartOpen] = useState(false);
    const [products, setProducts] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'product-popover' : undefined;

    const selectedProduct = products?.[anchorEl?.dataset?.group]?.find((product) => product.selected);

    useEffect(() => {
        logEvent('navigate_market', {
            page_name: 'Market',
            description: 'User navigated to the market page',
        });

        fetchGroupedProducts();
    }, []);

    const fetchGroupedProducts = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}stripe/products`);
        const fetchedProducts = await response.json();

        const intervalToMonths = (interval, count) => {
            switch (interval) {
                case 'year':
                    return count * 12;
                case 'month':
                default:
                    return count;
            }
        };

        const groupedProducts = fetchedProducts.reduce((groups, product) => {
            const groupKey = product.metadata.group || product.id;
            if (!groups[groupKey]) {
                groups[groupKey] = [];
            }
            const prices = product.prices
                .map((price) => {
                    const { interval, interval_count } = price.recurring;
                    const term = `${interval_count} ${interval}${interval_count > 1 ? 's' : ''}`;
                    const months = intervalToMonths(interval, interval_count);
                    return { ...price, term, months };
                })
                .sort((a, b) => a.months - b.months);

            const sortValue = product.metadata.sort ? parseInt(product.metadata.sort) : Infinity;
            const isSelected = (groups[groupKey].length === 0) || (sortValue === 0);

            groups[groupKey].push({ ...product, prices, sortValue, selected: isSelected });
            return groups;
        }, {});

        // Sort products within each group
        Object.keys(groupedProducts).forEach((groupKey) => {
            groupedProducts[groupKey].sort((a, b) => a.sortValue - b.sortValue);
        });

        setProducts(groupedProducts);
    };

    const handleProductSelection = (groupKey, selectedProductId) => {
        setProducts((prevProducts) => {
            const newGroup = prevProducts[groupKey].map((product) =>
                product.id === selectedProductId
                    ? { ...product, selected: true }
                    : { ...product, selected: false }
            );
            return { ...prevProducts, [groupKey]: newGroup };
        });
    };

    const handleTermSelection = (groupKey, product, selectedTermId) => {
        setProducts((prevProducts) => {
            const newGroup = prevProducts[groupKey].map((prod) =>
                prod.id === product.id ? { ...prod, selectedTerm: selectedTermId } : prod
            );
            return { ...prevProducts, [groupKey]: newGroup };
        });
    };

    const handleAddToCart = (item, selectedTermId) => {
        const selectedTerm = item.prices.find((price) => price.id === selectedTermId);

        const newItem = {
            ...item,
            selectedTerm,
        };

        setCartItems((prevItems) => [...prevItems, newItem]);
        handleToggleCart();
    };

    const handleRemoveFromCart = (product) => {
        setCartItems((prevItems) => prevItems.filter((item) => item !== product));
    };

    const handleToggleCart = () => {
        setCartOpen(!cartOpen);
    };

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item md={12}>
                    <Typography variant="h4" className="text-white mb-4" align="left">
                        Market
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {Object.entries(products).map(([groupKey, group], groupIndex) => {
                            const isSingleProduct = !group.find((product) => product.metadata.group);
                            const selectedProduct = group.find((product) => product.selected);
                            return (
                                <Grid item xs={12} sm={6} md={4} key={groupIndex}>
                                    <Card
                                        onMouseEnter={(e) => {
                                            e.currentTarget.querySelector('.info-icon').style.display = 'block';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.querySelector('.info-icon').style.display = 'none';
                                        }}
                                    >
                                        <Box position="relative">
                                            <CardContent>
                                                {!isSingleProduct && (
                                                    <Box mb={2}>
                                                        <Typography variant="h6">{groupKey}</Typography>
                                                        <Typography variant="body1" gutterBottom>
                                                            {group.find((product) => product.selected)?.description}
                                                        </Typography>
                                                        <FormControl fullWidth variant="standard">
                                                            <InputLabel id={`product-label-${groupIndex}`}>Product</InputLabel>
                                                            <Select
                                                                labelId={`product-label-${groupIndex}`}
                                                                id={`product-select-${groupIndex}`}
                                                                value={group.find((product) => product.selected)?.id}
                                                                onChange={(e) =>
                                                                    handleProductSelection(groupKey, e.target.value)
                                                                }
                                                            >
                                                                {group.map((product, index) => (
                                                                    <MenuItem key={index} value={product.id}>
                                                                        {product.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        {group.find((product) => product.selected)?.prices && (
                                                            <>
                                                                <FormControl fullWidth variant="standard">
                                                                    <InputLabel id={`term-label-${groupIndex}`}>Term</InputLabel>
                                                                    <Select
                                                                        labelId={`term-label-${groupIndex}`}
                                                                        id={`term-select-${groupIndex}`}
                                                                        value={
                                                                            group.find((product) => product.selected)?.selectedTerm ||
                                                                            group.find((product) => product.selected)?.prices[0].id
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleTermSelection(
                                                                                groupKey,
                                                                                group.find((product) => product.selected),
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    >
                                                                        {group
                                                                            .find((product) => product.selected)
                                                                            .prices.map((price, idx) => (
                                                                                <MenuItem key={idx} value={price.id}>
                                                                                    {price.term} - ${(
                                                                                        price.unit_amount / 100
                                                                                    ).toFixed(2)}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </>
                                                        )}
                                                        <Box mt={2}>
                                                            <Button
                                                                onClick={() =>
                                                                    handleAddToCart(
                                                                        group.find((product) => product.selected),
                                                                        group.find((product) => product.selected)?.selectedTerm
                                                                    )
                                                                }
                                                            >
                                                                Subscribe
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                )}
                                                {isSingleProduct && (
                                                    <Box mb={2}>
                                                        <Typography variant="h6">{selectedProduct.name}</Typography>
                                                        <Typography variant="body1" gutterBottom>
                                                            {selectedProduct.description}
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {`$${(
                                                                selectedProduct.prices[0].unit_amount / 100
                                                            ).toFixed(2)} / ${selectedProduct.prices[0].recurring.interval
                                                                }`}
                                                        </Typography>
                                                        <Box mt={2}>
                                                            <Button onClick={() => handleAddToCart(selectedProduct, selectedProduct.selectedTerm)}>
                                                                Subscribe
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </CardContent>
                                            <Box
                                                className="info-icon"
                                                sx={{
                                                    display: 'none',
                                                    position: 'absolute',
                                                    top: 8,
                                                    right: 8,
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <InfoIcon
                                                    color="primary"
                                                    aria-describedby={id}
                                                    data-group={groupKey}
                                                    onClick={handlePopoverOpen}
                                                />
                                            </Box>
                                        </Box>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ position: 'fixed', bottom: 16, right: 16 }}>
                <Badge badgeContent={cartItems.length} color="error">
                    <Fab color="primary" onClick={handleToggleCart}>
                        <ShoppingCartIcon />
                    </Fab>
                </Badge>
            </Box>
            <Cart
                cartItems={cartItems}
                setCartItems={setCartItems}
                open={cartOpen}
                handleRemoveFromCart={handleRemoveFromCart}
                handleCloseCart={handleToggleCart}
            />
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {selectedProduct && (
                    <Box p={2} maxWidth={400}>
                        <Typography variant="h6">{selectedProduct.name}</Typography>
                        <Typography variant="body2" gutterBottom>
                            {selectedProduct.description}
                        </Typography>
                        {Array.isArray(selectedProduct.metadata.features) && (
                            <ul>
                                {selectedProduct.metadata.features.map((feature, index) => (
                                    <li key={index}>{feature.trim()}</li>
                                ))}
                            </ul>
                        )}
                    </Box>
                )}
            </Popover>
        </Container>
    );
}

export default Market;