import React, { forwardRef, useState, useEffect, useRef } from 'react';

const getImageUrl = async (filename) => {
    const module = await import(`../../assets/marketing/${filename}`);
    return module.default;
};
const Feature = ({ title, body, screenshot, layout }) => {
    const layouts = {
        left: 'lg:flex-row',
        right: 'lg:flex-row-reverse',
        below: 'flex-col',
    };
    const [imageUrl, setImageUrl] = useState(null);
    const featureContentRef = useRef(null);

    useEffect(() => {
        const loadImage = async () => {
            const url = await getImageUrl(screenshot);
            setImageUrl(url);
        };
        loadImage();
    }, [screenshot]);

    // const onIntersection = (entries, observer, reverseObserver) => {
    //     entries.forEach(entry => {
    //         const viewportHeight = window.innerHeight;
    //         const targetTop = entry.boundingClientRect.top;
    //         const targetHeight = entry.boundingClientRect.height;
    //         const triggerPoint = targetTop + (targetHeight / 3);
    //         const animationStart = Math.max((viewportHeight - triggerPoint) / (viewportHeight - targetHeight), 0);
    //         const animationEnd = Math.min(viewportHeight - targetTop, viewportHeight) / targetHeight;
    //         const animationProgress = Math.min(Math.max((entry.intersectionRatio - animationStart) / (animationEnd - animationStart), 0), 1);

    //         if (entry.isIntersecting && animationProgress > 0 && targetTop > 0) {
    //             // apply animation styles
    //             entry.target.style.opacity = animationProgress;
    //             entry.target.style.animationName = `fadeIn${layout.charAt(0).toUpperCase()}${layout.slice(1)}`;
    //             entry.target.style.animationDuration = '1s';
    //             if (layout === 'left') {
    //                 entry.target.style.transform = `translateX(-${20 - (20 * animationProgress)}px)`;
    //             } else if (layout === 'right') {
    //                 entry.target.style.transform = `translateX(${20 - (20 * animationProgress)}px)`;
    //             } else {
    //                 entry.target.style.transform = `translateY(${20 - (20 * animationProgress)}px)`;
    //             }

    //             // observe the target element with the reverseObserver when it is leaving the viewport at the bottom
    //             if (entry.target.dataset.observed === 'false') {
    //                 entry.target.dataset.observed = 'true';
    //                 observer.unobserve(entry.target);
    //                 reverseObserver.observe(entry.target);
    //             }
    //         } else if (targetTop > 0) {
    //             // remove animation styles
    //             entry.target.style.opacity = 0;
    //             entry.target.style.transform = '';
    //             entry.target.style.animationName = '';
    //             entry.target.style.animationDuration = '';

    //             // stop observing the target element with the reverseObserver when it is back in the viewport
    //             if (entry.target.dataset.observed === 'true') {
    //                 entry.target.dataset.observed = 'false';
    //                 reverseObserver.unobserve(entry.target);
    //                 observer.observe(entry.target);
    //             }
    //         } else {
    //             // make the target element visible when it is at the top of the viewport
    //             entry.target.style.opacity = 1;
    //         }
    //     });
    // };

    // useEffect(() => {
    //     const observer = new IntersectionObserver(onIntersection, { threshold: 0.2 });

    //     if (featureContentRef.current) {
    //         observer.observe(featureContentRef.current);
    //     }

    //     return () => {
    //         if (featureContentRef.current) {
    //             observer.unobserve(featureContentRef.current);
    //         }
    //     };
    // }, []);

    return (
        <div ref={featureContentRef} className={`feature-card p-6 lg:p-12 bg-accent rounded shadow text-primary flex flex-col ${layouts[layout]} space-x-0 ${layout === 'below' ? '' : 'lg:space-x-12'} items-center lg:items-start`}>
            <div className={`w-full ${layout === 'left' || layout === 'right' ? 'lg:w-2/5' : 'lg:w-3/4'} px-4 ${layout === 'below' ? 'text-center lg:w-3/4 mx-auto' : ''}`}>
                <h2 className="text-2xl lg:text-3xl font-bold text-primary mb-2">{title}</h2>
                <p className="mt-4 text-sub">{body}</p>
            </div>
            <div className={`w-full ${layout === 'left' || layout === 'right' ? 'lg:w-3/5' : ''} ${layout === 'below' ? 'mt-6' : 'mt-6 lg:mt-0'} ${layout === 'below' ? 'text-center' : ''}`}>
                <img src={imageUrl} alt={`${title} Screenshot`} className={`rounded-md h-auto mx-auto ${layout === 'below' ? 'w-full lg:px-0' : 'w-full'} ${layout === 'left' ? 'shadow-bottom-right' : layout === 'right' ? 'shadow-bottom-left' : 'shadow-bottom'}`} />
            </div>
        </div>
    );
};

const Features = forwardRef(({ featuresList }, ref) => {
    return (
        <section ref={ref} className="lg:mt-16 mt-2 lg:space-y-16 space-y-4 bg-primary p-6 px-12">
            {featuresList.map((feature, index) => (
                <Feature key={index} {...feature} />
            ))}
        </section>
    );
});

export default Features;
