import React, { useEffect, useState, useCallback, useRef } from 'react';
//import { logEvent } from './../utilities/analytics';
import {
  Typography,
  Container,
  Grid,
  Paper,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { fetchSuggestions } from './../api/feedbackAPI';
import SpeedDial from './../components/SpeedDial';
import Suggestions from './../components/feedbackHub/Suggestions';
import SuggestionForm from './../components/feedbackHub/SuggestionForm';

function FeedbackHub() {
  const [openDialog, setOpenDialog] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(25);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  const handleVote = (suggestionId) => {
    const updatedSuggestions = suggestions.map((suggestion) => {
      if (suggestion.id === suggestionId) {
        if (suggestion.isVoted) {
          return { ...suggestion, votes: suggestion.votes - 1, isVoted: false };
        } else {
          return { ...suggestion, votes: suggestion.votes + 1, isVoted: true };
        }
      }
      return suggestion;
    });
    setSuggestions(updatedSuggestions);
  };

  const lastSuggestionRef = useCallback((node) => {
    if (isLoading || !hasMore) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  const loadMoreSuggestions = useCallback(async () => {
    setIsLoading(true);
    const fetchedSuggestions = await fetchSuggestions(currentPage, pageSize);
    if (fetchedSuggestions) {
      setSuggestions((prevSuggestions) => {
        const updatedSuggestions = [...prevSuggestions];
        fetchedSuggestions.suggestions.forEach((newSuggestion) => {
          const existingIndex = updatedSuggestions.findIndex(
            (prevSuggestion) => prevSuggestion.id === newSuggestion.id
          );

          if (existingIndex !== -1) {
            // Update the existing entry
            updatedSuggestions[existingIndex] = newSuggestion;
          } else {
            // Add a new entry
            updatedSuggestions.push(newSuggestion);
          }
        });
        setHasMore(fetchedSuggestions.totalCount > updatedSuggestions.length);
        return updatedSuggestions;
      });
    }
    setIsLoading(false);
  }, [currentPage, pageSize]);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const fetchedSuggestions = await fetchSuggestions(0, pageSize);
      if (fetchedSuggestions) {
        setSuggestions(fetchedSuggestions.suggestions);
        setHasMore(fetchedSuggestions.totalCount > fetchedSuggestions.suggestions.length);
      }
    };

    fetchData();
    setIsLoading(false);
  }, [pageSize]);

  useEffect(() => {
    if (currentPage > 0) {
      loadMoreSuggestions();
    }
  }, [currentPage, loadMoreSuggestions]);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Typography variant="h4" className="text-white mb-4" align="left">
            Feedback
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: 16 }}>
            <Suggestions
              suggestions={suggestions}
              onVote={handleVote}
              lastSuggestionRef={lastSuggestionRef}
              hasMore={hasMore} />
          </Paper>
        </Grid>
      </Grid>
      <SuggestionForm
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        suggestions={suggestions}
        setSuggestions={setSuggestions} />
      <SpeedDial
        onSpeedDialClick={() => setOpenDialog(true)}
        icon={<AddIcon />}
      />
    </Container>
  );
}

export default FeedbackHub;