import React, { useState, useEffect, useCallback, useRef } from 'react';
import { fetchProducts } from './../api/productAPI';
import { isElectron } from './../utilities/ElectronUtils'
import useScreenWidth from './../hooks/useScreenWidth';
import { logEvent } from './../utilities/analytics';
//import { UserContext } from './../context/UserContext';
import ProductCard from './../components/products/ProductCard';
import ProductDetails from './../components/products/ProductDetails';
import ProductForm from './../components/products/ProductForm';
import SpeedDial from './../components/SpeedDial';
import {
    Typography,
    Container,
    Grid,
    Card,
    Skeleton,
    useTheme,
    useMediaQuery
} from '@mui/material';
import {
    Add,
} from '@mui/icons-material';

function Products() {
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const screenWidth = useScreenWidth();

    const loader = useRef(null);

    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

    //const { userData } = useContext(UserContext);

    const [items, setItems] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize] = useState(25);
    const [expandedCard, setExpandedCard] = useState(null);
    const [showProductForm, setShowProductForm] = useState(false);

    const calculateNumSkeletons = () => {
        const numItemsPerRow = isMdUp ? 6 : isSmUp ? 4 : 2;
        const numLoadedItems = items.length % numItemsPerRow;
        const numEmptySpots = numItemsPerRow - numLoadedItems;

        return numEmptySpots + numItemsPerRow;
    };

    useEffect(() => {
        logEvent('navigate_products', {
            page_name: 'Products',
            description: 'User navigated to the products page',
        });
    }, []);

    const showAddProductDialog = () => {
        setShowProductForm(!showProductForm);
    };

    const fetchItems = useCallback(async (page = 0, pageSize = 25, resetItems = false) => {
        setIsLoading(true);
        try {
            let fetchedInventory = [];
            let totalCount = 0;
    
            const inventoryData = await fetchProducts(page, pageSize);
    
            totalCount = inventoryData.totalCount;
            fetchedInventory = fetchedInventory.concat(inventoryData.items);
    
            setHasMore((prevHasMore) => (resetItems ? 0 : prevHasMore) + fetchedInventory.length < totalCount);
    
            if (resetItems) {
                setItems(fetchedInventory);
            } else {
                setItems((prevItems) => {
                    const updatedItems = [...prevItems];
                    fetchedInventory.forEach((newItem) => {
                        const index = updatedItems.findIndex((item) => item.id === newItem.id);
                        if (index !== -1) {
                            // Update the existing item
                            updatedItems[index] = newItem;
                        } else {
                            // Add the new item to the list
                            updatedItems.push(newItem);
                        }
                    });
                    return updatedItems;
                });
            }
    
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);
    
    useEffect(() => {
        if (isLoading) return;
        const observer = new IntersectionObserver(
            (entries) => {
                // Only increase the page number if hasMore is true
                if (entries[0].isIntersecting && hasMore) {
                    setCurrentPage((prevPage) => prevPage + 1);
                }
            },
            {
                threshold: 1,
            }
        );

        const currentLoader = loader.current;

        if (currentLoader) {
            observer.observe(currentLoader);
        }

        return () => {
            if (currentLoader) {
                observer.unobserve(currentLoader);
            }
        };
    }, [loader, hasMore, isLoading]);

    useEffect(() => {
        fetchItems(currentPage, pageSize);
    }, [fetchItems, currentPage, pageSize]);

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h4" className="text-white mb-4" align="left">
                        Products
                    </Typography>
                </Grid>
                <Grid item xs={12} pb={5}>
                    <Grid container spacing={3}>
                        {Array.isArray(items) && items.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card
                                    className={`${(expandedCard === item.id) ? 'card card-expanded' : 'card'}  ${isElectron ? 'electron-app' : ''} ${(screenWidth < 600) ? 'mobile' : ''} `}
                                >
                                    {expandedCard === item.id ? (
                                        <ProductDetails
                                            item={item}
                                            onClose={() => setExpandedCard(null)}
                                            setItems={setItems}
                                        />
                                    ) : (
                                        <ProductCard
                                            item={item}
                                            setItems={setItems}
                                            setExpandedCard={setExpandedCard}
                                        />
                                    )}
                                </Card>
                            </Grid>
                        ))}
                        {(isLoading) &&
                            Array.from({ length: calculateNumSkeletons() }).map((_, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Skeleton variant="rectangular" height={115} />
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
                <Grid item xs={12} pb={10}>
                    <Grid container spacing={3}>
                        <div ref={loader} style={{ height: '1px' }}></div>
                        {!hasMore && <Typography variant="body2" align="center" sx={{ width: '100%' }}>No more results</Typography>}
                    </Grid>
                </Grid>
            </Grid>
            {expandedCard === null && !showProductForm && (
                <SpeedDial
                    onSpeedDialClick={showAddProductDialog}
                    icon={<Add />}
                />
            )}
            {showProductForm && (
                <Card
                    className={`card card-expanded ${isElectron ? 'electron-app' : ''} ${(screenWidth < 600) ? 'mobile' : ''} `}
                >
                    <ProductForm
                        isNew={true}
                        onClose={(deleted = false) => {
                            setShowProductForm(false);
                            if(deleted){
                                setExpandedCard(null);
                            }
                        }}
                        setItems={setItems}
                    />
                </Card>
            )}
        </Container>
    );
}

export default Products;