import React, { useEffect, useState } from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import { logEvent } from './../utilities/analytics';
import { Container, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TitleIcon from '@mui/icons-material/Title';
import BarChartIcon from '@mui/icons-material/BarChart';
import CounterIcon from '@mui/icons-material/PlusOne';
import SpeedDial from './../components/SpeedDial';
import ComponentWrapper from './../components/dashboardComponents/ComponentWrapper';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const ResponsiveGridLayout = WidthProvider(Responsive);

const initialWidgets = {
  layouts: {
    lg: [
      { i: '0', x: 0, y: 0, w: 8, h: 2 },
      { i: '1', x: 8, y: 0, w: 4, h: 1 },
      { i: '2', x: 0, y: 6, w: 8, h: 1 },
      { i: '3', x: 8, y: 6, w: 4, h: 1 },
    ],
    sm: [
      { i: '0', x: 0, y: 0, w: 1, h: 2 },
      { i: '1', x: 0, y: 0, w: 1, h: 1 },
      { i: '2', x: 0, y: 0, w: 1, h: 1 },
      { i: '3', x: 0, y: 0, w: 1, h: 1 },
    ],
  },
  components: [
    {
      type: 'chart',
      text: 'Sample Chart',
    },
    {
      type: 'header',
      text: 'Sample Header',
    },
    {
      type: 'text',
      text: 'Sample Text',
    },
    {
      type: 'text',
      text: 'Sample Text',
    },
  ]
};

const speedDialActions = [
  { type: 'chart', name: 'Add Chart', icon: <BarChartIcon /> },
  { type: 'counter', name: 'Add Counter', icon: <CounterIcon /> },
  { type: 'text', name: 'Add Text', icon: <TextFieldsIcon /> },
  { type: 'header', name: 'Add Header', icon: <TitleIcon /> },
];

function DashboardContent() {
  const [editModes, setEditModes] = useState(initialWidgets.components.map(() => false));
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");

  const [dashboardWidgets, setDashboardWidgets] = useState(initialWidgets);

  useEffect(() => {
    setDashboardWidgets((prevDashboardWidgets) => {
      const newLayouts = { ...prevDashboardWidgets.layouts };
      newLayouts.lg = newLayouts.lg.map((item, index) => {
        return {
          ...item,
          isResizable: editModes[index],
        };
      });
      return {
        ...prevDashboardWidgets,
        layouts: newLayouts,
      };
    });
  }, [editModes]);

  useEffect(() => {
    logEvent('navigate_dashboard', {
      page_name: 'Dashboard',
      description: 'User navigated to the dashboard page',
    });
  }, []);

  const setCurrentLayout = (layout, layouts) => {
    setDashboardWidgets((prevDashboardWidgets) => {
      return {
        ...prevDashboardWidgets,
        layouts: {
          ...prevDashboardWidgets.layouts,
          lg: layouts.lg,
          sm: layouts.sm,
        },
      };
    });
  };

  const findAvailablePosition = (layout, newItemWidth, newItemHeight) => {
    const sortedLayout = layout.slice().sort((a, b) => {
      if (a.y === b.y) {
        return a.x - b.x;
      }
      return a.y - b.y;
    });
  
    for (let y = 0; y <= 12 - newItemHeight; y++) {
      for (let x = 0; x <= 12 - newItemWidth; x++) {
        let validPosition = true;
  
        for (const item of sortedLayout) {
          if (
            x < item.x + item.w &&
            x + newItemWidth > item.x &&
            y < item.y + item.h &&
            y + newItemHeight > item.y
          ) {
            validPosition = false;
            break;
          }
        }
  
        if (validPosition) {
          return { x, y };
        }
      }
    }
  
    return { x: 0, y: Infinity };
  };
  
  const handleAddComponent = (type) => {
    const newComponent = {
      type: type,
      text: `Sample ${type}`,
    };

    const newItemWidth = type === 'chart' ? 8 : 4;
    const newItemHeight = type === 'chart' ? 2 : 1;
    const position = findAvailablePosition(dashboardWidgets.layouts.lg, newItemWidth, newItemHeight);

    const newLayoutItem = {
      i: String(dashboardWidgets.components.length),
      ...position,
      w: newItemWidth,
      h: newItemHeight,
    };

    setDashboardWidgets((prevDashboardWidgets) => {
      const newComponents = [...prevDashboardWidgets.components, newComponent];
      const newLayouts = {
        lg: [...prevDashboardWidgets.layouts.lg, newLayoutItem],
        sm: [
          ...prevDashboardWidgets.layouts.sm,
          { ...newLayoutItem, w: 1, h: newItemHeight },
        ],
      };

      return {
        components: newComponents,
        layouts: newLayouts,
      };
    });

    // Also, update the editModes array to include the new component
    setEditModes((prevEditModes) => [...prevEditModes, false]);
  };

  return (
    <Container>
      <Typography variant="h4" className="text-white mb-4" align="left">
        Dashboard
      </Typography>
      <ResponsiveGridLayout
        style={{ marginTop: '14px' }}
        className="layout"
        layouts={{ lg: dashboardWidgets.layouts.lg, sm: dashboardWidgets.layouts.sm }}
        breakpoints={{ lg: 747, sm: 0 }}
        cols={{ lg: 12, sm: 1 }}
        compactType="vertical"
        isDraggable={true}
        isResizable={false}
        onLayoutChange={(layout, layouts) => setCurrentLayout(layout, layouts)}
        onBreakpointChange={(breakpoint) => setCurrentBreakpoint(breakpoint)}
      >
        {dashboardWidgets.components.map((component, index) => {
          const layoutItem = dashboardWidgets.layouts[currentBreakpoint].find(
            (item) => item.i === String(index)
          );

          return (
            <div key={index}>
              <ComponentWrapper
                component={component}
                layoutItem={layoutItem}
                editMode={editModes[index]}
                setEditMode={(newEditMode) => {
                  const newEditModes = [...editModes];
                  newEditModes[index] = newEditMode;
                  console.log("NewEditMode:", newEditMode);
                  setEditModes(newEditModes);
                }}
              />
            </div>
          );
        })}
      </ResponsiveGridLayout>
      <SpeedDial
        actions={speedDialActions.map((action) => ({
          ...action,
          onClick: () => handleAddComponent(action.type),
        }))}
        icon={<AddIcon />}
      />
    </Container>
  );
}

export default DashboardContent;