import React, { useState, useRef, useEffect } from 'react';
import { updateOrderStatus, submitOrder } from './../../api/ordersAPI';
import {
    Typography,
    CardActionArea,
    CardContent,
    CardActions,
    Box,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    Button,
    TextField
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const OrderCard = ({ order, tabs, updateTabTotalOrders, setOrders, setSelectedOrder, activeTabIndex }) => {
    const [categorizeDialogOpen, setCategorizeDialogOpen] = useState(false);
    const [shipDialogOpen, setShipDialogOpen] = useState(false);

    //const [carrier, setCarrier] = useState(order.carrier);
    const [weight, setWeight] = useState(order.weight);
    const [length, setLength] = useState(order.length);
    const [width, setWidth] = useState(order.width);
    const [height, setHeight] = useState(order.height);
    const [shipCost, setShipCost] = useState(order.shippingPrice);
    const [trackingNo, setTrackingNo] = useState(order.trackingNo);

    const [selectedCategory, setSelectedCategory] = useState('');

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleCategorizeClick = () => {
        setSelectedCategory(tabs[activeTabIndex].text);
        setCategorizeDialogOpen(true);
    };

    const handleShipClick = () => {
        setShipDialogOpen(true);
    }

    const handleSaveCategory = async () => {
        try {
            const oldStatus = order.status;
            const newStatus = tabs.find((tab) => tab.text === selectedCategory).id;
            await updateOrderStatus(order.id, newStatus);

            if (isMounted.current) {
                setOrders((prevOrders) => {
                    // Remove the order from the current orders
                    const updatedOrders = prevOrders.filter((o) => o.id !== order.id);
                    return updatedOrders;
                });

                // Update tab's totalOrders
                updateTabTotalOrders(oldStatus, newStatus);
            }
        } catch (error) {
            console.error("Error updating order status:", error);
        }

        if (isMounted.current) {
            setCategorizeDialogOpen(false);
        }
    };

    const handleSaveShip = async (order) => {
        try {
            const updatedOrder = {
                ...order,
                status: 2,
                //carrier: carrier,
                weight: weight ? parseFloat(weight) : null,
                length: length ? parseFloat(length) : null,
                width: width ? parseFloat(width) : null,
                height: height ? parseFloat(height) : null,
                trackingNo: trackingNo || null,
                shippingPrice: shipCost ? parseFloat(shipCost) : null,
            };
            await submitOrder(updatedOrder, order.id);

            if (isMounted.current) {
                setOrders((prevOrders) => {
                    // Remove the order from the current orders
                    const updatedOrders = prevOrders.filter((o) => o.id !== order.id);
                    return updatedOrders;
                });

                // Update tab's totalOrders
                updateTabTotalOrders(order.status, updatedOrder.status);
            }
        } catch (error) {
            console.error("Error updating order status:", error);
        }

        if (isMounted.current) {
            setShipDialogOpen(false);
        }
    }

    return (
        <>
            <CardActionArea onClick={() => setSelectedOrder(order)}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {order.customerName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Order #: {order.orderNo}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Order Date: {new Date(order.orderDate).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Total Cost: ${order.totalCost}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {order.status !== 2 && (
                <>
                    <CardActions>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Box>
                                {/* <Tooltip title={`Cancel`}>
                            <IconButton
                                size="small"
                                color="primary"
                                aria-label="cancel"
                                onClick={() => { }}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Tooltip> */}
                                <Tooltip title={`Categorize`}>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        aria-label="categorize"
                                        onClick={() => handleCategorizeClick()}
                                    >
                                        <LocalOfferIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Tooltip title={`Ship`}>
                                <IconButton
                                    size="small"
                                    color="primary"
                                    aria-label="ship"
                                    onClick={() => { handleShipClick() }}
                                >
                                    <LocalShippingIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </CardActions>
                    <Dialog
                        open={categorizeDialogOpen}
                        onClose={() => setCategorizeDialogOpen(false)}
                        aria-labelledby="categorize-dialog-title"
                    >
                        <DialogTitle id="categorize-dialog-title">Categorize Order</DialogTitle>
                        <DialogContent>
                            <Select
                                labelId="category-select-label"
                                id="category-select"
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                fullWidth
                            >
                                {tabs.filter((tab) => tab.text !== 'Completed').map((tab) => (
                                    <MenuItem key={tab.id} value={tab.text}>
                                        {tab.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setCategorizeDialogOpen(false)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleSaveCategory} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={shipDialogOpen}
                        onClose={() => setShipDialogOpen(false)}
                        aria-labelledby="ship-dialog-title"
                    >
                        <DialogTitle id="ship-dialog-title">Ship Order</DialogTitle>
                        <DialogContent>
                            {/* <TextField
                        margin="dense"
                        id="carrier"
                        label="Carrier"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={carrier}
                        onChange={(e) => setCarrier(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="service"
                        label="Service"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={service}
                        onChange={(e) => setServices(e.target.value)}
                    /> */}
                            <TextField
                                margin="dense"
                                id="weight"
                                label="Weight"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <TextField
                                    margin="dense"
                                    id="length"
                                    label="Length"
                                    type="number"
                                    sx={{ width: '30%' }}
                                    variant="outlined"
                                    value={length}
                                    onChange={(e) => setLength(e.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="width"
                                    label="Width"
                                    type="number"
                                    sx={{ width: '30%' }}
                                    variant="outlined"
                                    value={width}
                                    onChange={(e) => setWidth(e.target.value)}
                                />
                                <TextField
                                    margin="dense"
                                    id="height"
                                    label="Height"
                                    type="number"
                                    sx={{ width: '30%' }}
                                    variant="outlined"
                                    value={height}
                                    onChange={(e) => setHeight(e.target.value)}
                                />
                            </Box>
                            <TextField
                                margin="dense"
                                id="ship-cost"
                                label="Shipment Cost"
                                type="number"
                                fullWidth
                                variant="outlined"
                                value={shipCost}
                                onChange={(e) => setShipCost(e.target.value)}
                            />
                            <TextField
                                margin="dense"
                                id="tracking-number"
                                label="Tracking Number"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={trackingNo}
                                onChange={(e) => setTrackingNo(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setShipDialogOpen(false)} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => handleSaveShip(order)} color="primary">
                                Mark Shipped
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}

        </>
    );
};

export default OrderCard;
