import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormDialog(props) {
  const { title, children, onClose, isOpen, actions } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  const defaultActions = <Button onClick={handleClose}>Close</Button>;

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        <DialogActions>{actions || defaultActions}</DialogActions>
      </Dialog>
    </div>
  );
}
