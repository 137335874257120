import React from 'react';
import { useNavigate } from 'react-router-dom';
import LandingNavigation from '../LandingNavigation';
import Banner from '../Banner';
import Features from '../Features';
import Pricing from '../Pricing';
import Footer from '../Footer';

const IntegrationModule = ({ sectionRefs }) => {
    const navigate = useNavigate();

    const handleLinkClick = (e, path) => {
        e.preventDefault();
        navigate(path);
    };

    const BannerContent = {
        title: 'Integration Module',
        body: 'Integrate with your favorite apps to streamline your business processes.',
        additional: (
            <div className="flex flex-row items-center space-x-6">
                <a href="/download" onClick={(e) => handleLinkClick(e, '/download')} className="btn btn-primary bg-secondary text-white px-6 py-2 rounded hover:bg-tertiary">Download for Windows</a>
                <a href="/login" onClick={(e) => handleLinkClick(e, '/login')} className="btn btn-primary bg-secondary text-white px-6 py-2 rounded hover:bg-tertiary">Open in your browser</a>
            </div>
        )
    };

    const featuresList = [
        {
            title: 'Seamless App Integrations',
            body:
                'Effortlessly connect your apps with our extensive list of built-in integrations, including popular platforms like Etsy, Shopify, WooCommerce, and more. Alternatively, utilize our robust API to create custom connections and seamlessly integrate your apps for a streamlined workflow.',
            screenshot: 'Dashboard.png',
            layout: 'left',
        },
        {
            title: 'Flexible Pricing Plans',
            body:
                'Choose from a variety of pricing plans tailored to suit your needs, with options to accommodate businesses of all sizes. No long-term contracts or commitments required, ensuring you have the flexibility to adapt as your business grows and evolves.',
            screenshot: 'Expenses.png',
            layout: 'right',
        },
        {
            title: 'Upgrade or Downgrade with Ease',
            body:
                'Enjoy the freedom to upgrade or downgrade your plan at any time, without the hassle of long-term contracts or commitments. Our flexible plans allow you to scale your integrations and features as your business needs change, providing you with the perfect fit at all times.',
            screenshot: 'Hardware.png',
            layout: 'left',
        },
        {
            title: 'Real-Time Data Sync',
            body:
                'Keep your apps and data in perfect harmony with real-time data syncing capabilities. Ensure consistent and up-to-date information across all your connected platforms, improving data accuracy and reducing the risk of errors or discrepancies.',
            screenshot: 'RealTimeSync.png',
            layout: 'right',
        },
        {
            title: 'User-Friendly Interface',
            body:
                'Navigate and manage your integrations with ease using our intuitive, user-friendly interface. Simplify complex workflows and streamline your app connections, making it easy for users of all skill levels to take full advantage of our powerful integration module.',
            screenshot: 'Interface.png',
            layout: 'left',
        },
    ];

    const pricingPlans = [
        {
            title: 'Startup',
            price: '$9.99 / Month / Integration',
            features: [
                'Sync up to 200 orders per month',
                'Daily automatic order syncing',
                'Real-time inventory level synchronization',
                '3 years of data retention',
                'Enhanced dashboard metrics',
                'Custom expense types and units',
            ],
        },
        {
            title: 'Growth',
            price: '$19.99 / Month / Integration',
            features: [
                'All Startup plan features included',
                'Sync up to 500 orders per month',
                '6 years of data retention',
                'Order synchronization every 3 hours',
                'Role-based user access control',
            ],
        },
        {
            title: 'Launch',
            price: '$29.99 / Month / Integration',
            features: [
                'All Growth plan features included',
                'Sync up to 1,500 orders per month',
                'Unlimited data retention',
                'Order synchronization every hour',
            ],
        },
        {
            title: 'Business',
            price: '$39.99 / Month / Integration',
            features: [
                'All Launch plan features included',
                'Unlimited order syncing',
                'Near real-time order synchronization',
                'API access for custom integrations and automation',
            ],
        },
    ];

    return (
        <div className="min-h-screen bg-primary text-primary flex flex-col">
            <LandingNavigation sectionRefs={sectionRefs} />

            <main className="flex-grow overflow-y-auto mt-20">
                <Banner Title={BannerContent.title} Body={BannerContent.body} AdditionalContent={BannerContent.additional} />

                <Pricing pricingPlans={pricingPlans} />

                <Features featuresList={featuresList} />

                <Footer />

            </main>
        </div>
    );
};

export default IntegrationModule;
