import customFetch from './../utilities/customFetch';
//import { logEvent } from './../utilities/analytics';

export const fetchExpenses = async (page, pageSize, sort, filters) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}expenses/get`;

        const requestData = {
            page: page,
            pageSize: pageSize,
            sort: sort,
            filter: filters
        };
    
        const response = await customFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Failed to fetch expenses:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return false;
    }
    
};

export const deleteExpense = async (id) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}expenses/${id}`;

        const response = await customFetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Failed to delete expense:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error while deleting expense:', error);
        return false;
    }
};

export const submitExpense = async (expenseData, expenseId = null) => {
    try {
        const response = await customFetch(expenseId ? `${process.env.REACT_APP_API_URL}expenses/${expenseId}` : `${process.env.REACT_APP_API_URL}expenses`, {
            method: expenseId ? "PUT" : "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(expenseData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Error creating expense:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error("Error creating expense:", error);
        return false;
    }
};