import React, { useState, useEffect } from 'react';
import { logEvent } from '../../utilities/analytics';
import {
    Grid,
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material';

function GeneralSettings({ userData, isInitialRender, saveSettingsRef }) {
    const [darkMode, setDarkMode] = useState(userData?.settings?.darkMode === '1');
    const [currencySymbol, setCurrencySymbol] = useState(userData?.settings?.currencySymbol ?? '$');
    const [deleteWarnings, setDeleteWarnings] = useState(userData?.settings?.deleteWarnings === '1');
    const [showChangelog, setShowChangelog] = useState(userData?.settings?.showChangelog === '1');

    useEffect(() => {
        if (!isInitialRender.current) {
            logEvent(`change_setting_darkMode`, {
                setting_name: "darkMode",
                new_value: darkMode,
                description: `User changed their "darkMode" setting to ${darkMode}`,
            });
            saveSettingsRef.current("darkMode", darkMode);
        }
    }, [darkMode, isInitialRender, saveSettingsRef]);

    useEffect(() => {
        if (!isInitialRender.current) {
            logEvent(`change_setting_currencySymbol`, {
                setting_name: "currencySymbol",
                new_value: currencySymbol,
                description: `User changed their "currencySymbol" setting to ${currencySymbol}`,
            });
            saveSettingsRef.current("currencySymbol", currencySymbol);
        }
    }, [currencySymbol, isInitialRender, saveSettingsRef]);

    useEffect(() => {
        if (!isInitialRender.current) {
            logEvent(`change_setting_deleteWarnings`, {
                setting_name: "deleteWarnings",
                new_value: deleteWarnings,
                description: `User changed their "deleteWarnings" setting to ${deleteWarnings}`,
            });
            saveSettingsRef.current("deleteWarnings", deleteWarnings);
        }
    }, [deleteWarnings, isInitialRender, saveSettingsRef]);

    useEffect(() => {
        if (!isInitialRender.current) {
            logEvent(`change_setting_showChangelog`, {
                setting_name: "showChangelog",
                new_value: showChangelog,
                description: `User changed their "showChangelog" setting to ${showChangelog}`,
            });
            saveSettingsRef.current("showChangelog", showChangelog);
        }
    }, [showChangelog, isInitialRender, saveSettingsRef]);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <FormControlLabel
                    control={
                        <Switch
                            checked={darkMode}
                            onChange={(e) => setDarkMode(e.target.checked)}
                        />
                    }
                    label="Dark Mode"
                />
            </Grid>
            <Grid item>
                <TextField
                    label="Currency Symbol"
                    value={currencySymbol}
                    onChange={(e) => setCurrencySymbol(e.target.value)}
                />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={
                        <Switch
                            checked={deleteWarnings}
                            onChange={(e) => setDeleteWarnings(e.target.checked)}
                        />
                    }
                    label="Show Delete Warnings"
                />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={
                        <Switch
                            checked={showChangelog}
                            onChange={(e) => setShowChangelog(e.target.checked)}
                        />
                    }
                    label="Changelog Popups on Login"
                />
            </Grid>
        </Grid>
    );
};

export default GeneralSettings;