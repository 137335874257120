// ProductDetails.js
import React, { useState, useRef } from 'react';
//import { UserContext } from './../../../context/UserContext';
import { fetchProducts } from './../../../api/productAPI';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
    Button,
    Grid,
    TextField,
    Autocomplete,
    CircularProgress
} from '@mui/material';

//const TAX_RATE = 0.07;

function ccyFormat(num) {
    const numberFormat = parseFloat(num);
    return `${numberFormat.toFixed(2)}`;
}

function priceRow(qty, cost) {
    return qty * cost;
}

function subtotal(lineItems) {
    let total = 0;
    if (lineItems) {
        lineItems.forEach(item => {
            total += item.sellPrice * item.quantity;
        });
    }
    return total;
}

function findTaxRate(totalCost, subTotal, shippingCost = 0) {
    let taxRate = (((totalCost - subTotal - shippingCost) / subTotal) * 100).toFixed(2);
    if(isNaN(taxRate)){
        taxRate = "0";
    }
    return taxRate + "%";
}

function solveTotalCost(subTotal, tax, shippingCost = 0) {
    let TotalCost = (parseFloat(subTotal || 0) + parseFloat(tax || 0) + parseFloat(shippingCost || 0)).toFixed(2);
    if(isNaN(TotalCost)){
        TotalCost = "0.00";
    }
    return TotalCost;
}

const Items = ({
    order,
    editMode,
    lineItems = [],
    setLineItems = () => { },
    tax = 0,
    setTax = () => { },
    shippingPrice = 0,
    setShippingPrice = () => { },
}) => {
    const [autoCompleteProductOpen, setAutoCompleteProductOpen] = useState(false);
    const [autoCompleteProducts, setAutoCompleteProducts] = useState([{ id: null, name: 'Custom Item' }]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [autoCompleteIsLoading, setAutoCompleteIsLoading] = useState(false);
    const [autoCompleteFocused, setAutoCompleteFocused] = useState(false);


    const theme = useTheme();
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

    //const { userData } = useContext(UserContext);
    const invoiceSubtotal = subtotal(order?.lineItems);

    const searchTimeoutRef = useRef(null);
    const UpdateLineItemResults = (value = '') => {

        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        setAutoCompleteIsLoading(true);
        searchTimeoutRef.current = setTimeout(async () => {
            //get all ids from lineItems where id is not null
            const ids = lineItems.filter(item => item.item_id !== null).map(item => item.item_id);
            const filters = [{ field: 'name', operator: 'contains', value: value }, { field: 'id', operator: 'isNotAnyOf', value: ids }];
            const response = await fetchProducts(0, 25, filters);

            const customItem = { id: null, name: 'Custom Item' };

            setAutoCompleteProducts([customItem, ...response.items]);

            setAutoCompleteIsLoading(false);
        }, 1000);
    };

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        let newValue;

        if (
            name === "length" ||
            name === "width" ||
            name === "height" ||
            name === "weight" ||
            name === "sellPrice"
        ) {
            newValue = Math.abs(parseFloat(value));

            if (value.endsWith(".")) {
                newValue = `${newValue}.`;
            }

            if (isNaN(newValue)) {
                newValue = null;
            }
        } else if (name === "quantity") {
            newValue = Math.abs(parseInt(value)).toFixed(0);

            if (isNaN(newValue)) {
                newValue = null;
            }
        } else {
            newValue = value;
        }

        const updatedLineItems = [...lineItems];
        updatedLineItems[index] = { ...updatedLineItems[index], [name]: newValue };
        setLineItems(updatedLineItems);
    };

    const handleQuantityValidation = (event, index = null) => {
        const { name, value } = event.target;
        let newValue;

        if (
            name === "length" ||
            name === "width" ||
            name === "height" ||
            name === "weight" ||
            name === "sellPrice" ||
            name === "totalTax" ||
            name === "shippingPrice"
        ) {
            newValue = Math.abs(parseFloat(value)).toFixed(2);

            if (isNaN(newValue)) {
                newValue = null;
            }
        } else if (name === "quantity") {
            newValue = Math.abs(parseInt(value)).toFixed(0);

            if (isNaN(newValue)) {
                newValue = null;
            }
        } else {
            newValue = value;
        }

        if (index !== null) {
            if (value < 0) {
                event.target.value = 0;
                handleInputChange(event, index);
            } else {
                event.target.value = newValue;
                handleInputChange(event, index);
            }
        } else {
            if (name === "totalTax") {
                setTax(newValue);
            } else if (name === "shippingPrice") {
                setShippingPrice(newValue);
            }
        }

        if (name === 'quantity' && newValue === "0") {
            //remove line item
            const updatedLineItems = [...lineItems];
            updatedLineItems.splice(index, 1);
            setLineItems(updatedLineItems);
        }
    };

    const handleAddLineItem = () => {
        if (!selectedProduct) {
            document.getElementById("line-item-autocomplete").focus();
            return;
        }

        const updatedLineItems = [...lineItems];
        if (selectedProduct.id === null) {
            updatedLineItems.push({
                item_id: null,
                name: null,
                length: null,
                width: null,
                height: null,
                weight: null,
                quantity: 1,
                sellPrice: null,
            });
        } else {
            updatedLineItems.push({
                item_id: selectedProduct.id || null,
                name: selectedProduct.name || null,
                length: selectedProduct.length || null,
                width: selectedProduct.width || null,
                height: selectedProduct.height || null,
                weight: selectedProduct.weight || null,
                quantity: 1,
                sellPrice: selectedProduct.sellPrice || null,
            });
        }
        setLineItems(updatedLineItems);

        // Clear the selected product and reset the Autocomplete input
        setSelectedProduct(null);
        document.getElementById("line-item-autocomplete").value = "";
    };

    return editMode ? (
        <>
            <form noValidate autoComplete="off">
                <TableContainer>
                    <Table sx={{ minWidth: 1000 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ width: 400 }}>Name</TableCell>
                                <TableCell style={{ width: 305 }}>Dimensions (LxWxH)</TableCell>
                                <TableCell style={{ width: 100 }}>Weight</TableCell>
                                <TableCell style={{ width: 100 }}>Quantity</TableCell>
                                <TableCell style={{ width: 160 }}>Unit Price</TableCell>
                                <TableCell align="right" style={{ width: 150 }}>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lineItems.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <TextField
                                            name="name"
                                            value={item.name}
                                            onChange={(event) => handleInputChange(event, index)}
                                            fullWidth
                                            disabled={item.item_id !== null}
                                            error={item.name === null || item.name === ''}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Length"
                                                    name="length"
                                                    value={item.length}
                                                    onChange={(event) => handleInputChange(event, index)}
                                                    onBlur={(event) => handleQuantityValidation(event, index)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Width"
                                                    name="width"
                                                    value={item.width}
                                                    onChange={(event) => handleInputChange(event, index)}
                                                    onBlur={(event) => handleQuantityValidation(event, index)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Height"
                                                    name="height"
                                                    value={item.height}
                                                    onChange={(event) => handleInputChange(event, index)}
                                                    onBlur={(event) => handleQuantityValidation(event, index)}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name="weight"
                                            value={item.weight}
                                            onChange={(event) => handleInputChange(event, index)}
                                            onBlur={(event) => handleQuantityValidation(event, index)}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name="quantity"
                                            type="number"
                                            value={item.quantity}
                                            onChange={(event) => handleInputChange(event, index)}
                                            onBlur={(event) => handleQuantityValidation(event, index)}
                                            fullWidth
                                            inputProps={{ inputMode: 'numeric', min: 0 }}
                                            error={item.quantity === null || item.quantity === ''}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name="sellPrice"
                                            value={item.sellPrice}
                                            onChange={(event) => handleInputChange(event, index)}
                                            onBlur={(event) => handleQuantityValidation(event, index)}
                                            fullWidth
                                            error={item.sellPrice === null || item.sellPrice === ''}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="body2" component="span">
                                            {ccyFormat(priceRow(item.quantity, item.sellPrice))}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={1}>
                                    <Autocomplete
                                        id="line-item-autocomplete"
                                        fullWidth
                                        open={autoCompleteProductOpen}
                                        onOpen={() => {setAutoCompleteProductOpen(true); UpdateLineItemResults();}}
                                        onClose={() => setAutoCompleteProductOpen(false)}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        filterOptions={(x) => x}
                                        getOptionLabel={(option) => option.name}
                                        options={autoCompleteProducts}
                                        loading={autoCompleteIsLoading}
                                        onChange={(event, value) => setSelectedProduct(value)}
                                        value={selectedProduct}
                                        onInputChange={(event, value) => UpdateLineItemResults(value)}
                                        onFocus={() => setAutoCompleteFocused(true)}
                                        onBlur={() => setAutoCompleteFocused(false)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Products"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {autoCompleteIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                error={selectedProduct === null && autoCompleteFocused}
                                            />
                                        )}
                                    />
                                </TableCell>
                                <TableCell colSpan={5}>
                                    <Button variant="contained" color="primary" onClick={() => handleAddLineItem()}>
                                        Add Line Item
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell rowSpan={4} colSpan={3} />
                                <TableCell colSpan={2}>Subtotal</TableCell>
                                <TableCell align="right">{ccyFormat(subtotal(lineItems))}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Tax</TableCell>
                                <TableCell align="right">{findTaxRate(solveTotalCost(subtotal(lineItems), tax, shippingPrice), subtotal(lineItems), shippingPrice)}</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        name="totalTax"
                                        value={tax}
                                        onChange={(event) => setTax(event.target.value)}
                                        onBlur={handleQuantityValidation}
                                        fullWidth
                                        error={tax === null || tax === ''}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>Shipping</TableCell>
                                <TableCell align="right">
                                    <TextField
                                        name="shippingPrice"
                                        value={shippingPrice}
                                        onChange={(event) => setShippingPrice(event.target.value)}
                                        onBlur={handleQuantityValidation}
                                        fullWidth
                                        error={shippingPrice === null || shippingPrice === ''}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>Total</TableCell>
                                <TableCell align="right">{ccyFormat(solveTotalCost(subtotal(lineItems), tax, shippingPrice))}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </form>
        </>
    ) : (
        <>
            <TableContainer>
                <Table sx={{ minWidth: 450 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: 400 }}>Name</TableCell>
                            {!isMdScreen &&
                                <>
                                    <TableCell align="right" style={{ width: 305 }}>Dimensions (LxWxH)</TableCell>
                                    <TableCell align="right" style={{ width: 100 }}>Weight</TableCell>
                                </>
                            }
                            <TableCell align="right" style={{ width: 100 }}>Quantity</TableCell>
                            <TableCell align="right" style={{ width: 160 }}>Unit Price</TableCell>
                            <TableCell align="right" style={{ width: 150 }}>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {order.lineItems.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Typography variant="body2" component="span">
                                        {item.name}
                                    </Typography>
                                </TableCell>
                                {!isMdScreen &&
                                    <>
                                        <TableCell align="right">
                                            <Typography variant="body2" component="span">
                                                {(item.length && item.width && item.height) ? `${item.length} x ${item.width} x ${item.height}` : '--'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography variant="body2" component="span">
                                                {item.weight || '--'}
                                            </Typography>
                                        </TableCell>
                                    </>
                                }
                                <TableCell align="right">
                                    <Typography variant="body2" component="span">
                                        {item.quantity}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body2" component="span">
                                        {ccyFormat(item.sellPrice)}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="body2" component="span">
                                        {ccyFormat(priceRow(item.quantity, item.sellPrice))}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell rowSpan={4} />
                            {!isMdScreen &&
                                <>
                                    <TableCell rowSpan={4} />
                                    <TableCell rowSpan={4} />
                                </>
                            }
                            <TableCell colSpan={!isMdScreen ? 2 : 2}>Subtotal</TableCell>
                            <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={!isMdScreen ? 1 : 0}>Tax</TableCell>
                            <TableCell align="right">{findTaxRate(order.totalCost, invoiceSubtotal, order.shipmentPrice)}</TableCell>
                            <TableCell align="right">{ccyFormat(order.totalTax)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={!isMdScreen ? 2 : 2}>Shipping</TableCell>
                            <TableCell align="right">{ccyFormat(order.shipmentPrice)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={!isMdScreen ? 2 : 2}>Total</TableCell>
                            <TableCell align="right">{ccyFormat(order.totalCost)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default Items;
