import React, { useEffect } from 'react';
import { logEvent } from './../utilities/analytics';
import { Link } from '@mui/material';

function ErrorPage() {

  useEffect(() => {
    logEvent('navigate_404', {
        page_name: '404',
        description: 'User reached a 404 page',
    });
  }, []);

  return (
    <div className="error-page">
      <div className="error-container">
        <h1 className="error-title">404</h1>
        <p className="error-subtitle">Oops! Looks like you're lost.</p>
        <p className="error-description">
          The page you're looking for doesn't exist or has been moved.
        </p>
        <Link href="/" style={{color:'white'}} className="error-btn">
          Take me back home
        </Link>
      </div>
    </div>
  );
}

export default ErrorPage;
