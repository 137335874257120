import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import customFetch from './../utilities/customFetch';
import { CURRENT_VERSION } from '../version';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const defaultSettings = useMemo(() => ({
        lastChangelog: CURRENT_VERSION,
        firstLogin: '1',
        darkMode: '0',
        currencySymbol: '$',
        deleteWarnings: '1',
        showChangelog: '1',
        enableLabor: '0',
        laborCost: '0.00',
        decrementMaterials: '1'
    }), []);
    const [userData, setUserData] = useState({
        email: '',
        settings: defaultSettings,
        lastLogin: '',
    });
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const fetchUserData = useCallback(async () => {
        try {
            const response = await customFetch(`${process.env.REACT_APP_API_URL}my-settings`);
            const data = await response.json();

            // Merge fetched settings with default settings
            const mergedSettings = { ...defaultSettings, ...data.settings };

            // Update the userData state with the merged settings
            setUserData({ ...data, settings: mergedSettings });
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }, [defaultSettings]);

    useEffect(() => {
        if (isLoggedIn) {
            fetchUserData();
        }
    }, [isLoggedIn, fetchUserData]);

    const checkIsLoggedIn = async () => {
        try {
            const response = await customFetch(`${process.env.REACT_APP_API_URL}is-authenticated`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        } catch (error) {
            console.error('Error checking logged-in status:', error);
            setIsLoggedIn(false);
        }
    };

    const updateUserSettings = async (updatedData) => {
        try {
            // Call your backend API to update the user data
            const response = await customFetch(`${process.env.REACT_APP_API_URL}my-settings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            });

            const data = await response.json();
            // Update the settings in the userData context
            setUserData({ ...userData, settings: data.settings });
        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };

    return (
        <UserContext.Provider value={{ userData, userUserSettings: updateUserSettings, isLoggedIn, checkIsLoggedIn, defaultSettings }}>
            {children}
        </UserContext.Provider>
    );
};
