import React, { createContext, useState, useEffect } from 'react';
import { SnackbarProvider, useSnackbar, MaterialDesignContent } from 'notistack';
import { isElectron } from '../utilities/ElectronUtils'
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ToastContext = createContext();

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    color: '#333333',
  },
  '&.notistack-MuiContent-warning': {
    color: '#333333',
  },
}));

const ToastProvider = ({ isLandingNavigation, children }) => {
  const toastFunctions = useToast();
  const [ snackbarClass, setSnackbarClass ] = useState('');
  
  useEffect(() => {

    setSnackbarClass('');
    if (isLandingNavigation) {
      setSnackbarClass('snackbar-landing');
      return 'snackbar-landing';
    }
  
    if (isElectron) {
      setSnackbarClass('snackbar-electron');
      return 'snackbar-electron';
    }

    return '';
  }, [isLandingNavigation]);

  return (
    <ToastContext.Provider value={toastFunctions}>
      <SnackbarProvider
        classes={{
          containerRoot: snackbarClass,
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        maxSnack={6}
        preventDuplicate
        Components={{
          success: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
        }}
      >
        {children}
      </SnackbarProvider>
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showToast = (message, { key, variant, autoCloseDuration=6000, onClose=()=>{}, allowClose=true, extraAction }) => {

    const handleClose = (event, reason) => {
      if (onClose) {
        onClose(event, reason, key);
      }
      closeSnackbar(key);
    };

    const AlertAction = ({ ExtraAction }) => {
      const CloseButton = () => (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={(event) => {
            handleClose(event, "close-icon");
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      );
    
      return (
        <>
          {ExtraAction && (typeof ExtraAction === 'function' ? <ExtraAction /> : ExtraAction)}
          {onClose && allowClose && <CloseButton />}
        </>
      );
    };

    enqueueSnackbar(message, {
      key,
      variant,
      autoHideDuration: autoCloseDuration === false ? null : autoCloseDuration,
      persist: autoCloseDuration === false,
      action: <AlertAction ExtraAction={extraAction} />,
    });
  };

  const toastFunctions = {
    showError: (message, options) =>
      showToast(message, { ...options, variant: 'error' }),
    showWarning: (message, options) =>
      showToast(message, { ...options, variant: 'warning' }),
    showSuccess: (message, options) =>
      showToast(message, { ...options, variant: 'success' }),
    closeSnackbar,
  };

  return toastFunctions;
};

export { ToastProvider, useToast };
