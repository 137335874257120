// OrderDetails.js
import React, { useState } from 'react';
import {
  Typography,
  Grid,
  IconButton,
  Box,
  Chip,
  Tab,
  Tabs
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import OrderForm from './OrderForm';
import Items from './additionalDetails/Items';
import Notes from './additionalDetails/Notes';
import Shipment from './additionalDetails/Shipment';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

const OrderDetails = ({ order, onClose, setOrders, status }) => {
  const [tabValue, setTabValue] = useState(0);
  const [editMode, setEditMode] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  // const inventoryAvailable = (item) => {
  //   return item.quantity <= item.inventory;
  // }

  function getTextById(id) {
    const item = status.find(element => element.id === id);
    return item ? item.text : null;
  }


  return editMode ? (
    <OrderForm
      order={order}
      onClose={() => {
        setEditMode(false);
        onClose();
      }}
      setOrders={setOrders} />
  ) : (
    <>
      <Typography variant="h5" className="mb-4" align="left" style={{ position: 'sticky', top: 0, zIndex: 101, padding: '10px 0 0 10px' }}>
        Order #{order.orderNo}
        <Chip
          label={getTextById(order.status)}
          size="small"
        />
      </Typography>
      <IconButton onClick={onClose} style={{ position: 'absolute', top: 0, right: 0, zIndex: 101 }}>
        <CloseIcon />
      </IconButton>
      {order.status !== 2 && (
        <IconButton onClick={toggleEditMode} style={{ position: 'absolute', top: 0, right: 40, zIndex: 101 }}>
          <EditIcon />
        </IconButton>
      )}
      <Box sx={{ padding: '10px', overflow: 'auto', maxHeight: 'calc(100% - 46px)' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Order Details</Typography>
            {/* <Typography>Order Source: {order.via}</Typography> */}
            <Typography>Order Date: {new Date(order.orderDate).toLocaleDateString()}</Typography>
            <Typography>Customer Name: {order.customerName || '--'}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1">Billing Address</Typography>
            <Typography>Name: {order.billToName || '--'}</Typography>
            <Typography>Street: {order.billToStreet || '--'}</Typography>
            {order.billToStreet2 && <Typography>Street2: {order.billToStreet2}</Typography>}
            {order.billToStreet3 && <Typography>Street3: {order.billToStreet3}</Typography>}
            <Typography>City: {order.billToCity || '--'}</Typography>
            <Typography>State: {order.billToState || '--'}</Typography>
            <Typography>Zip: {order.billToZip || '--'}</Typography>
            <Typography>Country: {order.billToCountry || '--'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Product tabs"
              >
                <Tab label="Items" />
                <Tab label="Shipment" />
                <Tab label="Notes" />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
              <Items
                order={order}
                editMode={false} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Shipment 
                order={order}
                editMode={false} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Notes 
                order={order}
                editMode={false} />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OrderDetails;
