import customFetch from './../utilities/customFetch';

export const fetchTabs = async () => {
    try {
        const url = `${process.env.REACT_APP_API_URL}orders/tabs`;

        const response = await customFetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Failed to fetch tabs:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error fetching tabs:', error);
        return false;
    }
}

export const updateTabs = async (tabs) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}orders/tabs`;

        const response = await customFetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tabs),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Failed to update tabs:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error updating tabs:', error);
        return false;
    }
}

export const fetchOrders = async (page, pageSize, sort, filter) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}orders/get`;

        const filters = {
            items: filter,
            logicOperator: 'AND',
        }

        const requestData = {
            page: page,
            pageSize: pageSize,
            sort: sort,
            filter: filters
        };

        const response = await customFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Failed to fetch orders:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return false;
    }
};

export const fetchOrderCount = async (filter) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}orders/count`;

        const filters = {
            items: filter,
            logicOperator: 'AND',
        }

        const requestData = {
            filter: filters
        };

        const response = await customFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Failed to fetch order count:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return false;
    }
};

export const deleteOrder = async (id) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}orders/${id}`;

        const response = await customFetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Failed to delete order:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error while deleting order:', error);
        return false;
    }
};

export const submitOrder = async (orderData, orderId = null) => {
    try {
        const response = await customFetch(orderId ? `${process.env.REACT_APP_API_URL}orders/${orderId}` : `${process.env.REACT_APP_API_URL}orders`, {
            method: orderId ? "PUT" : "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(orderData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Error creating order:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error("Error creating order:", error);
        return false;
    }
};

export const updateOrderStatus = async (orderId, status) => {
    try {
        const response = await submitOrder({ status }, orderId);
        return response;
    } catch (error) {
        console.error('Error updating order status:', error);
        throw error;
    }
}