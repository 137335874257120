import React from 'react';
import { useNavigate } from 'react-router-dom';
import LandingNavigation from '../LandingNavigation';
import Banner from '../Banner';
import Features from '../Features';
import Footer from '../Footer';

const ReportingModule = ({ sectionRefs }) => {
    const navigate = useNavigate();
    
    const handleLinkClick = (e, path) => {
      e.preventDefault();
      navigate(path);
    };

    const BannerContent = {
        title: 'Reporting Module',
        body: 'Track your business performance with additional reporting features.',
        additional: (
          <div className="flex flex-row items-center space-x-6">
            <a href="/download" onClick={(e) => handleLinkClick(e, '/download')} className="btn btn-primary bg-secondary text-white px-6 py-2 rounded hover:bg-tertiary">Download for Windows</a>
            <a href="/login" onClick={(e) => handleLinkClick(e, '/login')} className="btn btn-primary bg-secondary text-white px-6 py-2 rounded hover:bg-tertiary">Open in your browser</a>
          </div>
        )
    };

    const featuresList = [
        {
            title: 'Comprehensive Data Analytics',
            body:
                'Gain valuable insights into your business performance with our in-depth data analytics features. Analyze key metrics, trends, and patterns to make informed decisions and drive business growth.',
            screenshot: 'DataAnalytics.png',
            layout: 'left',
        },
        {
            title: 'Customizable Reports',
            body:
                'Create custom reports tailored to your specific needs, with a wide range of report templates and data visualization options. Easily adapt reports to present the most relevant and meaningful data for your business objectives.',
            screenshot: 'CustomReports.png',
            layout: 'right',
        },
        {
            title: 'Scheduled Report Generation',
            body:
                'Automate your reporting process by scheduling report generation at regular intervals. Receive timely, up-to-date reports to stay informed and monitor your business performance consistently.',
            screenshot: 'ScheduledReports.png',
            layout: 'left',
        },
        {
            title: 'Export and Share Reports',
            body:
                'Easily export your reports in various formats, such as PDF, Excel, or CSV, and share them with your team, stakeholders, or clients. Ensure that everyone stays informed and aligned on key business metrics and goals.',
            screenshot: 'ExportShare.png',
            layout: 'right',
        },
        {
            title: 'Secure Data Storage',
            body:
                'Rest assured that your sensitive business data is securely stored and protected with our robust data security measures. Maintain the privacy and confidentiality of your valuable data while generating insightful reports.',
            screenshot: 'SecureData.png',
            layout: 'left',
        },
        {
            title: 'User-Friendly Report Builder',
            body:
                'Design and build insightful reports with ease using our intuitive, user-friendly report builder. Simplify the reporting process and make data analysis accessible for users of all skill levels.',
            screenshot: 'ReportBuilder.png',
            layout: 'right',
        },
    ];

    return (
        <div className="min-h-screen bg-primary text-primary flex flex-col">
            <LandingNavigation sectionRefs={sectionRefs} />

            <main className="flex-grow overflow-y-auto mt-20">
                <Banner Title={BannerContent.title} Body={BannerContent.body} />

                <Features featuresList={featuresList} />

                <Footer />

            </main>
        </div>
    );
};

export default ReportingModule;

