const generateTerms = (discounts, customTerms = {}) => {
    const baseTerms = ['1 month', '3 months', '6 months', '1 year'];
    const multipliers = [1, 3, 6, 12];

    if (Object.keys(customTerms).length > 0) {
        return customTerms;
    }

    const terms = baseTerms.reduce((terms, term, index) => {
        terms[term] = {
            multiplier: multipliers[index],
            discount: discounts[index] || 0,
        };
        return terms;
    }, {});

    return terms;
};

const generateAdditionalOptions = (title, priceTiers) => {
    return [
        {
            title,
            prices: priceTiers.reduce((prices, tier) => {
                prices[tier.name] = tier.price;
                return prices;
            }, {}),
        },
    ];
};

export const processModules = (modules, cartItems) => {
    return modules.map((module) => {
        const itemInCart = cartItems.find((item) => item.title === module.title);
        if (itemInCart) {
            return {
                ...module,
                disabled: true,
            };
        }
        return module;
    });
};

export const calculateItemPrice = (item) => {
    let basePrice = item.basePrice ? item.basePrice : item.price;
    const termMultiplier = item.terms && item.terms[item.selectedTerm].multiplier;
    const discount = item.terms && item.terms[item.selectedTerm].discount;
    
    if (item.additionalOptions) {
        const additionalPrices = Object.entries(item.selectedAdditionalOption || {}).reduce((prev, [optionTitle, option]) => {
            const correspondingOptionDetails = item.additionalOptions.find(additionalOption => additionalOption.title === optionTitle);
            const optionPrice = correspondingOptionDetails.prices[option] || 0;
            return prev + optionPrice;
        }, 0);

        if (additionalPrices > 0) {
            basePrice = additionalPrices;
        }
    }

    const discountedPrice = termMultiplier ? (basePrice * termMultiplier) * (1 - (discount / 100)) : basePrice;

    return {
        basePrice: basePrice,
        finalPrice: discountedPrice,
    };
};

export const calculateTotal = (cartItems) => {
    const total = cartItems.reduce((acc, item) => {
        const itemPrice = calculateItemPrice(item).finalPrice;
        return acc + itemPrice;
    }, 0);

    return total.toFixed(2);
};

export const setDefaultAdditionalOptions = (additionalOptions) => {
    if (!additionalOptions) return null;
    const selectedOptions = {};
    additionalOptions.forEach((option) => {
        selectedOptions[option.title] = Object.keys(option.prices)[0];
    });
    return selectedOptions;
};

export const initialModules = [
    {
        title: 'Integration Module',
        description: 'The integration module provides seamless integration with various third-party tools.',
        isActive: true,
        allowAutoRenew: true,
        date: '2023-05-05',
        price: 9.99,
        terms: generateTerms([0, 10, 20, 30]),
        additionalOptions: generateAdditionalOptions('Package', [
            { name: 'Startup', price: 9.99 },
            { name: 'Growth', price: 19.99 },
            { name: 'Launch', price: 29.99 },
            { name: 'Business', price: 39.99 },
        ]),
    },
    {
        title: 'Reporting Module',
        description: 'The reporting module offers detailed reporting and analytics for your data.',
        isActive: false,
        allowAutoRenew: true,
        date: '2023-06-10',
        price: 4.99,
        terms: generateTerms([0, 5, 10, 20]),
    },
    {
        title: 'Tax Module',
        description: 'The tax module helps manage tax-related tasks and ensures compliance with tax regulations.',
        isActive: false,
        allowAutoRenew: false,
        date: '2023-06-10',
        price: 19.99,
        additionalOptions: generateAdditionalOptions('Tax Year', [
            { name: '2024', price: 19.99 },
            { name: '2023', price: 19.99 },
            { name: '2022', price: 19.99 },
            { name: '2021', price: 19.99 },
        ]),
    },
];
