import { isElectron, setElectronToken } from './ElectronUtils';
import customFetch from './customFetch';

export const handleLogout = async (checkIsLoggedIn, navigate) => {
  try {
    if (isElectron) {
      // Clear the user token if the app is running on Electron
      await setElectronToken('');
    } else {
      // Make a request to your API to clear the session/cookie
      const response = await customFetch(process.env.REACT_APP_API_URL + 'logout', {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Error during logout');
      }
    }

    // Update the authentication status
    checkIsLoggedIn();

    // Redirect the user to the login page or any other desired page
    navigate('/login');
  } catch (error) {
    alert(error.message);
  }
};
