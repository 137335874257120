import React, { useContext } from 'react';
import { UserContext } from './../context/UserContext';
import customFetch from '../utilities/customFetch';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, TextField, Button, Typography, Link } from '@mui/material';

export default function SignupPage() {
    const { defaultSettings } = useContext(UserContext);
    const navigate = useNavigate();

    async function handleRegister(e) {
        e.preventDefault();

        const email = e.target.email.value;
        const password = e.target.password.value;

        try {
            const response = await customFetch(process.env.REACT_APP_API_URL + 'register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password, defaultSettings }),
            });

            if (!response.ok) {
                throw new Error('Registration failed');
            }

            await response.json();
            //alert('User registered successfully');
            // Redirect the user to the login page or any other desired page
            navigate('/login');
        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <div className="signup-page">
            <Card className="signup-card">
                <CardContent>
                    <Typography variant="h5" component="h2">
                        Sign Up
                    </Typography>
                    <form onSubmit={handleRegister} >
                        <TextField name="email" label="Email" type="email" fullWidth margin="normal" />
                        <TextField name="password" label="Password" type="password" fullWidth margin="normal" />
                        <TextField name="confirmPassword" label="Confirm Password" type="password" fullWidth margin="normal" />
                        <Button variant="contained" style={{ backgroundColor: '#1484ac', color: '#fff' }} fullWidth type="submit">
                            Sign Up
                        </Button>
                        <Typography variant="body2">
                            Already have an account?{' '}
                            <Link href="#" onClick={() => navigate('/login')}>
                                Login
                            </Link>
                        </Typography>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}
