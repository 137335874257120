// ProductDetails.js
import React, { useState } from 'react';
import Components from './additionalDetails/Components';
import Varients from './additionalDetails/Varients';
//import Integrations from './additionalDetails/Integrations';
import { Typography, Grid, IconButton, Box, Tab, Tabs, Divider, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ProductForm from './ProductForm';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

const ProductDetails = ({ item, onClose, setItems }) => {
    const [tabValue, setTabValue] = useState(0);
    const [editMode, setEditMode] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const findProductionQuantity = (components) => {
        let productionQuantity = null;
        components.forEach((component) => {
            let componentUseQuantity = Math.floor(component.total_remaining / component.quantity);
            if (productionQuantity === null || componentUseQuantity < productionQuantity) {
                productionQuantity = componentUseQuantity;
            }
        });
        if (productionQuantity === null) {
            productionQuantity = 0;
        }
        return productionQuantity;
    }

    return editMode ? (
        <ProductForm
            item={item}
            onClose={(deleted = false) => {
                setEditMode(false);
                if (deleted) {
                    onClose(null);
                }
            }}
            setItems={setItems} />
    ) : (
        <>
            <Typography variant="h5" className="mb-4" align="left" style={{ position: 'sticky', top: 0, zIndex: 101, padding: '10px 0 0 10px' }}>
                {item.name}
            </Typography>
            <IconButton onClick={onClose} style={{ position: 'absolute', top: 0, right: 0, zIndex: 101 }}>
                <CloseIcon />
            </IconButton>
            <IconButton onClick={toggleEditMode} style={{ position: 'absolute', top: 0, right: 40, zIndex: 101 }}>
                <EditIcon />
            </IconButton>
            <Divider />
            <Box sx={{ padding: '10px', overflow: 'auto', maxHeight: 'calc(100% - 46px)' }}>
                <Grid container spacing={3}>
                    {item.pictures && (
                        <Grid item xs={12} sm={6}>
                            {/* Display the first image as a placeholder */}
                            <img src={item.pictures[0]} alt="Product" width="100%" />
                            {/* Image selector placeholder */}
                            <Typography variant="body1">Image selector goes here</Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Details</Typography>
                        <Typography align="left">Status: {item.status === 1 ? 'Active' : 'Inactive'}</Typography>
                        <Typography align="left">SKU: {item.sku || '--'}</Typography>
                        <Typography align="left">UPC: {item.upc || '--'}</Typography>
                        <Typography align="left">Description: {item.description || '--'}</Typography>
                        <Typography align="left">Details: {item.details || '--'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Unit of Measure</Typography>
                        <Typography align="left">Unit: {item.unit || '--'}</Typography>
                        <Typography align="left">Unit Weight: {item.weight ? `${item.weight} lbs` : '--'}</Typography>
                        <Typography align="left">Unit Width: {item.width ? `${item.width} in` : '--'}</Typography>
                        <Typography align="left">Unit Height: {item.height ? `${item.height} in` : '--'}</Typography>
                        <Typography align="left">Unit Length: {item.length ? `${item.length} in` : '--'}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Inventory</Typography>
                        <Typography align="left">Production Quantity: {findProductionQuantity(item?.components)}</Typography>
                        <Typography align="left">Total Quantity: {item.inventory || 0}</Typography>
                        <Typography align="left">On Order Quantity: {item.openOrderQuantity || 0}</Typography>
                        <Typography align="left">Available Quantity: {item.inventory - item.openOrderQuantity || 0}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Product tabs"
                            >
                                <Tab label="Components" />
                                <Tooltip title="Coming soon" placement="bottom">
                                    <span>
                                        <Tab label="Variants" disabled />
                                    </span>
                                </Tooltip>
                                {/* <Tooltip title="Coming soon" placement="top">
                                    <span>
                                        <Tab label="Integrations" disabled />
                                    </span>
                                </Tooltip> */}
                            </Tabs>
                        </Box>
                        <TabPanel value={tabValue} index={0}>
                            <Components
                                item={item}
                                editMode={false} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <Varients
                                item={item}
                                editMode={false} />
                        </TabPanel>
                        {/* <TabPanel value={tabValue} index={2}>
                            <Integrations
                                item={item}
                                editMode={false} />
                        </TabPanel> */}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ProductDetails;
