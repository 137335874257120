import React from 'react';
import LandingNavigation from '../LandingNavigation';
import Banner from '../Banner';
import Footer from '../Footer';

const Privacy = ({ sectionRefs }) => {

    const BannerContent = {
        title: 'Privacy Policy'
    };

    return (
        <div className="min-h-screen bg-primary text-primary flex flex-col">
            <LandingNavigation sectionRefs={sectionRefs} />

            <main className="flex-grow overflow-y-auto mt-20">
                <Banner Title={BannerContent.title} />

                <section className="lg:mt-0 mt-2 lg:space-y-16 space-y-4 bg-primary p-6 px-12">
                    <div align="left" className={`feature-card max-w-screen-md mx-auto p-6 lg:p-12 bg-accent rounded shadow text-primary flex flex-col space-x-0 items-center lg:items-start`}>
                        <h2>1. Introduction</h2>
                        <p>MakerMate is committed to safeguarding your privacy and ensuring the protection of your personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information through our website application ("Service"). By using the Service, you agree to the terms of this Privacy Policy.</p>

                        <h2>2. Information We Collect</h2>
                        <p>We collect personal information such as email addresses and IP addresses. Email addresses are utilized for user notifications, which users can opt into, verification of signup emails, password resets, and other essential communications. IP addresses are collected for security purposes, allowing users to verify previous logins to their accounts.</p>

                        <h2>3. Use of Cookies and Google Analytics</h2>
                        <p>We employ cookies to manage user accounts across the site and gather usage data through Google Analytics. Google Analytics assists us in understanding user engagement with our website, enabling us to optimize and enhance the user experience.</p>

                        <h2>4. Data Retention and Deletion</h2>
                        <p>We retain accounts that have not been used for up to one year, unless the user has previously purchased additional modules. In such cases, data will be maintained for the duration of our business operations. We provide a paid service that enables users to export all their data from the site in bulk.</p>

                        <h2>5. Data Security and Breach Response</h2>
                        <p>We are dedicated to maintaining the security of user data. In the event of a data breach, we will promptly notify affected users via their registered email addresses, conduct a thorough investigation into the breach, and provide relevant details based on the outcome of our investigation.</p>

                        <h2>6. Third-Party Services</h2>
                        <p>We may employ third-party services to support the functionality of our Service. These third-party service providers may have access to your personal information solely for the purpose of performing the services on our behalf and are obligated not to disclose or use it for any other purpose.</p>

                        <h2>7. Changes to Privacy Policy</h2>
                        <p>We may update our Privacy Policy from time to time. The most current version will always be available on the MakerMate website. Your continued use of the Service after any modifications to the Privacy Policy indicates your acceptance of the updated terms. If you do not agree to the new terms, you must discontinue your use of the Service.</p>

                        <h2>8. Contact Us</h2>
                        <p>For any questions or concerns regarding this Privacy Policy, please contact us at contact@makermate.app or makermikesshop@gmail.com.</p>
                    </div>
                </section>

                <Footer />

            </main>
        </div>
    );
};

export default Privacy;
