// ProductDetails.js
import React from 'react';
//import { UserContext } from './../../../context/UserContext';
import {
    Typography,
    Grid,
    TextField,
    FormControlLabel,
    Checkbox
} from '@mui/material';

const Shipment = ({
    order,
    editMode,
    shipmentData = {
        shipToName: '',
        shipToStreet: '',
        shipToStreet2: '',
        shipToStreet3: '',
        shipToCity: '',
        shipToState: '',
        shipToZip: '',
        shipToCountry: '',
        weight: '',
        length: '',
        width: '',
        height: '',
        shipmentCharge: '',
        trackingNo: '',
    },
    setShipmentData = () => { },
    copyBillTo = false,
    setCopyBillTo = () => { },
    billingData = {
        billToName: '',
        billToStreet: '',
        billToStreet2: '',
        billToStreet3: '',
        billToCity: '',
        billToState: '',
        billToZip: '',
        billToCountry: '',
    }
}) => {
    //const { userData } = useContext(UserContext);
    //const [copyBillTo, setCopyBillTo] = useState(order?.copyBillTo || false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let useVal = value;
        if(value === ''){
            useVal = null;
        }
        setShipmentData({ ...shipmentData, [name]: useVal });
    };

    return editMode ? (
        <>
            <form noValidate autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Package Details</Typography>
                        <TextField
                            label="Weight"
                            name="weight"
                            value={shipmentData.weight}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Length"
                            name="length"
                            value={shipmentData.length}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Width"
                            name="width"
                            value={shipmentData.width}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Height"
                            name="height"
                            value={shipmentData.height}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Shipment Charge"
                            name="shipmentCharge"
                            value={shipmentData.shipmentCharge}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Tracking Number"
                            name="trackingNo"
                            value={shipmentData.trackingNo}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1">Shipment Address</Typography>
                        <FormControlLabel sx={{ float: 'left' }} control={<Checkbox checked={copyBillTo === 1} onChange={(event) => setCopyBillTo(copyBillTo === 1 ? 0 : 1)} />} label="Same as Billing" />
                        <TextField
                            label="Name"
                            name="shipToName"
                            value={copyBillTo ? billingData?.billToName : shipmentData.shipToName}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={copyBillTo}
                            error={!copyBillTo && (shipmentData.shipToName === null || shipmentData.shipToName === '')}
                        />
                        <TextField
                            label="Street"
                            name="shipToStreet"
                            value={copyBillTo ? billingData?.billToStreet : shipmentData.shipToStreet}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={copyBillTo}
                            error={!copyBillTo && (shipmentData.shipToStreet === null || shipmentData.shipToStreet === '')}
                        />
                        <TextField
                            label="Street 2"
                            name="shipToStreet2"
                            value={copyBillTo ? billingData?.billToStreet2 : shipmentData.shipToStreet2}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={copyBillTo}
                        />
                        <TextField
                            label="Street 3"
                            name="shipToStreet3"
                            value={copyBillTo ? billingData?.billToStreet3 : shipmentData.shipToStreet3}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={copyBillTo}
                        />
                        <TextField
                            label="City"
                            name="shipToCity"
                            value={copyBillTo ? billingData?.billToCity : shipmentData.shipToCity}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={copyBillTo}
                            error={!copyBillTo && (shipmentData.shipToCity === null || shipmentData.shipToCity === '')}
                        />
                        <TextField
                            label="State"
                            name="shipToState"
                            value={copyBillTo ? billingData?.billToState : shipmentData.shipToState}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={copyBillTo}
                            error={!copyBillTo && (shipmentData.shipToState === null || shipmentData.shipToState === '')}
                        />
                        <TextField
                            label="Zip"
                            name="shipToZip"
                            value={copyBillTo ? billingData?.billToZip : shipmentData.shipToZip}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={copyBillTo}
                            error={!copyBillTo && (shipmentData.shipToZip === null || shipmentData.shipToZip === '')}
                        />
                        <TextField
                            label="Country"
                            name="shipToCountry"
                            value={copyBillTo ? billingData?.billToCountry : shipmentData.shipToCountry}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                            disabled={copyBillTo}
                            error={!copyBillTo && (shipmentData.shipToCountry === null || shipmentData.shipToCountry === '')}
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    ) : (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Package Details</Typography>
                    {/* <Typography>Carrier: {order.carrier}</Typography>
                    <Typography>Service: {order.service}</Typography> */}
                    <Typography>Weight: {order?.weight ? `${order.weight} lbs` : '--'}</Typography>
                    <Typography>Length: {order?.length || '--'}</Typography>
                    <Typography>Width: {order?.width || '--'}</Typography>
                    <Typography>Height: {order?.height || '--'}</Typography>
                    <Typography>Shipment Charge: {order?.shipmentCharge || '--'}</Typography>
                    <Typography>Tracking Number: {order?.trackingNo || '--'}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Shipment Address</Typography>
                    <Typography>Name: {order?.shipToName}</Typography>
                    <Typography>Street: {order?.shipToStreet}</Typography>
                    {order?.shipToStreet2 && <Typography>Street2: {order?.shipToStreet2}</Typography>}
                    {order?.shipToStreet3 && <Typography>Street3: {order?.shipToStreet3}</Typography>}
                    <Typography>City: {order?.shipToCity}</Typography>
                    <Typography>State: {order?.shipToState}</Typography>
                    <Typography>Zip: {order?.shipToZip}</Typography>
                    <Typography>Country: {order?.shipToCountry}</Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default Shipment;
