import React from 'react';
import logo from '../assets/logo-beta.svg';

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <div className="loading-logo-container">
        <img src={logo} alt="Logo" className="loading-logo" style={{ width: '100px', height: 'auto' }} />
      </div>
      <div className="loading-text-container">
        <h1 className="loading-title">Loading...</h1>
      </div>
    </div>
  );
}

export default LoadingScreen;
