import React, { useState } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  BarChart,
  AreaChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Area,
  Bar
} from 'recharts';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

const data = [
  { name: 'Jan', Sales: 4000, Expenses: 2400, Inventory: 2400 },
  { name: 'Feb', Sales: 3000, Expenses: 1398, Inventory: 2210 },
  { name: 'Mar', Sales: 2000, Expenses: 9800, Inventory: 2290 },
  { name: 'Apr', Sales: 2780, Expenses: 3908, Inventory: 2000 },
  { name: 'May', Sales: 1890, Expenses: 4800, Inventory: 2181 },
  { name: 'Jun', Sales: 2390, Expenses: 3800, Inventory: 2500 },
  { name: 'Jul', Sales: 3490, Expenses: 4300, Inventory: 2100 },
  { name: 'Aug', Sales: 3490, Expenses: 4300, Inventory: 2100 },
  { name: 'Sep', Sales: 3490, Expenses: 4300, Inventory: 2100 },
  { name: 'Oct', Sales: 3490, Expenses: 4300, Inventory: 2100 },
  { name: 'Nov', Sales: 3490, Expenses: 4300, Inventory: 2100 },
  { name: 'Dec', Sales: 3490, Expenses: 4300, Inventory: 2100 },
];

const dataConfig = {
  xAxis: 'name',
  yAxis: null,
  plots: [
    { name: 'Sales', type: 'monotone', color: '#8884d8' },
    { name: 'Expenses', type: 'monotone', color: '#888420' },
    { name: 'Inventory', type: 'monotone', color: '#882020' }
  ]
}

const Chart = ({ layoutItem, isEditMode, text, setText }) => {
  const [selectedChart, setSelectedChart] = useState('line');

  const handleChartSelect = (event) => {
    setSelectedChart(event.target.value);
  };

  const ChartRender = () => {
    if (selectedChart === 'bar') {
      return (
        <BarChart
          data={data}
          margin={{ top: 15, right: 30, left: 5, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataConfig.xAxis} />
          <YAxis dataKey={dataConfig.yAxis} />
          <Tooltip />
          <Legend />
          {dataConfig.plots.map((plot, index) => {
            return (
              <Bar
                key={index}
                type={plot.type}
                dataKey={plot.name}
                stroke={plot.color}
                fill={plot.color}
              />
            );
          })}
        </BarChart>
      );
    } else if (selectedChart === 'area') {
      return (
        <AreaChart
          data={data}
          margin={{ top: 15, right: 30, left: 5, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataConfig.xAxis} />
          <YAxis dataKey={dataConfig.yAxis} />
          <Tooltip />
          <Legend />
          {dataConfig.plots.map((plot, index) => {
            return (
              <Area
                key={index}
                type={plot.type}
                dataKey={plot.name}
                stroke={plot.color}
                fill={plot.color}
              />
            );
          })}
        </AreaChart>
      );
    } else if (selectedChart === 'line') {
      return (
        <LineChart
          data={data}
          margin={{ top: 15, right: 30, left: 5, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={dataConfig.xAxis} />
          <YAxis dataKey={dataConfig.yAxis} />
          <Tooltip />
          <Legend />
          {dataConfig.plots.map((plot, index) => {
            return (
              <Line
                key={index}
                type={plot.type}
                dataKey={plot.name}
                stroke={plot.color}
              />
            );
          })}
        </LineChart>
      );
    }
  };

  return (
    <>
      {isEditMode ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="chart-select-label">Chart</InputLabel>
            <Select
              labelId="chart-select-label"
              id="chart-select"
              value={selectedChart}
              onChange={handleChartSelect}
            >
              <MenuItem value={'line'}>Line Chart</MenuItem>
              <MenuItem value={'bar'}>Bar Chart</MenuItem>
              <MenuItem value={'area'}>Area Chart</MenuItem>
            </Select>
          </FormControl>
        </div>
      ) : (
        <div style={{ width: '100%', height: ((layoutItem.h * 150) + ((layoutItem.h - 1) * 10)) - 50 }}>
          <h1>{text}</h1>
          <ResponsiveContainer>
            {ChartRender()}
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default Chart;