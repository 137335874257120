// ProductDetails.js
import React from 'react';
//import { UserContext } from './../../../context/UserContext';
import { Typography, TextField } from '@mui/material';

const Notes = ({
    order,
    editMode,
    notes = '',
    setNotes = () => { },
}) => {
    //const { userData } = useContext(UserContext);

    return editMode ? (
        <>
            <form noValidate autoComplete="off">
                <TextField
                    label="Notes"
                    multiline
                    rows={4}
                    value={notes}
                    onChange={(event) => setNotes(event.target.value)}
                    fullWidth
                    margin="normal"
                />
            </form>
        </>
    ) : (
        <>
            <Typography variant="body1" align="left">{order?.notes || '--'}</Typography>
        </>
    );
};

export default Notes;
