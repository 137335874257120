import React, { useContext } from 'react';
import { UserContext } from './../context/UserContext';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children }) {
  const { isLoggedIn } = useContext(UserContext);

  return isLoggedIn ? (
    children
  ) : (
    <Navigate to="/login" replace />
  );
}

export default ProtectedRoute;
