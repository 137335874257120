import React, { useState, useEffect, useLayoutEffect } from 'react';
import { logEvent } from './../utilities/analytics';
import {
    Button,
    Typography,
    Box,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Backdrop,
} from '@mui/material';
import './../styles/Tutorial.css';

const steps = [
    {
        label: 'Welcome',
        description: 'Welcome to MakerMate! This is a tutorial to help you get started.',
        drawBackground: true,
        navigate: 'dashboard'
    },
    {
        label: 'Dashboard',
        description: 'This is your Dashboard. You can customize it with reports and widgets to monitor your business.',
        targetSelector: '.App-page',
        vertical: 'bottom',
        navigate: 'dashboard'
    },
    {
        label: 'Expenses',
        description: 'This is your Expenses. You can track all your purchases here including materials, supplies, and fees.',
        vertical: 'bottom',
        navigate: 'expenses',
    },
    {
        label: 'Materials',
        description: 'This is your Materials. This page provides a simple way to adjust your material inventory, and preview some additional information.',
        vertical: 'bottom',
        navigate: 'materials',
    },
    {
        label: 'Inventory',
        description: 'This is your Inventory page. You can make listing and track inventory of your products here.',
        vertical: 'bottom',
        navigate: 'inventory',
    },
    {
        label: 'Orders',
        description: 'This is your Orders page. Add your orders here to automatically deduct inventory and track your sales.',
        vertical: 'bottom',
        navigate: 'orders',
    },
];

const Tutorial = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    //const [highlightedElement, setHighlightedElement] = useState(null);
    const [stepperPosition, setStepperPosition] = useState({ top: 25, left: 60 });
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useLayoutEffect(() => {
        if (activeStep < steps.length) {
            if (steps[activeStep].targetSelector) {
                //const targetElement = document.querySelector(steps[activeStep].targetSelector);
                //setHighlightedElement(targetElement);
            } else {
                //setHighlightedElement(null);
            }
            const tutorialStepper = document.querySelector('.tutorial .MuiBox-root');
            let newPosition = {
                top: 'auto',
                left: 'auto',
                bottom: 'auto',
                right: 'auto',
            };
            switch (steps[activeStep].vertical) {
                case 'bottom':
                    newPosition.top = 'auto';
                    newPosition.bottom = 25;
                    break;
                case 'top':
                    newPosition.top = 25;
                    newPosition.bottom = 'auto';
                    break;
                default:
                    newPosition.top = (windowSize.height / 2) - (tutorialStepper.offsetHeight / 2 + 30);
                    newPosition.bottom = 'auto';
                    break;
            }
            switch (steps[activeStep].horizontal) {
                case 'left':
                    newPosition.left = 25;
                    newPosition.right = 'auto';
                    break;
                case 'right':
                    newPosition.left = 'auto';
                    newPosition.right = 25;
                    break;
                default:
                    newPosition.left = (windowSize.width / 2) - (tutorialStepper.offsetWidth / 2);
                    newPosition.right = 'auto';
                    break;
            }
            setStepperPosition(newPosition);
        } else {
            //setHighlightedElement(null);
        }
    }, [activeStep, windowSize]);

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNext = () => {
        if (steps[activeStep + 1]?.navigate) {
            console.log("Navigate to: " + steps[activeStep + 1].navigate);
            if (steps[activeStep + 1].navigate === 'dashboard') {
                props.navigate('');
            } else {
                props.navigate(steps[activeStep + 1].navigate);
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        logEvent('tutorial_next', {
          tutorial_action: 'next',
          description: 'User progressed forward through the tutorial.',
        });
    };

    const handleBack = () => {
        if (steps[activeStep - 1]?.navigate) {
            console.log("Navigate to: " + steps[activeStep - 1].navigate);
            if (steps[activeStep - 1].navigate === 'dashboard') {
                props.navigate('');
            } else {
                props.navigate(steps[activeStep - 1].navigate);
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

        logEvent('tutorial_previous', {
          tutorial_action: 'previous',
          description: 'User progressed backwards through the tutorial.',
        });
    };

    const handleClose = () => {
        logEvent('tutorial_close', {
          tutorial_action: 'close',
          description: 'User closed the tutorial.',
        });
        props.handleClose();
        props.navigate('');
    };

    return (
        <div className="tutorial">
            <Backdrop
                sx={{
                    backgroundColor: (steps[activeStep]?.drawBackground ? "rgba(0, 0, 0, 0.85)" : "transparent"),
                    zIndex: 1000,
                    display: (steps[activeStep]?.drawBackground ? "block" : "none")
                }}
                open={true}
            >
            </Backdrop>
            <Box
                className="tutorial"
                sx={{
                    backgroundColor: (!steps[activeStep]?.drawBackground ? 'rgba(0, 0, 0, 0.85)' : 'transparent'),
                    padding: 2,
                    borderRadius: 6,
                    position: 'absolute',
                    top: stepperPosition.top,
                    left: stepperPosition.left,
                    right: stepperPosition.right,
                    bottom: stepperPosition.bottom,
                    zIndex: 2000,
                    maxWidth: 400,
                }}
            >
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepLabel>{step.label}</StepLabel>
                            <StepContent>
                                <Typography sx={{ color: 'white' }}>{step.description}</Typography>
                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={index === steps.length - 1 ? handleClose : handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                        </Button>

                                        <Button
                                            onClick={index === 0 ? handleClose : handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === 0 ? 'Skip' : 'Back'}
                                        </Button>

                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </div>
    );
};

export default Tutorial;

