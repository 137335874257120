import React, { useState, useEffect } from 'react';
import { logEvent } from '../../utilities/analytics';
import {
    Grid,
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material';

function InventorySettings({ userData, isInitialRender, saveSettingsRef }) {
    const [enableLabor, seteEnableLabor] = useState(userData?.settings?.enableLabor === '1');
    const [laborCost, setLaborCost] = useState(userData?.settings?.laborCost ?? '0.00');
    const [decrementMaterials, setDecrementMaterials] = useState(userData?.settings?.decrementMaterials === '1');

    useEffect(() => {
        if (!isInitialRender.current) {
            logEvent(`change_setting_enableLabor`, {
                setting_name: "enableLabor",
                new_value: enableLabor,
                description: `User changed their "enableLabor" setting to ${enableLabor}`,
            });
            saveSettingsRef.current("enableLabor", enableLabor);
        }
    }, [enableLabor, isInitialRender, saveSettingsRef]);

    useEffect(() => {
        if (!isInitialRender.current) {
            logEvent(`change_setting_laborCost`, {
                setting_name: "laborCost",
                new_value: laborCost,
                description: `User changed their "laborCost" setting to ${laborCost}`,
            });
            saveSettingsRef.current("laborCost", laborCost);
        }
    }, [laborCost, isInitialRender, saveSettingsRef]);

    useEffect(() => {
        if (!isInitialRender.current) {
            logEvent(`change_setting_decrementMaterials`, {
                setting_name: "decrementMaterials",
                new_value: decrementMaterials,
                description: `User changed their "decrementMaterials" setting to ${decrementMaterials}`,
            });
            saveSettingsRef.current("decrementMaterials", decrementMaterials);
        }
    }, [decrementMaterials, isInitialRender, saveSettingsRef]);

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <FormControlLabel
                    control={
                        <Switch
                            checked={enableLabor}
                            onChange={(e) => seteEnableLabor(e.target.checked)}
                        />
                    }
                    label="Enable Labor Input Fields"
                />
            </Grid>
            {enableLabor && (
                <Grid item>
                    <TextField
                        label="Labor Cost Per Hour"
                        value={laborCost}
                        onChange={(e) => setLaborCost(e.target.value)}
                    />
                </Grid>
            )}
            <Grid item>
                <FormControlLabel
                    control={
                        <Switch
                            checked={decrementMaterials}
                            onChange={(e) => setDecrementMaterials(e.target.checked)}
                        />
                    }
                    label="Default 'Decrement Materials' Option"
                />
            </Grid>
        </Grid>
    );
};

export default InventorySettings;