import React, { useContext, useState, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { logPageView } from './utilities/analytics';
import { UserContext } from './context/UserContext';
import './styles/App.css';
import { isElectron } from './utilities/ElectronUtils'
import TitleBar from './components/TitleBar';
import LandingPage from './LandingPage';
import LoginPage from './pages/Login';
import SignupPage from './pages/Signup';
import Dashboard from './Dashboard';
import Terms from './components/LandingPage/legal/Terms';
import Privacy from './components/LandingPage/legal/Privacy';
import ProtectedRoute from './utilities/ProtectedRoute';
import useAppUpdate from './utilities/useAppUpdate';
import ErrorPage from './pages/Error';
import LoadingPage from './pages/Loading';

//Module-Info
import IntegrationModule from './components/LandingPage/module-info/IntegrationModule';
import ReportingModule from './components/LandingPage/module-info/ReportingModule';
import TaxModule from './components/LandingPage/module-info/TaxModule';

function App() {
  const hasRunAppUpdate = useRef(false);
  const { checkIsLoggedIn } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkIsLoggedIn();

    // delay by 1 second
    setTimeout(() => {
      setLoading(false);
    }, 1000);

  }, [checkIsLoggedIn]);

  useAppUpdate(true, hasRunAppUpdate);

  const sectionRefs = {
    pricing: useRef(null),
    features: useRef(null),
    testimonials: useRef(null),
    faqs: useRef(null),
    contactUs: useRef(null),
  };

  // const handleLogout = () => {
  //   setLoggedIn(false);
  // };

  return (
    <div className="App">
      <TitleBar />
      <div className={`App-content ${isElectron ? 'App-titlebar' : ''}`}>
        {loading ? (
          <LoadingPage />
        ) : (
          <BrowserRouter onUpdate={logPageView}>
            <Routes>
              <Route path="/" element={<LandingPage sectionRefs={sectionRefs} />} />
              <Route path="/integration-module" element={<IntegrationModule sectionRefs={sectionRefs} />} />
              <Route path="/reporting-module" element={<ReportingModule sectionRefs={sectionRefs} />} />
              <Route path="/tax-module" element={<TaxModule sectionRefs={sectionRefs} />} />
              <Route path="/terms" element={<Terms sectionRefs={sectionRefs} />} />
              <Route path="/privacy" element={<Privacy sectionRefs={sectionRefs} />} />
              <Route path="/login" element={
                <LoginPage />
              } />
              <Route path="/signup" element={<SignupPage />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </BrowserRouter>
        )}
      </div>
    </div>
  );
}

export default App;
