import React from 'react';
import {
    Typography,
    Paper,
    Box,
    Drawer,
    Select,
    MenuItem,
    IconButton,
    Chip,
    Grid,
    Button,
} from '@mui/material';
import { calculateItemPrice, calculateTotal } from './../utilities/marketUtils';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const Cart = ({ cartItems, setCartItems, open, handleRemoveFromCart, handleCloseCart }) => {
    const handleAdditionalOptionChange = (event, item, optionIndex) => {
        const newOption = event.target.value;
        setCartItems((prevItems) =>
            prevItems.map((currentItem) => {
                if (currentItem === item) {
                    const updatedItem = {
                        ...currentItem,
                        selectedAdditionalOption: {
                            ...currentItem.selectedAdditionalOption,
                            [item.additionalOptions[optionIndex].title]: newOption,
                        },
                    };

                    if (item.additionalOptions) {
                        const optionDetails = item.additionalOptions[optionIndex];
                        const additionalPrice = optionDetails.prices[newOption];

                        const basePrice = item.basePrice ? item.basePrice : item.price;
                        const previousAdditionalPrice = currentItem.selectedAdditionalOption ? Object.entries(currentItem.selectedAdditionalOption).reduce((prev, [optionTitle, option]) => {
                            const correspondingOptionDetails = item.additionalOptions.find(additionalOption => additionalOption.title === optionTitle);
                            const optionPrice = correspondingOptionDetails.prices[option] || 0;
                            return prev + optionPrice;
                        }, 0) : 0;

                        const newBasePrice = basePrice - previousAdditionalPrice + additionalPrice;

                        return { ...updatedItem, basePrice: newBasePrice };
                    } else {
                        return updatedItem;
                    }
                }
                return currentItem;
            })
        );
    };

    const handleTermChange = (event, item) => {
        const newTerm = event.target.value;
        setCartItems((prevItems) =>
            prevItems.map((currentItem) =>
                currentItem === item
                    ? { ...currentItem, selectedTerm: newTerm }
                    : currentItem
            )
        );
    };

    return (
        <Drawer anchor="right" open={open} onClose={handleCloseCart}>
            <Paper
                className="bg-white text-black p-6 rounded-lg shadow-md"
                sx={{ width: { xs: '100vw', sm: 540, md: 840 }, paddingTop: { xs: 8, sm: 0 } }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">Your Cart</Typography>
                    <IconButton edge="end" color="inherit" onClick={handleCloseCart} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
                {cartItems.map((item, index) => (
                    <Box key={index} mb={2}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item xs={7}>
                                <Typography variant="h6">{item.title}</Typography>
                            </Grid>
                            <Grid item xs={4} textAlign="right">
                                {item.terms ? (
                                    <Box>
                                        <Typography variant="body1">
                                            ${(calculateItemPrice(item).finalPrice || 0).toFixed(2)}
                                        </Typography>
                                        {item.terms[item.selectedTerm].discount !== 0 && (
                                            <Chip
                                                label={`${item.terms[item.selectedTerm].discount}% off`}
                                                size="small"
                                                color="primary"
                                            />
                                        )}
                                    </Box>
                                ) : (
                                    <Typography variant="body1">${item.price.toFixed(2)}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => handleRemoveFromCart(item)} edge="end" color="error" aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            {item.additionalOptions &&
                                item.additionalOptions.map((option, optionIndex) => (
                                    <Grid item xs={12} key={optionIndex}>
                                        <Typography variant="body1">{option.title}</Typography>
                                        <Select
                                            value={item.selectedAdditionalOption?.[option.title] || ''}
                                            onChange={(event) => handleAdditionalOptionChange(event, item, optionIndex)}
                                        >
                                            {option.prices && Object.entries(option.prices).map(([key, value], idx) => (
                                                <MenuItem key={idx} value={key}>
                                                    {key} - ${value.toFixed(2)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                ))}
                            {item.terms && (
                                <Grid item xs={12}>
                                    <Select
                                        value={item.selectedTerm || ''}
                                        onChange={(event) => handleTermChange(event, item)}
                                    >
                                        {Object.entries(item.terms).map(([term, price], idx) => {
                                            return (
                                                <MenuItem key={idx} value={term}>
                                                    {term}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                ))}
                <Typography variant="h6">Total: ${calculateTotal(cartItems)}</Typography>
                <Box mt={2}>
                    <Button variant="contained" color="primary" mr={2}>
                        Checkout
                    </Button>
                    <Button variant="outlined" color="primary" onClick={handleCloseCart}>
                        Continue
                    </Button>
                </Box>
            </Paper>
        </Drawer>
    );
};

export default Cart;