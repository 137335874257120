import React from 'react';

const Banner = ({ Title, Body, AdditionalContent }) => {

    return (
        <section className="bg-primary py-20 px-8 flex flex-col items-center justify-center space-y-6">
            <h1 className="text-6xl font-bold text-white">{Title}</h1>
            <p className="text-xl text-center max-w-2xl text-white font-semibold">{Body}</p>
            {AdditionalContent}
        </section>
    );
};

export default Banner;
