import { useEffect, useState, useCallback } from 'react';
import { useToast } from '../components/Toast';
import Button from '@mui/material/Button';
import { CURRENT_VERSION } from '../version';

const useAppUpdate = (checkForUpdates, hasRunAppUpdate) => {
    const { showError, showWarning } = useToast();
    const [isUpdateToastVisible, setIsUpdateToastVisible] = useState(false);

    const checkAppUpdates = useCallback(async () => {
        try {
            let response;
            response = await fetch('/version.json');

            if (response.ok) {
                const data = await response.json();
                const latestVersion = data.version;

                console.log('Current version:', CURRENT_VERSION);
                console.log('Latest version:', latestVersion);

                if (latestVersion !== CURRENT_VERSION) {
                    if (!isUpdateToastVisible) {
                        showWarning('A new version of the application is available.',
                            {
                                autoCloseDuration: false,
                                onClose: () => {
                                    setIsUpdateToastVisible(false);
                                },
                                allowClose: false,
                                extraAction: <Button color="inherit" onClick={() => { window.location.reload() }}>Update Now</Button>
                            });
                    } else {
                        console.log("Update toast is already visible");
                    }
                }
            }
        } catch (error) {
            console.error('Error checking for updates:', error);
            showError('An error occurred while checking for updates');
        }
    }, [showError, showWarning, isUpdateToastVisible]);

    useEffect(() => {
        if (checkForUpdates && !hasRunAppUpdate.current) {
            checkAppUpdates();
            hasRunAppUpdate.current = true;
            const updateInterval = setInterval(() => {
                checkAppUpdates();
            }, 10 * 60 * 1000); // Check for updates every 10 minutes

            return () => {
                clearInterval(updateInterval);
            };
        }
    }, [checkForUpdates, checkAppUpdates, hasRunAppUpdate]);
};

export default useAppUpdate;