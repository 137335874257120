import React, { useState, useEffect } from 'react';
//import { logEvent } from '../../utilities/analytics';
import { isElectron, getElectronVersion } from '../../utilities/ElectronUtils';
import {
  Typography,
  Button,
  Link
} from '@mui/material';

function About({ChangeLogOpen }) {
  const [electronVersion, setElectronVersion] = useState(null);

  useEffect(() => {
    async function fetchElectronVersion() {
      const version = await getElectronVersion();
      //console.log('Electron version: ' + version);
      setElectronVersion(version);
    }

    fetchElectronVersion();
  }, []);

  return (
    <div>
      <Typography variant="body1" className="mb-4">
        Author: <Link href="https://makermikes.com" target="_blank" rel="noopener">Maker Mikes</Link>
      </Typography>
      <Typography variant="body1" className="mb-4">
        Version: {process.env.REACT_APP_VERSION}
      </Typography>
      {isElectron && (
        <Typography variant="body1" className="mb-4">
          App Version: {electronVersion}
        </Typography>
      )}
      <div className="mb-4 mt-4">
        <Button onClick={ChangeLogOpen} variant="contained" color="primary" >
          View Changelog
        </Button>
      </div>
      <div className="mb-4">
        <Button variant="contained" color="primary">
          View Licenses
        </Button>
      </div>
      <div className="mb-4">
        <Typography variant="body2">
          <Link href="/terms" target="_blank" rel="noopener">Terms of Service</Link> | <Link href="/privacy" target="_blank" rel="noopener">Privacy Policy</Link>
        </Typography>
      </div>
      <Typography variant="body2" color="textSecondary">
        © {new Date().getFullYear()} MakerMate. All Rights Reserved.
      </Typography>
    </div>
  );
};

export default About;