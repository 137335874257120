import React from 'react';
import LandingNavigation from '../LandingNavigation';
import Banner from '../Banner';
import Footer from '../Footer';

const Terms = ({ sectionRefs }) => {

    const BannerContent = {
        title: 'Terms of Service',
    };

    return (
        <div className="min-h-screen bg-primary text-primary flex flex-col">
            <LandingNavigation sectionRefs={sectionRefs} />

            <main className="flex-grow overflow-y-auto mt-20">
                <Banner Title={BannerContent.title} />

                <section className="lg:mt-0 mt-2 lg:space-y-16 space-y-4 bg-primary p-6 px-12">
                    <div align="left" className={`feature-card max-w-screen-md mx-auto p-6 lg:p-12 bg-accent rounded shadow text-primary flex flex-col space-x-0 items-center lg:items-start`}>
                        <h2>1. Introduction</h2>
                        <p>Welcome to MakerMate, a small business inventory and order management system. These Terms of Service ("Terms") govern your access to and use of the MakerMate website application ("Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, you must not access or use the Service.</p>

                        <h2>2. Accounts</h2>
                        <p>To access some features of the Service, you may need to create an account. You must provide accurate and complete information when creating an account. You are responsible for maintaining the confidentiality of your account password and are responsible for all activities that occur under your account. You agree to notify MakerMate immediately of any unauthorized use of your account or any other breach of security.</p>

                        <h2>3. Subscription Plans</h2>
                        <p>MakerMate offers a base software for free with the option to subscribe to additional feature modules as needed. Subscription fees for additional modules are non-refundable. MakerMate reserves the right to modify, suspend, or discontinue the Service, or any part thereof, at any time, with or without notice.</p>

                        <h2>4. User Content</h2>
                        <p>You may submit, post, or display content through the Service. You retain ownership of any intellectual property rights that you hold in that content. By submitting, posting, or displaying content through the Service, you grant MakerMate a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such content for the purpose of operating, promoting, and improving the Service.</p>

                        <h2>5. Acceptable Use</h2>
                        <p>You agree not to engage in any activity that interferes with or disrupts the Service, or the servers and networks connected to the Service. You agree not to use the Service for any illegal or unauthorized purpose, including but not limited to violating any local, state, national, or international laws.</p>
                        
                        <h2>6. Intellectual Property</h2>
                        <p>All content, features, and functionality of the Service, including but not limited to text, graphics, logos, and software, are the exclusive property of MakerMate and are protected by copyright, trademark, and other intellectual property laws.</p>                        
                        
                        <h2>7. Limitation of Liability</h2>
                        <p>To the maximum extent permitted by applicable law, in no event shall MakerMate, its affiliates, directors, employees, or licensors be liable for any direct, indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, that result from the use of, or inability to use, the Service.</p>                        
                        
                        <h2>8. Termination</h2>
                        <p>MakerMate reserves the right to terminate your access to the Service, without cause or notice, which may result in the forfeiture and destruction of all information associated with your account.</p>                        
                        
                        <h2>9. Changes to Terms</h2>
                        <p>MakerMate may revise these Terms from time to time. The most current version of the Terms will always be available on the MakerMate website. By continuing to access or use the Service after any revisions become effective, you agree to be bound by the revised Terms. If you do not agree to the new Terms, you must stop using the Service.</p>                        
                        
                        <h2>10. Governing Law</h2>
                        <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which MakerMate operates, without regard to its conflict of law provisions. Any disputes arising from or relating to these Terms or the Service shall be subject to the exclusive jurisdiction of the courts of that jurisdiction.</p>                        
                        
                        <h2>11. Contact Us</h2>
                        <p>If you have any questions about these Terms, please contact us at contact@makermate.app.</p>
                    </div>
                </section>

                <Footer />

            </main>
        </div>
    );
};

export default Terms;
