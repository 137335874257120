import React, { useContext, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from './../../context/UserContext';
import { useLandingNavigationContext } from './LandingNavigationContext';
import logo from '../../assets/logo.svg';
import { CSSTransition } from 'react-transition-group';
import {
    Menu as MenuIcon
} from '@mui/icons-material';

const LandingNavigation = ({ sectionRefs }) => {
    const { isLoggedIn } = useContext(UserContext);
    const menuRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

    const { setIsInLandingNavigation } = useLandingNavigationContext();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIsInLandingNavigation(true);
    }, [setIsInLandingNavigation]);

    const handleLinkClick = (e, path) => {
        e.preventDefault();
        setMenuOpen(false);

        // Function to scroll to the desired section
        const scrollToSection = () => {
            const mainElement = document.querySelector('main');
            if (sectionRefs[path]) {
                const yOffset = -80;

                const yPosition =
                    sectionRefs[path].current.getBoundingClientRect().top + mainElement.scrollTop;

                mainElement.scrollTo({ top: yPosition + yOffset, behavior: 'smooth' });
            } else {
                navigate('/' + path);
                mainElement.scrollTo({ top: 0, behavior: 'smooth' });
            }
        };

        if (location.pathname !== '/' && sectionRefs[path]) {
            navigate('/');
            // Use setTimeout to give time for the page to render before scrolling
            setTimeout(scrollToSection, 100);
        } else {
            scrollToSection();
        }
    };

    const menuItems = (
        <ul
            ref={menuRef}
            className={`fixed top-20 left-0 w-full mt-0 z-1 bg-navigation pb-8 p-4 space-y-4 flex flex-col transform ${menuOpen ? 'translate-y-0' : 'translate-y-full'
                } lg:relative lg:flex lg:flex-row lg:space-x-6 lg:space-y-0 lg:bg-transparent lg:p-0 lg:mt-0 lg:translate-y-0 lg:top-0`}
        >
            <li><a href="/features" onClick={(e) => handleLinkClick(e, 'features')} className="text-primary hover:text-secondary">Features</a></li>
            {/* <li><a href="/pricing" onClick={(e) => handleLinkClick(e, 'pricing')} className="text-primary hover:text-secondary">Pricing</a></li>
            <li><a href="/testimonials" onClick={(e) => handleLinkClick(e, 'testimonials')} className="text-primary hover:text-secondary">Testimonials</a></li>
            <li><a href="/faqs" onClick={(e) => handleLinkClick(e, 'faqs')} className="text-primary hover:text-secondary">FAQs</a></li> */}
            <li><a href="/contactUs" onClick={(e) => handleLinkClick(e, 'contactUs')} className="text-primary hover:text-secondary">Contact Us</a></li>
            {!isLoggedIn && (
                <>
                    <li><a href="/login" onClick={(e) => handleLinkClick(e, 'login')} className="text-primary hover:text-secondary">Login</a></li>
                    <li><a href="/signup" onClick={(e) => handleLinkClick(e, 'signup')} className="bg-secondary text-white px-6 py-2 rounded hover:bg-tertiary">Start for Free</a></li>
                </>
            )}
            {isLoggedIn && (
                <>
                    <li><a href="/dashboard" onClick={(e) => handleLinkClick(e, 'dashboard')} className="bg-secondary text-white px-6 py-2 rounded hover:bg-tertiary">Open MakerMate</a></li>
                </>
            )}
        </ul>
    );

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className={`fixed top-0 z-20 w-full flex justify-between items-center px-8 py-6 ${menuOpen && isMobile ? 'bg-navigation' : 'bg-primary'}`}>
            <div className="flex items-center space-x-2 cursor-pointer" onClick={((e) => handleLinkClick(e, ''))}>
                <img src={logo} alt="MakerMate" className="h-8 w-auto" />
                <div className={`text-2xl font-bold text-primary App-logo-text`}>
                    MakerMate
                </div>
            </div>
            <nav className={`${isMobile ? 'block' : 'hidden'} lg:hidden`}>
                <button onClick={toggleMenu} className="text-primary focus:outline-none">
                    <MenuIcon />
                </button>
                <CSSTransition
                    nodeRef={menuRef}
                    in={menuOpen}
                    timeout={300}
                    classNames="slide-down"
                    unmountOnExit
                >
                    {menuItems}
                </CSSTransition>
            </nav>
            <nav className={`${isMobile ? 'hidden' : 'block'} hidden lg:block`}>
                <ul className="flex space-x-6 items-center">{menuItems}</ul>
            </nav>
        </header>
    );
};

export default LandingNavigation;