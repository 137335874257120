import React, {
    useState,
    //useEffect 
} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    //Typography,
} from '@mui/material';
import { useToast } from './../Toast';
import { addSuggestion } from './../../api/feedbackAPI';

function SuggestionForm({
    suggestions,
    setSuggestions,
    openDialog,
    setOpenDialog,
  }) {
    const { showError, showSuccess } = useToast();

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [submissionType, setSubmissionType] = useState('Feature Request');
    //const [similarSuggestions, setSimilarSuggestions] = useState([]);

    // useEffect(() => {
    //     if (title.length > 2) {
    //         const search = title.toLowerCase();
    //         const foundSuggestions = suggestions.filter((suggestion) =>
    //             suggestion.title.toLowerCase().includes(search)
    //         );
    //         setSimilarSuggestions(foundSuggestions);
    //     } else {
    //         setSimilarSuggestions([]);
    //     }
    // }, [title, suggestions]);

    // const handleSimilarSuggestionClick = (suggestion) => {
    //     // Implement opening the dialog for the clicked suggestion
    //     //setOpenDialog(false);
    // };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSubmit = async () => {
        // Implement the submit functionality
        const suggestion = await addSuggestion(title, description, submissionType);
        if (suggestion !== false) {
            setTitle('');
            setDescription('');
            setSubmissionType('Feature Request');
            setOpenDialog(false);
            setSuggestions([suggestion, ...suggestions]);
            showSuccess('Suggestion submitted successfully');
        } else {
            showError('Suggestion submission failed');
        }
    };

    return (
        <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>New Suggestion</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="dense">
                    <InputLabel id="submissionType-label">Submission Type</InputLabel>
                    <Select
                        labelId="submissionType-label"
                        id="submissionType"
                        value={submissionType}
                        label="Submission Type"
                        onChange={(e) => setSubmissionType(e.target.value)}
                    >
                        <MenuItem value="Feature Request">Feature Request</MenuItem>
                        <MenuItem value="Bug Report">Bug Report</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Title"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />

                {/* {similarSuggestions.length > 0 && (
                    <>
                        Similar suggestions found:
                        {similarSuggestions.map((suggestion) => (
                            <Typography
                                key={suggestion.id}
                                variant="body2"
                                onClick={() => handleSimilarSuggestionClick(suggestion)}
                                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                            >
                                {suggestion.title}
                            </Typography>
                        ))}
                    </>
                )} */}
                <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    fullWidth
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SuggestionForm;
