import customFetch from './../utilities/customFetch';
//import { logEvent } from './../utilities/analytics';

export const fetchProducts = async (page, pageSize, filter=[]) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}products/get`;

        const filters = {
            items: filter,
            logicOperator: 'AND',
        }

        const requestData = {
            page: page,
            pageSize: pageSize,
            filter: filters,
            sort: [{ field: 'name', direction: 'asc' }]
        };
    
        const response = await customFetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Failed to fetch products:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return false;
    }
    
};

export const fetchProduct = async (id) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}products/${id}`;

        const response = await customFetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Failed to fetch product:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        return false;
    }

};

export const deleteProduct = async (id) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}products/${id}`;

        const response = await customFetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            // logEvent("product_delete", {
            //     action: "Delete",
            //     description: "User deleted a product",
            // });
            return await response.json();
        } else {
            // logEvent("product_delete_error", {
            //     action: "Delete",
            //     description: response.statusText,
            // });
            console.error("Failed to delete product:", response.statusText);
            return false;
        }
    } catch (error) {
        // logEvent("product_delete_error", {
        //     action: "Delete",
        //     description: error,
        // });
        console.error('Error while deleting product:', error);
        return false;
    }
};

export const submitProduct = async (itemData, itemId = null) => {

    try {
        const response = await customFetch(itemId ? `${process.env.REACT_APP_API_URL}products/${itemId}` : `${process.env.REACT_APP_API_URL}products`, {
            method: itemId ? "PUT" : "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(itemData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error("Error creating product:", response.statusText);
            return false;
        }
    } catch (error) {
        console.error("Error creating product:", error);
        return false;
    }
};

export const productAdjustment = async (productId, adjustment) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}products/${productId}/adjustment`;

        const requestData = {
            adjustment: adjustment,
        };

        const response = await customFetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (response.ok) {
            return await response.json();
        } else {
            console.error('Failed to update product quantity:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error updating product quantity:', error);
        return false;
    }
};
