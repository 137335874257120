import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { logEvent } from './../utilities/analytics';
import { UserContext } from './../context/UserContext';
import { useToast } from '../components/Toast';
import { fetchExpenses, deleteExpense, submitExpense } from './../api/expenseAPI';
import FormDialog from '../components/FormDialog';
import ConfirmDialog from '../components/ConfirmDialog';
import ExpenseForm from '../components/ExpenseForm';
import SpeedDial from '../components/SpeedDial';
import {
    Typography,
    Button,
    Container,
    Grid,
    Paper,
    LinearProgress
} from '@mui/material';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector
} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const CustomToolbar = (ReportModule = false) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            {ReportModule === true ? <GridToolbarExport /> : null}
        </GridToolbarContainer>
    );
}

function ExpensesPurchases() {
    const { userData } = useContext(UserContext);

    const { showError, showSuccess } = useToast();
    const showErrorRef = useRef(showError);
    const showSuccessRef = useRef(showSuccess);
    useEffect(() => {
        showErrorRef.current = showError;
        showSuccessRef.current = showSuccess;
    }, [showError, showSuccess]);

    const expenseFormRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [dialogMode, setDialogMode] = useState(null);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState({ open: false, expenseId: null });
    const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = useState({ open: false, expenseIds: null });

    const [expenses, setExpenses] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [speedDialActions, setSpeedDialActions] = useState([]);

    const [rowCount, setRowCount] = useState(0);
    const [rowCountState, setRowCountState] = useState(rowCount);
    const [filters, setFilters] = useState({});
    const [sorting, setSorting] = useState([{ field: 'date', direction: 'desc ' }]);
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 25,
        page: 0,
    });

    useEffect(() => {
        logEvent('navigate_expenses', {
            page_name: 'Expenses',
            description: 'User navigated to the expenses page',
        });
    }, []);

    const openDialog = (expense, mode) => {
        setSelectedExpense(expense);
        setDialogMode(mode); // Set the dialog mode
        setDialogIsOpen(true);

        // Log the event
        if (mode === "edit") {
            logEvent('expense_dialog_open_edit', {
                page_name: 'Edit Expense Dialog',
                description: 'User opened the Edit dialog',
            });
        } else if (mode === "copy") {
            logEvent('expense_dialog_open_copy', {
                page_name: 'Copy Expense Dialog',
                description: 'User opened the Copy dialog',
            });
        } else {
            logEvent('expense_dialog_open_add', {
                page_name: 'Add Expense Dialog',
                description: 'User opened the Add dialog',
            });
        }
    };

    const closeDialog = () => {
        setSelectedExpense(null);
        const previousMode = dialogMode;
        setDialogMode(null); // Reset the dialog mode
        setDialogIsOpen(false);

        // Log the event
        if (previousMode === "edit") {
            logEvent('expense_dialog_close_edit', {
                page_name: 'Edit Expense Dialog',
                description: 'User closed the Edit dialog',
            });
        } else if (previousMode === "copy") {
            logEvent('expense_dialog_close_copy', {
                page_name: 'Copy Expense Dialog',
                description: 'User closed the Copy dialog',
            });
        } else {
            logEvent('expense_dialog_close_add', {
                page_name: 'Add Expense Dialog',
                description: 'User closed the Add dialog',
            });
        }
    };

    const loadExpenses = useCallback(async (paginationModel, filters = {}, sorting = []) => {
        setIsLoading(true);

        try {
            const fetchedData = await fetchExpenses(paginationModel.page, paginationModel.pageSize, sorting, filters);

            if (fetchedData !== false) {
                const data = fetchedData.expenses.map((expense) => ({
                    id: expense.id,
                    name: expense.name,
                    date: new Date(expense.date),
                    cost: parseFloat(expense.cost),
                    type: expense.type,
                    unit: expense.unit,
                    quantity: parseInt(expense.quantity),
                    material_id: expense.material_id,
                }));

                setExpenses(data);
                setRowCount(fetchedData.totalCount);
            }
        } catch (error) {

        }

        setIsLoading(false);

    }, []);

    const handleAddExpense = async (newExpense) => {
        setIsLoading(true);

        try {
            const data = await submitExpense(newExpense);

            if (data !== false) {
                logEvent("expense_add", {
                    action: "Add",
                    description: "User added a new expense",
                });
                // Refetch the expenses for the current page
                loadExpenses(paginationModel, filters, sorting);
                showSuccessRef.current('Expense added successfully');
            }
        } catch (error) {
            console.error('Error adding expense:', error);
        }
    };

    const handleUpdateExpense = async (newExpense) => {
        setIsLoading(true);

        try {
            const data = await submitExpense(newExpense, selectedExpense.id);

            if (data !== false) {
                logEvent("expense_update", {
                    action: "Update",
                    description: "User updated an expense",
                });
                // Refetch the expenses for the current page
                loadExpenses(paginationModel, filters, sorting);
                showSuccessRef.current('Expense updated successfully');
            }
        } catch (error) {
            console.error('Error updating expense:', error);
        }
    };

    const handleDeleteExpense = useCallback(
        (expenseId, bulkDelete = false, skipConfirmation = false) => {
            const removeExpense = () => {
                setIsLoading(true);

                return deleteExpense(expenseId)
                    .then(() => {
                        if (!bulkDelete) {
                            logEvent("expense_delete", {
                                action: "Delete",
                                description: "User deleted an expense",
                            });
                            loadExpenses(paginationModel, filters, sorting);
                            showSuccessRef.current('Expense deleted successfully');
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        showErrorRef.current('Failed to delete expense');
                    });
            };

            if (skipConfirmation || userData.settings.deleteWarnings === "0") {
                removeExpense();
            } else {
                setOpenDeleteDialog({ open: true, expenseId: expenseId, deleteFunction: removeExpense });
            }
        },
        [userData, loadExpenses, paginationModel, filters, sorting]
    );

    const handleDeleteExpenses = useCallback(
        (expenseIds) => {
            const deleteAllExpenses = () => {
                setIsLoading(true);
                // Call handleDeleteExpense for each expenseId and wait for all to complete
                Promise.all(expenseIds.map((expenseId) => handleDeleteExpense(expenseId, true, true)))
                    .then(() => {
                        logEvent("expense_bulk_delete", {
                            action: "Bulk Delete",
                            description: `User bulk deleted ${expenseIds.length} expenses`,
                        });
                        // Refetch the expenses for the current page after all expenses are deleted
                        loadExpenses(paginationModel, filters, sorting);
                        showSuccessRef.current('Expenses deleted successfully');
                        // Clear the selected rows state
                        setSelectedRows([]);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        showErrorRef.current('Failed to delete expenses');
                    });
            };

            if (userData.settings.deleteWarnings === "0") {
                deleteAllExpenses();
            } else {
                setOpenBulkDeleteDialog({
                    open: true,
                    expenseIds: expenseIds,
                    deleteFunction: deleteAllExpenses,
                    itemCount: expenseIds.length
                });
            }
        },
        [userData, handleDeleteExpense, loadExpenses, paginationModel, filters, sorting]
    );

    const getSpeedDialActions = useCallback(() => {
        let actions = [];

        if (selectedRows.length === 0) {
            setSpeedDialActions(actions);
        } else if (selectedRows.length === 1) {
            actions = [
                { icon: <AddIcon />, name: 'Add', onClick: () => openDialog(null) },
                { icon: <EditIcon />, name: 'Edit', onClick: () => openDialog(expenses.find(expense => expense.id === selectedRows[0]), "edit") },
                { icon: <FileCopyIcon />, name: 'Copy', onClick: () => openDialog((expenses.find(expense => expense.id === selectedRows[0])), "copy") },
                { icon: <DeleteIcon />, name: 'Delete', onClick: () => handleDeleteExpense(selectedRows[0]) },
            ];
            setSpeedDialActions(actions);
        } else {
            actions = [
                { icon: <AddIcon />, name: 'Add', onClick: () => openDialog(null) },
                { icon: <DeleteIcon />, name: 'Delete', onClick: () => handleDeleteExpenses(selectedRows) },
            ];
            setSpeedDialActions(actions);
        }
    }, [selectedRows, expenses, handleDeleteExpense, handleDeleteExpenses]);

    useEffect(() => {
        getSpeedDialActions();
    }, [selectedRows, getSpeedDialActions]);

    useEffect(() => {
        loadExpenses(paginationModel, filters, sorting);
    }, [paginationModel, filters, sorting, loadExpenses]);

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            rowCount !== undefined ? rowCount : prevRowCountState,
        );
    }, [rowCount, setRowCountState]);

    function addCurrencySymbol(params) {
        const roundedCost = parseFloat(params.row.cost).toFixed(2);
        return `${userData?.settings?.currencySymbol}${roundedCost}`
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Expense Name',
            flex: 1,
            type: 'string'
        },
        {
            field: 'date',
            headerName: 'Purchase Date',
            flex: 1,
            type: 'date'
        },
        {
            field: 'cost',
            headerName: 'Cost',
            flex: 1,
            type: 'string',
            valueGetter: addCurrencySymbol
        },
        {
            field: 'type',
            headerName: 'Type',
            flex: 1,
            type: 'string'
        },
        {
            field: 'unit',
            headerName: 'Unit',
            flex: 1,
            type: 'string'
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            flex: 1,
            type: 'number'
        },
    ];

    const handleSave = () => {
        expenseFormRef.current.handleSubmit();
        setSelectedRows([]);
    };

    const ExpenseFormActions = ({ formType, onSave, onCancel }) => {
        const saveButtonLabel =
            formType === "edit"
                ? "Save"
                : formType === "copy"
                    ? "Copy"
                    : "Add";
        return (
            <>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        closeDialog();
                        onSave();
                    }}
                >
                    {saveButtonLabel}
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        closeDialog();
                        onCancel && onCancel();
                    }}
                >
                    Cancel
                </Button>
            </>
        );
    };

    return (
        <>
            <Container>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <Typography variant="h4" className="text-white mb-4" align="left">
                            Expenses
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className="bg-white text-black p-6 rounded-lg shadow-md">
                            <DataGrid
                                autoHeight
                                columns={columns}
                                loading={isLoading}
                                initialState={{
                                    pagination: { paginationModel: { page: 0, pageSize: 25 } },
                                    sorting: { sortModel: [{ field: 'date', sort: 'desc' }] },
                                }}
                                paginationMode="server"
                                rowCount={rowCountState}
                                paginationModel={paginationModel}
                                onPaginationModelChange={(newPaginationModel) => {
                                    setPaginationModel(newPaginationModel);
                                }}
                                checkboxSelection={true}
                                onRowSelectionModelChange={(newRowSelectionModel) => {
                                    setSelectedRows(newRowSelectionModel);
                                }}
                                onFilterModelChange={(newFilterModel) => {
                                    setFilters(newFilterModel);
                                }}
                                onSortModelChange={(newSortModel) => {
                                    setSorting(newSortModel);
                                }}
                                rowSelectionModel={selectedRows}
                                slots={{
                                    loadingOverlay: LinearProgress,
                                    toolbar: CustomToolbar,
                                }}
                                rows={expenses}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <FormDialog
                    title={
                        dialogMode === "edit"
                            ? "Edit Expense"
                            : dialogMode === "copy"
                                ? "Copy Expense"
                                : "Add Expense"
                    }
                    onClose={closeDialog}
                    isOpen={dialogIsOpen}
                    actions={
                        <ExpenseFormActions
                            formType={dialogMode}
                            onSave={handleSave}
                            onCancel={closeDialog}
                        />
                    }
                >
                    <ExpenseForm
                        ref={expenseFormRef} // Pass the ref to the ExpenseForm component
                        onSubmit={
                            dialogMode === "edit"
                                ? handleUpdateExpense
                                : dialogMode === "copy"
                                    ? handleAddExpense // Use handleAddExpense for copy mode
                                    : handleAddExpense
                        }
                        // onDelete={handleDeleteExpense}
                        selectedExpense={selectedExpense}
                    />
                </FormDialog>
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    title="Delete Expense"
                    content="Are you sure you want to delete this expense? This action cannot be undone."
                    onCancel={() => setOpenDeleteDialog({ open: false, expenseId: null })}
                    onConfirm={() => {
                        openDeleteDialog.deleteFunction();
                        setOpenDeleteDialog({ open: false, expenseId: null });
                    }}
                />
                <ConfirmDialog
                    open={openBulkDeleteDialog.open}
                    title={`Delete ${openBulkDeleteDialog.itemCount} Expenses`}
                    content={`Are you sure you want to delete ${openBulkDeleteDialog.itemCount} expenses? This action cannot be undone.`}
                    onCancel={() => setOpenBulkDeleteDialog({ open: false, expenseIds: null })}
                    onConfirm={() => {
                        openBulkDeleteDialog.deleteFunction();
                        setOpenBulkDeleteDialog({ open: false, expenseIds: null });
                    }}
                />
            </Container >
            <SpeedDial
                onSpeedDialClick={selectedRows.length === 0 ? () => openDialog(null) : undefined}
                actions={speedDialActions}
                icon={selectedRows.length === 0 ? <AddIcon /> : undefined}
            />
        </>
    );

}

export default ExpensesPurchases;