// ProductDetails.js
import React from 'react';
//import { UserContext } from './../../../context/UserContext';
import { Typography } from '@mui/material';

const Varients = ({
    //item,
    editMode,
}) => {
    //const { userData } = useContext(UserContext);

    return editMode ? (
        <>
            <Typography variant="body1">Coming Soon...</Typography>
        </>
    ) : (
        <>
            <Typography variant="body1">Coming Soon...</Typography>
        </>
    );
};

export default Varients;
