export const isElectron = typeof window.electronAPI !== 'undefined';

export const ipcRenderer = (() => {
    if (typeof window.electronAPI !== 'undefined') {
        const { ipcRenderer: electronIPC } = window.electronAPI;
        return electronIPC;
    }
    return null;
})();

export const getElectronVersion = async () => {
    if (isElectron) {
        return await ipcRenderer.invoke('app_version');
    }
    return null;
  }

export const setElectronToken = async (token) => {
    if (isElectron) {
        await ipcRenderer.invoke('set-token', token);
    }
};


export const ElectronToken = async () => {
    if (isElectron) {
        return await ipcRenderer.invoke('get-token');
    }
    return null;
};