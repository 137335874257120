// ProductDetails.js
import React, { 
    // useState, 
    // useEffect, 
    // useContext 
} from 'react';
//import { styled } from '@mui/material/styles';
//import { UserContext } from './../../../context/UserContext';
import { 
    // Box, 
    // Autocomplete, 
    // TextField, 
    // Badge, 
    // Chip, 
    // Dialog, 
    // DialogTitle, 
    // DialogContent, 
    // DialogActions, 
    // Button, 
    // Divider, 
    // Grid, 
    Typography, 
    // Collapse, 
    // IconButton 
} from '@mui/material';
//import { ExpandLess as ExpandLessIcon } from '@mui/icons-material';

// const ExpandMore = styled((props) => {
//     const { expand, ...other } = props;
//     return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//     transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
//     marginLeft: 'auto',
//     transition: theme.transitions.create('transform', {
//         duration: theme.transitions.duration.shortest,
//     }),
// }));

// const StyledBadge = styled(Badge)(({ theme }) => ({
//     '& .MuiBadge-badge': {
//         right: 35,
//         top: 0,
//         fontSize: '1rem',
//         padding: '10px',
//         margin: '0',
//     },
// }));

const Varients = ({
    //item,
    editMode,
}) => {

    return editMode ? (
        <>
            <Typography variant="body1">Coming Soon...</Typography>
        </>
    ) : (
        <>
            <Typography variant="body1">Coming Soon...</Typography>
        </>
    );
};

export default Varients;
